export const validateForm = (values, validationRules) => {
    const errors = {};
  
    for (const fieldName in validationRules) {
      const rule = validationRules[fieldName];
      const value = values[fieldName];
  
      if (rule.required && (!value || (typeof value === 'string' && value.trim() === ''))) {
        errors[fieldName] = `${fieldName} is required`;
      }
  
      // Add more validation rules as needed
  
      // Example: Check if it's a valid URL
      if (rule.isURL && value) {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i')
        if (!pattern.test(value)) {
          errors[fieldName] = `Invalid ${fieldName}`;
        }
      }
  
      // Example: Check if it's a valid phone number
      if (rule.isPhoneNumber && value) {
        if (!/^[0-9\b]+$/.test(value) || value.length !== 10) {
          errors[fieldName] = `Invalid ${fieldName}`;
        }
      }
    }
  
    return errors;
  };