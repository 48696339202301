import React from 'react';
import { Grid, Button, Tooltip } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import TabularFilter from './TabularFilter';
import LedgerFilter from './LedgerFilter';
import { useSelector } from 'react-redux';

function LedgerHeader({
  branchName,
  frDate,
  tDate,
  isLoadingTrue,
  branchSelect,
  setbranchSelect,
  branchList,
  handleSearchClick,
  setbranchSelectSearch,
  setBranchName,
  setFrmdate,
  setTodate,
  frmDate,
  toDate,
  fyrList,
  fyrSelect,
  setFyrSelect,
  setSelectedFyr,
  selectedFyr,
  setOptionSelect,
  exportExcel,
  companyName,
  title
}) {
  // console.log(fyrList);
  const { t } = useTranslation()
  const user = useSelector(state => state.user)



  return (

    <Grid container spacing={1}>

      <Grid item xs={12} ml={2} mt={5} className='box space'>
        <span style={{ color: '#489C8F', fontSize: '15px', fontWeight: '500' }}>{title}</span>
        <span style={{ color: '#1F77B4', fontWeight: '500' }}>{user?.company?.companyname}</span>
      </Grid>

      <Grid item xs={12} ml={2} mt={1} style={{ textAlign: 'start', display: 'flex' }}>

        <Grid item >
          <span className="span text-h1">
            <b className="b text-h1" style={{ fontSize: '12px', fontWeight: 500, color: 'black' }}> Branch :  </b>
            {/* <b className="b text-h1" style={{ fontSize: '12px', fontWeight: 500 }}> {companyName}
              &nbsp;&nbsp; */}
              <b className="b text-h1" style={{ color: '#1F77B4', fontSize: '12px', fontWeight: 500 }}>{branchName.join(" , ")}</b>

            {/* </b> */}
          </span>
        </Grid>

        <Grid item ml={6}>
          <span className="span text-h1">
            <span style={{ fontSize: '12px', fontWeight: 500, color: 'black' }}>From :  </span><b className="b text-h1" style={{ fontSize: '12px', fontWeight: 500, color: '#1F77B4' }}>{frDate}</b>
          </span>
          <span className="span text-h1" style={{ marginLeft: '50px' }}>
            <span style={{ fontSize: '12px', fontWeight: 500, color: 'black', }}>To :   </span><b className="b text-h1" style={{ fontSize: '12px', fontWeight: 500, color: '#1F77B4', }}>{tDate}</b>
          </span>
        </Grid>

        <Grid item style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', flex: 1 }}>
          <LedgerFilter
            branchSelect={branchSelect}
            setBranchSelect={setbranchSelect}
            branchList={branchList}
            handleSearch={handleSearchClick}
            isLoadingTrue={isLoadingTrue}
            setbranchSelectSearch={setbranchSelectSearch}
            setBranchName={setBranchName}
            setFrmdate={setFrmdate}
            setTodate={setTodate}
            frmDate={frmDate}
            toDate={toDate}
            fyrList={fyrList}
            fyrSelect={fyrSelect}
            setFyrSelect={setFyrSelect}
            setSelectedFyr={setSelectedFyr}
            selectedFyr={selectedFyr}
            //  optionTitle={optionTitle}
            //  optionSelect={optionSelect}
            setOptionSelect={setOptionSelect}
          //  optionList ={optionList}
          //  optionKey ={optionKey}

          />

          &nbsp; &nbsp; &nbsp; &nbsp;
          <DownloadIcon onClick={exportExcel} className='common-icon' style={{ marginTop: '0px' }} />
        </Grid>

      </Grid>


    </Grid>



    //   <Grid container spacing={1}  justifyContent="space-between" alignItems="center"  >
    //       <Grid item xs={12} mt={2} style={{ textAlign: 'center' }}>

    //   <span className="span text-h1">
    //     <b className="b text-h1" style={{ fontSize:'16px' }}> {companyName}
    //             &nbsp;&nbsp;
    //         <b className="b text-h1" style={{ color: 'black' }}>({branchName.join(" , ")})</b>

    //     </b>
    //   </span>
    // </Grid>


    // <Grid item xs={12} style={{textAlign:'center'}}>
    //   <span className="span text-h1">
    //     <b className="b text-h1" style={{ color: '#1F77B4' }}>{title}
    //     &nbsp;&nbsp;
    //       <b className="b text-h1" style={{ color: 'black' }}>({optionSelect})</b>
    //     </b>
    //   </span>
    // </Grid>


    // <Grid item xs={12} style={{textAlign:'center'}}>
    // <span className="span text-h1">
    //   <span  style={{ color: 'black' }}>From: &nbsp; </span><b className="b text-h1"  style={{ color: '#1F77B4' }}> {frDate} </b>
    //   </span>&nbsp;&nbsp;
    //   <span className="span text-h1">
    //   <span style={{ color: 'black',   }}>To: &nbsp; </span><b className="b text-h1"  style={{ color: '#1F77B4',  }}> {tDate} </b>
    //   </span>
    // </Grid>

    // <Grid item xs={12} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}} >
    // <div style={{ marginTop: '0px' }}>
    //    <TabularFilter 

    //          branchSelect={branchSelect}
    //          setBranchSelect={setbranchSelect}
    //          branchList={branchList}
    //          handleSearch={handleSearchClick}
    //          isLoadingTrue={isLoadingTrue}
    //          setbranchSelectSearch={setbranchSelectSearch}
    //          setBranchName={setBranchName}
    //          setFrmdate={setFrmdate}
    //          setTodate={setTodate}
    //          frmDate={frmDate}
    //          toDate={toDate}
    //          optionTitle={optionTitle}
    //          optionSelect={optionSelect}
    //          setOptionSelect={setOptionSelect}
    //          optionList ={optionList}
    //          optionKey ={optionKey}

    //           />
    //            </div>
    //     &nbsp; &nbsp; &nbsp; &nbsp;
    //      <DownloadIcon   onClick={exportExcel} className='common-icon' style={{ marginTop: '0px' }}/>
    // </Grid>




    //       </Grid>

  );
}

export default LedgerHeader;
