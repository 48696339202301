import React from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'

function NoData({name, create}) {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
    <Grid container spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} display='flex' justifyContent='center' >
            <Box display="flex" flexDirection="column" alignItems="center">
                <img src={process.env.PUBLIC_URL + '/assets/images/nodata.png'} alt='offline' className='image nodata' />
                <Typography variant='h5' mb={2}>You haven't created a {name} yet.</Typography>
                <Button className='btn btn-log' onClick={create}>Add {name}</Button>
            </Box>
        </Grid>
        <Grid item xs={4}></Grid>
    </Grid>
</Box>
  )
}

export default NoData