import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography, CircularProgress, Modal, Tooltip, IconButton, Card } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../components/styles/theme';
import dayjs from 'dayjs'
import { useSelector } from 'react-redux';
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from '../../api/HandleApiErrors';
import { useTranslation } from 'react-i18next';
import { getPermittedBranch } from '../../api/commonApi';
import { changeFormat, dateConvert, changeCurrencyFormat } from '../../HelperFile';
import InventoryHeader from '../../components/InventoryHeader';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as inventoryStockReportApi from '../../api/inventoryStockReportApi';
import { getProductGroup } from '../../api/saleshomeApi';
//  import * as saleshomeApi from '../../api/saleshomeApi';
import InventoryExtraFilter from '../../components/InventoryExtraFilter'
import { getFyrByCompany } from '../../api/fyrApi';
import { getProfitLoss } from '../../api/profitlossApi';
import ProfitLossHeader from '../../components/ProfitLossHeader';
import AntSwitch from '../../components/styles/AntSwitch';
import DetailTable from './DetailTable';
import ProfitLossChart from './ProfitLossChart';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ProfitLossReport() {

  const { t } = useTranslation()
  const isBranchList = useRef(false)

  const initialLoading = useRef(false)
  const abortConstants = useSignalAbort()
  const token = useSelector(state => state.token)
  const user = useSelector(state => state.user)
  const companyId = user?.companyid
  const company = user?.company
  const isFyr = useRef(false);
  const [fyrList, setFyrList] = useState()
  const [fyrSelect, setFyrSelect] = useState('');
  const [fyrName, setFyrName] = useState()
  const [branchList, setBranchList] = useState()

  const [branchSelect, setbranchSelect] = useState(user?.branchid)
  const [branchSelectSearch, setbranchSelectSearch] = useState(user?.branchid)
  const [toDate, setTodate] = useState()
  const [selectedFyr, setSelectedFyr] = useState()
  const [frmDate, setFrmdate] = useState()
  const [frDate, setFrDate] = useState()
  const [tDate, setTDate] = useState()
  const [branchName, setBranchName] = useState()
  const [isLoading, setIsLoading] = useState({})
  const [groupSelect, setGroupSelect] = useState();
  const [Grouptype, setGrouptype] = useState()
  const [profitLoss, setProfitLoss] = useState()
  const [costOfSales, setCostOfSales] = useState()
  const [grossProfit, setGrossProfit] = useState()
  const [netProfit, setNetProfit] = useState()
  const [otherExpenses, setOtherExpenses] = useState()
  const [isDetail, setIsDetail] = useState(false)
  const [detailData, setDetailData] = useState()
  const [path, setPath] = useState([]);
  const [chartData, setChartData] = useState();

  const excludedTypes = [
    'Direct Incomes',
    'Direct Expenses',
    'Indirect Incomes',
    'Indirect Expenses',
    'Purchase Accounts',
    'Sales Accounts',
    'Opening Stock',
    'Closing Stock'
  ];

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setDetailData(null)
  }

  //display message if default branch is not set to the user
  useEffect(() => {
    if (user && !user?.branchid) {
      toast.error("A default branch has not been assigned to you. Please contact your administrator", {
        autoClose: 3000, // Close the toast after 5 seconds
      });
    }
  }, [user])

  useEffect(() => {
    const fetchData = async () => {
      if (!token || !companyId || !user) return;

      if (!isFyr.current) {
        console.log("Fetching financial year data...");
        await getFyrByCriteria(companyId, abortConstants.controllerConfig);
        isFyr.current = true;
      }

      if (!isBranchList.current) {
        console.log("Fetching branch data...");
        await getBranch(user.userid, abortConstants.controllerConfig);
        isBranchList.current = true;
      }

      if (branchSelect && selectedFyr && !initialLoading.current) {
        console.log("Fetching all data...");
        await getAllData(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, 'Income,Expense', abortConstants.controllerConfig)
        initialLoading.current = true;
      }
    };

    fetchData();

    return () => {
      console.log("Cleanup");
      abortConstants.controller.abort();
      toast.dismiss();
    };
  }, [token, companyId, user, branchSelect, selectedFyr]);

  const getBranch = async (userId, abortToken) => {
    console.log("inside branch latest");
    try {
      const result = await getPermittedBranch(userId, abortToken);//api call to get all branch
      console.log("dashbranch", result);
      isBranchList.current = true;
      if (result?.data?.branch.length !== 0) {
        setBranchList(result?.data?.branch);
        if (!user?.branchid) {
          // Set branchSelect to the first branch ID if user does not have a branch ID
          setbranchSelect(result?.data?.branch[0].branchid);
          const brName = result.data?.branch[0].branchname;
          setBranchName(brName);
        } else {
          const brName = result.data?.branch
            .filter(item => branchSelect === item.branchid)
            .map(item => item.branchname);
          setBranchName(brName);
        }
      }
      else {
        toast.error(result?.data?.message, {
          autoClose: 3000, // Close the toast after 5 seconds
        });
      }
    } catch (error) {
      handleApiErrors(error, 'Branch', 'branch_response', 'branch_timeout', 'branch_request', setIsLoading)
    }
  }

  const getFyrByCriteria = async (id, abortToken) => {
    console.log("inside fyr", id);
    try {
      const result = await getFyrByCompany(id, abortToken)
      console.log("fyr", result);
      setFyrList(result.data.FinancialYear)
      isFyr.current = true
      let fYear = result.data.FinancialYear.find(item => item.Iscurrent === 1)
      setSelectedFyr(fYear)
      setFyrSelect(fYear?.Fyrname)
      setFyrName(fYear?.Fyrname)
      setFrmdate(fYear?.Fyrfrom)
      setTodate(fYear?.Fyrto)
    }
    catch (error) {
      handleApiErrors(error, 'Financial Year', 'fyr_response', 'fyr_timeout', 'fyr_request', setIsLoading)
    }
  }


  const getAllData = (branchId, frm, to, head, abortToken) => {
    setFrDate(changeFormat(dayjs(frm), user?.company?.DateFormatname))//change the format of from date
    setTDate(changeFormat(dayjs(to), user?.company?.DateFormatname))//change the format of to date
    getProfitLossData(branchId, frm, to, head, abortToken)
  }

  //profitloss  
  const getProfitLossData = async (branchId, frm, to, head, abortToken) => {
    console.log(branchId, frm, to, head);
    try {
      //should be revised if error comes
      const result = await getProfitLoss(branchId, frm, to, head, abortToken);
      console.log(result);
      const data = result?.data?.ProfitLoss;

      const getValue = (type) => {
        return Math.abs(data?.find(item => item.transactionType === type)?.value || 0);
      };

      const getColor = (type) => {
        return (data?.find(item => item.transactionType === type)?.value < 0) ? '#9C4855' : '#489C8F'
      };

      const getBarColor = (value) => {
        return value < 0 ? '#9C4855' : '#489C8F'
      };

      const openingStock = getValue('Opening Stock');
      const purchaseAccounts = getValue('Purchase Accounts');
      const closingStock = getValue('Closing Stock');
      const directExpenses = getValue('Direct Expenses');
      const directIncomes = getValue('Direct Incomes');
      const salesAccounts = getValue('Sales Accounts');
      const indirectExpenses = getValue('Indirect Expenses');
      const indirectIncomes = getValue('Indirect Incomes');

      const totalSales = salesAccounts + directIncomes;
      const cos = openingStock + purchaseAccounts - closingStock;
      const gross = totalSales - (cos + directExpenses);

      const excludedTypes = ['Direct Expenses', 'Direct Incomes', 'Sales Accounts', 'Indirect Expenses', 'Indirect Incomes', 'Opening Stock', 'Purchase Accounts', 'Closing Stock'];
      const otherExpensesSum = data?.filter(item => !excludedTypes.includes(item.transactionType))
        .reduce((sum, item) => sum + Math.abs(item.value), 0) + indirectExpenses;
      setOtherExpenses(otherExpensesSum);
      console.log(otherExpensesSum);

      const net = (gross + indirectIncomes) - otherExpensesSum;
      const graphData = [
        { name: 'Revenue', value: salesAccounts, fill: getColor('Sales Accounts') },
        { name: 'Direct Incomes', value: directIncomes, fill: getColor('Direct Incomes') },
        { name: 'Cost Of Sales', value: cos, fill: getBarColor(cos) },
        { name: 'Direct Expenses', value: directExpenses, fill: getColor('Direct Expenses') },
        { name: 'GP', value: gross, fill: getBarColor(gross) },
        { name: 'Other Incomes', value: indirectIncomes, fill: getColor('Indirect Incomes') },
        { name: 'Other Expenses', value: otherExpensesSum, fill: getBarColor(otherExpensesSum) },
        { name: 'NP', value: net, fill: getBarColor(net) }
      ]
      setChartData(graphData)
      setCostOfSales(cos);
      setGrossProfit(gross);
      setNetProfit(net);
      setProfitLoss(data)
    }
    catch (error) {
      handleApiErrors(error, 'Profit & Loss', 'profitloss_response', 'profitloss_timeout', 'profitloss_request', setIsLoading)
    }
  }

  const getProfitLossDetailData = async (branchId, frm, to, head, abortToken) => {
    console.log(branchId, frm, to, head);
    try {
      //should be revised if error comes
      const result = await getProfitLoss(branchId, frm, to, head, abortToken)
      console.log(result);
      setDetailData(result?.data?.ProfitLoss)
    }
    catch (error) {
      handleApiErrors(error, 'Profit & Loss', 'profitloss_response', 'profitloss_timeout', 'profitloss_request', setIsLoading)
    }
  }

  //for search
  const handleSearchClick = (branchId, frm, to) => {
    console.log(branchId, frm, to);
    toast.dismiss()
    setProfitLoss(null)
    setIsDetail(false)
    setIsLoading({})
    getAllData(branchId, frm, to, 'Income,Expense', abortConstants.controllerConfig)
  }

  //for loading circular progress
  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`] ||
      isLoading.branch_response || isLoading.branch_request || isLoading.branch_timeout) {
      return true
    }
    else {
      return false
    }
  }

  // to disable/enable search button
  const isLoadingTrue = () => {
    const state = (Object.keys(isLoading).length === 0) && (!profitLoss)
    return state
  }

  const exportToExcel = () => {
    window.print()
    // Extract only the required fields from inventoryStock
    // const dataToExport = profitLoss.map(item => ({
    //   'Product Code': item.ProductCode,
    //   'Product Name': item.ProductName,
    //   'Description': item.Description,
    //   'Unit Name': item.UnitName,
    //   'MRP': changeCurrencyFormat(item.MRP, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL'),
    //   'Stock Qty': item.Units,
    //   'Cost Price': changeCurrencyFormat(item.CostPrice, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL'),
    //   'Stock Value': changeCurrencyFormat(item.Units * item.CostPrice, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')
    // }));

    // // Calculate Total Stock Value
    // const totalStockValue = profitLoss.reduce((total, row) => total + row.Units * row.CostPrice, 0);

    // // Append Total Stock Value as a row to dataToExport
    // dataToExport.push({
    //   'Product Code': 'Total Stock Value',
    //   'Stock Value': changeCurrencyFormat(totalStockValue, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')
    // });

    // const ws = XLSX.utils.json_to_sheet(dataToExport);
    // const wb = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.writeFile(wb, 'Inventory Report.xlsx');
  };

  const getCurrencyFormat = (value) => {
    return changeCurrencyFormat(value, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")
  }

  const getDetail = (head) => {
    if (isDetail) {
      setDetailData(null)
      handleOpen()
      setPath([head])
      getProfitLossDetailData(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, head, abortConstants.controllerConfig)
    }
  }

  const getDiggedDetail = (head) => {
    setDetailData(null)
    getProfitLossDetailData(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, head, abortConstants.controllerConfig)
  }

  const close = () => {
    if (detailData || renderLoading('profitloss') === true) {
      handleClose()
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container >
          {/* header */}
          <Grid item xs={12}>
            {branchList && fyrList && (
              <ProfitLossHeader
                branchName={branchName}
                frDate={frDate}
                tDate={tDate}
                isLoadingTrue={isLoadingTrue}
                branchSelect={branchSelect}
                setbranchSelect={setbranchSelect}
                branchList={branchList}
                handleSearch={handleSearchClick}
                setbranchSelectSearch={setbranchSelectSearch}
                setBranchName={setBranchName}
                frmDate={frmDate}
                toDate={toDate}
                setFrmdate={setFrmdate}
                setTodate={setTodate}
                fyrSelect={fyrSelect}
                setFyrSelect={setFyrSelect}
                fyrList={fyrList}
                setFyrName={setFyrName}
                selectedFyr={selectedFyr}
                setSelectedFyr={setSelectedFyr}
                optionTitle="Group selection"
                optionList={Grouptype}
                optionSelect={groupSelect}
                setOptionSelect={setGroupSelect}
                optionKey="ProductGroupName"
                exportExcel={exportToExcel}
                companyName={company.companyname}
                title="P & L Report"
              />
            )}
          </Grid>
          <Grid item xs={12} display='flex' justifyContent='end' >
            <Box className='box flex' >
              <Typography className='typography-switch size'>Consolidated</Typography>&nbsp;&nbsp;
              <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={isDetail} onChange={() => setIsDetail(!isDetail)} />&nbsp;&nbsp;
              <Typography className='typography-switch size'>Detail</Typography>
            </Box>
          </Grid>
          <Grid item xs={12}  >
            {profitLoss && chartData ? (<>{profitLoss?.length !== 0 ?
              <Grid container>
                <Grid item xs={12} md={isDetail? 12: 4}>
                  <Box display='flex' justifyContent='center' mb={2} pl={2}>
                    <TableContainer component={Paper} sx={{ maxHeight: 580, margin: 'auto', width: isDetail? '70%': '100%' }}>
                      <Table size="small" stickyHeader aria-label="spanning table">
                        <TableHead>
                          <TableRow >
                            <TableCell align="left" colSpan={3} className="table tableCellPadding tableCellColor tableCellBold tableCellMargin">Particulars</TableCell>
                            {/* <TableCell align="center" className="table tableCellPadding">{selectedFyr.Fyrfrom} to {selectedFyr.Fyrto}</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell className="table tableCellWidths75 tableCellMargin"><i>Trading Account :</i></TableCell>
                            <TableCell className="table tableCellWidths25"></TableCell>
                            {/* <TableCell className="table tableCell"></TableCell> */}
                          </TableRow>
                          <TableRow className={isDetail ? 'common-icon' : ''} onClick={() => getDetail('Sales Accounts')} >
                            <TableCell className="table tableCellWidths75 tableCellMargin">Revenue</TableCell>
                            {/* <TableCell className="table tableCell tableCellWidths35"></TableCell> */}
                            <TableCell align='right' className="table tableCellWidths25">{getCurrencyFormat(profitLoss?.find(item => item.transactionType === 'Sales Accounts')?.value)}</TableCell>
                          </TableRow>
                          <TableRow className={isDetail ? 'common-icon' : ''} onClick={() => getDetail('Direct Incomes')}>
                            <TableCell className="table tableCellWidths75 tableCellMargin">Direct Incomes</TableCell>
                            {/* <TableCell className="table tableCell tableCellWidths35"></TableCell> */}
                            <TableCell align='right' className="table tableCellWidths25">{getCurrencyFormat(profitLoss?.find(item => item.transactionType === 'Direct Incomes')?.value)}</TableCell>
                          </TableRow>
                          {/* {isDetail && <TableRow>
                          <TableCell className="table tableCell tableCellWidths40"></TableCell>
                          <TableCell className="table tableCell tableCellWidths35"></TableCell>
                          <TableCell align='right' className="table tableCell tableCellWidths25">
                            {getCurrencyFormat(profitLoss?.find(item => item.transactionType === 'Sales Accounts')?.value + profitLoss?.find(item => item.transactionType === 'Direct Incomes')?.value)}
                          </TableCell>
                        </TableRow>} */}
                          <TableRow>
                            <TableCell className={`${isDetail ? "table tableCellWidths75" : "table tableCellWidths75 tableCellMargin"}`}>{isDetail && <span className='table tableCellsubTitle'>(=)</span>}Cost of sales</TableCell>
                            {/* <TableCell className="table tableCell"></TableCell> */}
                            <TableCell align='right' className="table tableCellWidths25">{getCurrencyFormat(costOfSales)}</TableCell>
                          </TableRow>
                          {isDetail && <TableRow>
                            <TableCell className="table tableCellWidths75 tableCellSubLight"><span className='table tableCellsubTitle'>(+)</span><span className='tableCell subText'>Opening Stock</span></TableCell>
                            <TableCell align='right' className="table tableCellWidths25">{getCurrencyFormat(profitLoss?.find(item => item.transactionType === 'Opening Stock')?.value)}</TableCell>
                            {/* <TableCell className="table tableCell"></TableCell> */}
                          </TableRow>}
                          {isDetail && <TableRow className={isDetail ? 'common-icon' : ''} onClick={() => getDetail('Purchase Accounts')}>
                            <TableCell className="table tableCellWidths75"><span className='table tableCellsubTitle'>(+)</span><span className='tableCell subText'>Add: Purchase Accounts</span></TableCell>
                            <TableCell align='right' className="table tableCellWidths25">{getCurrencyFormat(profitLoss?.find(item => item.transactionType === 'Purchase Accounts')?.value)}</TableCell>
                            {/* <TableCell className="table tableCell"></TableCell> */}
                          </TableRow>}
                          {isDetail && <TableRow>
                            <TableCell className="table tableCellWidths75"><span className='table tableCellsubTitle'>(-)</span><span className='tableCell subText'>Less: Closing Stock</span></TableCell>
                            <TableCell align='right' className="table tableCellWidths25">{getCurrencyFormat(profitLoss?.find(item => item.transactionType === 'Closing Stock')?.value)}</TableCell>
                            {/* <TableCell className="table tableCell"></TableCell> */}
                          </TableRow>}
                          {/* <TableRow>
                          <TableCell className="table tableCell tableCellWidths40"></TableCell>
                          <TableCell align='right' className="table tableCell tableCellWidths35">{getCurrencyFormat(costOfSales)}</TableCell>
                          <TableCell className="table tableCell tableCellWidths25"></TableCell>
                        </TableRow> */}
                          <TableRow className={isDetail ? 'common-icon' : ''} onClick={() => getDetail('Direct Expenses')}>
                            <TableCell className="table tableCellWidths75 tableCellMargin">Direct Expenses</TableCell>
                            <TableCell align='right' className="table tableCellWidths25">{getCurrencyFormat(profitLoss?.find(item => item.transactionType === 'Direct Expenses')?.value)}</TableCell>
                            {/* <TableCell className="table tableCell "></TableCell> */}
                          </TableRow>
                          <TableRow className="table tableRowBackground">
                            <TableCell className="table tableCell-heading tableCellBold tableCellWidths75 tableCellMargin">Gross Profit :</TableCell>
                            {/* <TableCell className="table tableCell"></TableCell> */}
                            <TableCell align='right' className="table tableCell-heading tableCellBold tableCellWidths25">{getCurrencyFormat(grossProfit)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="table tableCellWidths75 tableCellMargin"><i>Income Statement :</i></TableCell>
                            <TableCell className="table tableCellWidths25"></TableCell>
                            {/* <TableCell className="table tableCell"></TableCell> */}
                          </TableRow>
                          <TableRow className={isDetail ? 'common-icon' : ''} onClick={() => getDetail('Indirect Incomes')}>
                            <TableCell className="table tableCellWidths75 tableCellMargin">{isDetail ? 'Indirect Incomes' : 'Other Incomes'}</TableCell>
                            {/* <TableCell className="table tableCell tableCellWidths35"></TableCell> */}
                            <TableCell align='right' className="table tableCellWidths25 ">{getCurrencyFormat(profitLoss?.find(item => item.transactionType === 'Indirect Incomes')?.value)}</TableCell>
                          </TableRow>
                          {!isDetail && <TableRow>
                            <TableCell className="table tableCellWidths75 tableCellMargin">Other Expense</TableCell>
                            {/* <TableCell className="table tableCell tableCellWidths35"></TableCell> */}
                            <TableCell align='right' className="table tableCellWidths25 ">{getCurrencyFormat(otherExpenses)}</TableCell>
                          </TableRow>}
                          {/* {isDetail && <TableRow>
                          <TableCell className="table tableCellWidths75"></TableCell>
                          <TableCell className="table tableCell tableCellWidths35"></TableCell>
                          <TableCell align='right' className="table tableCellWidths25">{getCurrencyFormat(grossProfit + profitLoss?.find(item => item.transactionType === 'Indirect Incomes')?.value)}</TableCell>
                        </TableRow>} */}
                          {isDetail && <>
                            <TableRow className={isDetail ? 'common-icon' : ''} onClick={() => getDetail('Indirect Expenses')}>
                              <TableCell className="table tableCellWidths75 tableCellMargin">Indirect Expenses</TableCell>
                              {/* <TableCell className="table tableCell tableCellWidths35"></TableCell> */}
                              <TableCell align='right' className="table tableCellWidths25 ">{getCurrencyFormat(profitLoss?.find(item => item.transactionType === 'Indirect Expenses')?.value)}</TableCell>
                            </TableRow>
                            {profitLoss.filter(item => !excludedTypes.includes(item.transactionType)).map((item, index) => (
                              <TableRow key={index} className={isDetail ? 'common-icon' : ''} onClick={() => getDetail(item.transactionType)}>
                                <TableCell className="table tableCellWidths75 tableCellMargin">{item.transactionType}</TableCell>
                                {/* <TableCell className="table tableCell tableCellWidths35"></TableCell> */}
                                <TableCell align='right' className="table tableCellWidths25 ">{getCurrencyFormat(item.value)}</TableCell>
                              </TableRow>
                            ))}
                          </>}
                          <TableRow className="table tableRowBackground">
                            <TableCell className="table tableCell-heading tableCellBold tableCellWidths75 tableCellMargin">Net Profit :</TableCell>
                            {/* <TableCell className="table tableCell"></TableCell> */}
                            <TableCell align='right' className="table tableCell-heading tableCellBold tableCellWidths25">{getCurrencyFormat(netProfit)}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
               {!isDetail && <Grid item xs={12} md={8}>
                  <Box pl={2}>
                    <Card sx={{height: '395px'}}>
                      <ProfitLossChart chartData={chartData} />
                    </Card>
                  </Box>
                </Grid>}
              </Grid>
              : <h5 style={{ textAlign: "center" }}>No data</h5>
            }</>) : (
              <>
                {renderLoading("profitloss") ? (
                  <h5 style={{ textAlign: "center" }}>No data</h5>
                ) : (
                  <Box className='box center group' sx={{ marginTop: "20px" }}>
                    <img src={process.env.PUBLIC_URL + '/assets/images/loading.png'} alt="loading" width={250} height={250} />
                    <span style={{ marginBottom: '10px' }}>Almost There! Your P&L Report is on Its Way...</span>
                    <CircularProgress />
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>

        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-description" >
              {/* {detailData ? <>
                {detailData.length !== 0 ? */}
              <DetailTable tableData={detailData} path={path} setPath={setPath} getDiggedDetail={getDiggedDetail} renderLoading={renderLoading} detailData={detailData} close={close} />
              {/* :
                  <h5 style={{ textAlign: "center" }}>No data</h5>
                }
              </> :
                <>
                  {renderLoading("profitloss") ? (
                    <h5 style={{ textAlign: "center" }}>No data</h5>
                  ) : (
                    <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </>
              } */}
            </Typography>
          </Box>
        </Modal>
      </Box>
    </ThemeProvider>)
}

export default ProfitLossReport