import React from 'react'
import Header from './Header'
import { Box, Button} from '@mui/material'
import Branch from '../pages/masterFiles/Branch'
import UserForm from '../components/forms/UserForm'
import { useSelector } from 'react-redux'
import { use } from 'i18next'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'

function UserProfile({getOnboardingStep, goToPreviousStep}) {
    const user = useSelector(state => state.user);
    console.log(user);
    const location = useLocation()
    const navigate = useNavigate()
    const hideBackButton = location.state?.hideBackButton

    const userUpdate = (message) => {
        toast.success(message, {
          autoClose: 3000, // Close the toast after 5 seconds
        });
        getOnboardingStep()
      };
    return (
        <Box>
            <Header />
            <Box sx={{mt:'100px', ml: '150px', mr: '150px', backgroundColor:'white'}}>
               <Box sx={{p:3}}>
               <UserForm onBoarding={true} isEdit={true} userId={user?.userid} onUserUpdated={userUpdate} hideBackButton={hideBackButton} goToPreviousStep ={goToPreviousStep}/>
               </Box>
               {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 3 }}>
                    <Button className='btn btn-search'>Next</Button>
                </Box> */}
            </Box>
        </Box>
    )
}

export default UserProfile