const gridStyles = {
  root: {
    border: 'none',
    fontSize: '12px',
    fontWeight: 400,
    width: '100%',
    '& .MuiDataGrid-cell': {
      borderRight: '1px solid #DADADA',
    },
    '& .MuiDataGrid-columnHeaders': {
      color: '#489C8F',
      fontWeight: 500,
      fontSize: '12px'
    },
    '& .super-app-theme--header': {
      borderRight: '1px solid #DADADA',
    },
  },
};

const textboxStyle = {
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#489C8F",
    },
  },
};

const modalStyle = {
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    maxHeight: '600px',
    overflowY: { xs: 'scroll', md: 'hidden' }
  },
};

const autocompleteStyle = {
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: '#F4FFFD', // Set your desired background color here
      borderRadius: '30px',
      fontSize: '12px',
      // height: '36px', // Increase the height to allow more space for text
    },
  
  },
};

const mainAutocompleteStyle = {
  root: {
    '& .MuiInputBase-root': {
      backgroundColor: '#F4FFFD', // Set your desired background color here
      borderRadius: '8px',
      fontSize: '12px',
      width:'100%'
      // height: '36px', // Increase the height to allow more space for text
    },
  
  },
};

const dialogPaperProps = {
  root: {
    position: 'absolute',
    bottom: '80px',
    right: '20px',
    height: '400px',
    width: '300px',
    borderRadius: '10px',
  },
};

const searchField = {
  root: {
    width: '100%',
    backgroundColor: '#E6FFFA',
    borderRadius: '5px',
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none', // Change or remove the underline
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none', // Change or remove the underline after interaction
    },
    '& .MuiFilledInput-underline:hover:before': {
      borderBottom: 'none', // Change or remove the underline on hover
    },
    '& .MuiInputBase-input': {
      height: '10px', // Adjust the height as needed
    },
    '& .Mui-focused .MuiFilledInput-underline:after': {
      borderBottom: 'none', // Remove underline on focus
    },
  },
};

const textField = {
  root: {
    width: '100%',
    marginTop: 'auto', // Push the text field to the bottom
    backgroundColor: '#7FD3C5',
    borderRadius: '5px',
    '& .MuiFilledInput-underline:before': {
      borderBottom: 'none', // Change or remove the underline
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: 'none', // Change or remove the underline after interaction
    },
    '& .MuiFilledInput-underline:hover:before': {
      borderBottom: 'none', // Change or remove the underline on hover
    },
    '& .MuiInputBase-input': {
      height: '10px', // Adjust the height as needed
    },
  }
}

export default { gridStyles, textboxStyle, modalStyle, autocompleteStyle, dialogPaperProps, textField, searchField, mainAutocompleteStyle }

