import React, { useState, useEffect, useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LatestLogin from "../Assets/Images/LatestLogin 1.png"
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import RCORLOGO from '../Assets/Images/RCOR_DRK 1.png'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setUser, setToken, setMenu } from '../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withSplashScreen from '../components/withSplashScreen';

const API_URL = process.env.REACT_APP_BASE_URL
const SITE_KEY = process.env.REACT_APP_SITE_KEY

function NewLogin({ apiCallProgress }) {
    const recaptchaRef = useRef(null);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [viewPassword, setViewPassword] = useState(false)
    const [errMsg, setErrMsg] = useState()
    const [isValid, setIsValid] = useState(false)
    const [islogin, setIsLogin] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [recaptchaValue, setRecaptchaValue] = useState('');
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

    useEffect(() => {
        // Load reCAPTCHA Enterprise API when the component mounts
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/enterprise.js';
        script.async = true;
        script.defer = true;
        script.onload = () => setRecaptchaLoaded(true);
        document.head.appendChild(script);

        return () => {
            // Cleanup the script when the component unmounts
            document.head.removeChild(script);
        };
    }, []);
    // useEffect(() => {
    //     console.log('login usereffect');
    //     if (token !== null && userHome) {
    //         console.log("redux", token);
    //         localStorage.setItem("token", token)
    //         console.log(localStorage.getItem("token"));
    //         console.log('login usereffect if', userHome);
    //         // navigate(`/${userHome}`)
    //         navigate('/')
    //     }
    // }, [token, userHome])

    const handleLogin = async (e) => {
        e.preventDefault()
        if (recaptchaLoaded) {
            // Execute reCAPTCHA verification
            window.grecaptcha.enterprise.execute(SITE_KEY, { action: 'login' })
                .then((token) => {

                    // Make a POST request to your Go server with the recaptchaValue
                    fetch(`${API_URL}/auth`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                        body: {
                            "Username": username,
                            "Password": password,
                            'g-recaptcha-response': token,
                        },
                    })
                        .then((response) => response.json())
                        .then((result) => {
                            console.log(result);
                            // Handle the server response accordingly
                            if (result.data?.token) {
                                console.log(result);
                                apiCallProgress(true)
                                localStorage.setItem("token", result.data?.token)
                                dispatch(setToken({ token: result?.data?.token }))
                                dispatch(setUser({ user: result?.data?.user }))
                                getMenu(result.data?.user.usertypeid, result.data?.token)
                                setErrMsg(null)
                                setIsLogin(false)
                            }
                            else {
                                alert('Error occured')
                            }
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                            apiCallProgress(false)
                            setIsLogin(false)
                            console.log(error);
                            if (error.response?.data?.message) {
                                toast.error(error?.response?.data?.message, {
                                    autoClose: 5000, // Close the toast after 5 seconds
                                });
                                setIsValid(false)
                                setErrMsg(error?.response?.data?.message)
                            }
                            else {
                                toast.error(error?.message, {
                                    autoClose: 5000, // Close the toast after 5 seconds
                                });
                                setIsValid(false)
                                setErrMsg(error?.message)
                            }
                        });
                });
        }
    };

    const userlogin = async (userData) => {
        console.log(userData);
        setIsValid(true)
        setIsLogin(true)
        try {
            const response = await axios.post(`${API_URL}/auth`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                    // 'mode' : 'cors'
                    // 'Origin': 'http://localhost:3000'
                },
                // withCredentials: false,
                mode: 'cors',
            })
            if (response.data?.token) {
                console.log(response);
                apiCallProgress(true)
                localStorage.setItem("token", response.data?.token)
                dispatch(setToken({ token: response?.data?.token }))
                dispatch(setUser({ user: response?.data?.user }))
                getMenu(response.data?.user.usertypeid, response.data?.token)
                setErrMsg(null)
                setIsLogin(false)
            }
            else {
                alert('Error occured')
            }
        }
        catch (error) {
            apiCallProgress(false)
            setIsLogin(false)
            console.log(error);
            if (error.response?.data?.message) {
                toast.error(error?.response?.data?.message, {
                    autoClose: 5000, // Close the toast after 5 seconds
                });
                setIsValid(false)
                setErrMsg(error?.response?.data?.message)
            }
            else {
                toast.error(error?.message, {
                    autoClose: 5000, // Close the toast after 5 seconds
                });
                setIsValid(false)
                setErrMsg(error?.message)
            }
        }
    }


    const getMenu = async (userTypeId, token) => {
        try {
            const response = await axios.get(`${API_URL}/SubMenu/Permitted/${userTypeId}`, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json',
                }
            })
            if (response?.data?.SubMenu && response?.data?.SubMenu?.length !== 0) {
                // const updatedArray = response?.data?.SubMenu?.map(item => {
                //     // Check if the item has subMenu_route equal to 'chart_of_accounts' and update the route if true
                //     if (item.subMenu_route === 'chart_of_accounts') {
                //       return { ...item, subMenu_route: 'chart_of_accounts/:head' };
                //     }
                //     return item;
                //   });
                // let route = response.data?.SubMenu?.find(item => item.isDashboar === 1)
                // if (route?.subMenu_route) {
                    dispatch(setMenu({ menu: response.data.SubMenu }));
                    apiCallProgress(false)
                    navigate('/')
                    // setTimeout(() => {
                    //     apiCallProgress(false)
                    //     navigate(`/${route.subMenu_route}`)
                    // }, 3000)
                    setIsValid(false);
                    // setIsLogin(false)
                    // (menuArray && userHome) ? navigate(`/${route.subMenu_route}`) : navigate('/')
                    // toast.success('You have successfully logged in.', {
                    //     autoClose: 5000, // Close the toast after 5 seconds
                    // });
                // }
                // else {

                // }
            }
        }
        catch (error) {
            apiCallProgress(false)
            setIsValid(true)
            toast.error(error?.message, {
                autoClose: 5000, // Close the toast after 5 seconds
            });
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        const captchaToken = await recaptchaRef.current.getValue();
        // console.log(captchaToken);
        let userData = {
            "Username": username,
            "Password": password,
            "token": captchaToken
        }
        let captcha = {
            "g-recaptcha-response": captchaToken,
        }
        userlogin(userData)
    }

    return (
        <div className='boxstyle'>
            <Grid container sx={{ padding: { xs: 0, sm: 5 }, maxWidth: '1920px', margin: '0 auto' }}>
                <CssBaseline />
                <Grid item xs={false} sm={3} md={3} ></Grid>
                <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }} className='load bgcolor'>
                    <Box className='alignstyle' >
                        <img src={RCORLOGO} alt="LOGO" width={85} height={30} />
                    </Box>
                    <Box className='alignstyle' mt={3} >
                        <img src={LatestLogin} alt="Login" width={220} height={150} />
                        <Typography className='typography heading-h' mt={3}> RCOR INTELLIGER </Typography>
                        <Typography className='typography heading-h2' mt={1}> Sales Analysis System </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3} md={3} component={Paper} elevation={0} square className='load bgcolor-a4s'>
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }} className='boxalign2'>
                        <img src={RCORLOGO} alt="LOGO" width={85} height={30} />
                    </Box>
                    <Box className='alignstyle1' >

                        <Typography className='typography heading-h1' mt={3}> Sign in </Typography>
                        <Typography className='typography heading-h3' mt={1} >To Access RCOR Intelliger  </Typography>
                        {/* {isValid && <Box > */}
                        {/* <CircularProgress /> */}
                        {/* <span className="loading-dots">......</span>  */}
                        {/* </Box>} */}
                        {/* {errMsg && <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                        <Alert severity="error">{errMsg}</Alert>
                        </Box>} */}
                    </Box>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 8 }} className='boxalign1' >

                        <Typography className="typography heading-h3"> User Name </Typography>
                        <TextField className='textfiled style-1' required id="email" name="email" autoComplete="email" autoFocus variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton>
                                            <Person2RoundedIcon className="icon style-i" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            value={username}
                            onChange={(e) => { setUsername(e.target.value); setErrMsg(null) }} />
                        <Typography className="typography heading-h3" mt={1}>
                            {" "}
                            Password{" "}
                        </Typography>
                        <TextField className='textfiled style-1'  required name="password" type={viewPassword ? 'text' : 'password'} id="password" autoComplete="current-password" variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setViewPassword(!viewPassword)}
                                        // onMouseDown={handleMouseDownPassword}
                                        >
                                            {viewPassword ? <VisibilityOff className="icon style-i" /> : <Visibility className="icon style-i" />}
                                        </IconButton>
                                    </InputAdornment>),
                            }}
                            value={password}
                            onChange={(e) => { setPassword(e.target.value); setErrMsg(null) }}
                        />
                        <Box mt={2}>
                            <ReCAPTCHA sitekey={SITE_KEY} ref={recaptchaRef} />

                        </Box>
                        <FormControlLabel control={<Checkbox value="remember" style={{ color: "#489C8F" }} />}
                            label={<Typography className="typography heading-h3"> Remember Me </Typography>} />

                        {!islogin && <Button type="submit" className='btn btn-log' > Sign In </Button>}
                        {islogin && <Button className='btn btn-disable' ><span className="btn-text">Signing</span><div className="spinner"></div>
                        </Button>}

                    </Box>
                    <Box className='boxalignbtn'>
                        <Grid container className='boxalign' >
                            <Grid item xs={6} mt={1} className='boldstyle-f' >
                                <Link href="#" className='link link-varient' >
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item xs={6} mt={1} className='boldstyle-f' mb={3}>
                                <Link href="#" className='link link-varient'>
                                    Sign Up
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item xs={false} sm={3} md={3} ></Grid>
            </Grid>
        </div>
    )
}

export default withSplashScreen(NewLogin)