import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';

function SplashMessage() {
    const user = useSelector(state => state.user)
    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} display='flex' justifyContent='center'>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt='150px'>
                        <img src={process.env.PUBLIC_URL + `/assets/images/rcorLogo.png`} alt='logo' width={121} height={45} />
                        <Typography mt={5} mb={1} className='typography heading-h1'>Welcome, {user?.firstname}</Typography>
                        <Typography mb='80px' className='typography heading-h5'>Please wait. We are loading your data</Typography>
                        <img className="flip-animation" src={process.env.PUBLIC_URL + '/assets/images/rcorlogo_flip.png'} width={114} height={100} />
                    </Box>
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
        </Box>
    );
}

function withSplashScreen(WrappedComponent) {
    return function SplashScreenWrapper(props) {
        const token = useSelector(state => state?.token)
        const menuArray = useSelector(state => state?.menu)
        const [loading, setLoading] = useState(false);

        // useEffect(() => {
        //     console.log(token,menuArray);
        //     if(token && menuArray){
        //         setTimeout(()=>{
        //             setLoading(false)
        //         },3000)
        //     }

        // }, []);

        const apiCallProgress = (state) => {
            if (state) {
                setLoading(true)
            }
            else {
                setLoading(false)

            }
        }

        // While checking user session, show "loading" message
        if (loading) return <SplashMessage />;

        // Otherwise, show the desired route
        return <WrappedComponent apiCallProgress={apiCallProgress} />;
    };
}

export default withSplashScreen

