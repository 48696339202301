import React from 'react';
import { Box, Grid, Button, Typography, IconButton, Tooltip, Menu, Select, FormControl, MenuItem, } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import TabularFilter from './TabularFilter';
import InventoryFilter from './InventoryFilter';
import { useSelector } from 'react-redux';


function InventoryHeader({
    branchName,
    fyrName,
    fyrList,
    fyrSelect,
    frDate,
    tDate,
    // asOnDate,
    isLoadingTrue,
    branchSelect,
    setbranchSelect,
    setFyrSelect,
    setFyrName,
    branchList,
    handleSearchClick,
    handleSearch,
    setbranchSelectSearch,
    setBranchName,
    setFrmdate,
    setTodate,
    frmDate,
    toDate,
    selectedFyr,
    setSelectedFyr,
    optionTitle,
    optionSelect,
    setOptionSelect,
    optionList,
    optionKey,
    exportExcel,
    companyName,
    title
}) {
    // console.log(fyrList);
    const { t } = useTranslation()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const user = useSelector(state => state.user)


    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };



    console.log(fyrList);

    const search = () => {
        handleCloseMenu()
        const fyear = fyrList.find(item => item.Fyrname === fyrSelect);
        // setAsOnDate(fyear.AsonDate)
        setFyrName(fyear.Fyrname)

        const brName = branchList?.filter(item => branchSelect.includes(item.branchid))
            .map(item => item.branchname);
        setBranchName(brName)
        setbranchSelectSearch(branchSelect)
        handleSearch(branchSelect.join(','), fyear.Fyrfrom, fyear.Fyrto)
    }



    return (

        <Grid container spacing={1}>

            <Grid item xs={12} ml={2} mt={5} className='box space'>
            <span style={{ color: '#489C8F', fontSize: '15px', fontWeight: '500' }}>{title}</span>
            <span style={{ color: '#1F77B4', fontWeight: '500' }}>{user?.company?.companyname}</span>
            </Grid>

            <Grid item xs={12} ml={2} mt={1} style={{ textAlign: 'start', display: 'flex', justifyContent: 'space-between' }}>

                <Grid item >
                    <span className="span text-h1">
                        <b className="b text-h1" style={{ fontSize: '12px', fontWeight: 500, color: 'black' }}> Branch :  </b>
                        {/* <b className="b text-h1" style={{ fontSize: '12px', fontWeight: 500 }}> {companyName}
                            &nbsp;&nbsp; */}
                            <b className="b text-h1" style={{ color: '#1F77B4', fontSize: '12px', fontWeight: 500 }}>{branchName.join(" , ")}</b>

                        {/* </b> */}
                    </span>
                </Grid>


                <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <InventoryFilter

                        branchSelect={branchSelect}
                        setBranchSelect={setbranchSelect}
                        branchList={branchList}
                        handleSearch={handleSearch}
                        isLoadingTrue={isLoadingTrue}
                        setbranchSelectSearch={setbranchSelectSearch}
                        setBranchName={setBranchName}
                        setFrmdate={setFrmdate}
                        setTodate={setTodate}
                        frmDate={frmDate}
                        toDate={toDate}
                        //  optionTitle={optionTitle}
                        //  optionSelect={optionSelect}
                        //  setOptionSelect={setOptionSelect}
                        //  optionList ={optionList}
                        //  optionKey ={optionKey}
                        fyrSelect={fyrSelect}
                        setFyrSelect={setFyrSelect}
                        fyrList={fyrList}
                        setFyrName={setFyrName}
                        selectedFyr={selectedFyr}
                        setSelectedFyr={setSelectedFyr}
                    />

                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <DownloadIcon onClick={exportExcel} className='common-icon' style={{ marginTop: '8px' }} />
                </Grid>

            </Grid>


        </Grid>

    );
}

export default InventoryHeader;
