import React from 'react'
import { Box, Grid, Button, Typography, IconButton, Tooltip, Menu, Select, FormControl, MenuItem, } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DateCalander from './DateCalander';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSelector } from 'react-redux';
import { changeFormat } from '../HelperFile';

function ProfitLossHeader({
    branchName,
    fyrList,
    fyrSelect,
    isLoadingTrue,
    branchSelect,
    setbranchSelect,
    setFyrSelect,
    setFyrName,
    branchList,
    handleSearch,
    setbranchSelectSearch,
    setBranchName,
    setFrmdate,
    setTodate,
    frmDate,
    toDate,
    selectedFyr,
    setSelectedFyr,
    exportExcel,
    title
}) {

    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const user = useSelector(state => state.user)

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChangeFyrselect = (e) => {
        setFyrSelect(e.target.value);
        let fYear = fyrList?.find(item => item.Fyrname === e.target.value)
        setSelectedFyr(fYear)
    }
    const setFyrTextfield =(frm, to)=>{
        return changeFormat(dayjs(frm), user?.company?.DateFormatname) + '   to  ' + changeFormat(dayjs(to), user?.company?.DateFormatname)
    }

    const search = () => {
        handleCloseMenu()
        setFyrName(selectedFyr.Fyrname)
        const brName = branchList?.filter(item => branchSelect === item.branchid)
            .map(item => item.branchname);
        console.log(brName);
        setBranchName(brName)
        handleSearch(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto)
    }



    return (
        <Grid container spacing={1} >
            <Grid item xs={12} ml={2} mt={2} className='box space'>
                <span style={{ color: '#489C8F', fontSize: '15px', fontWeight: '500' }}>{title}</span>
                <span style={{ color: '#1F77B4', fontWeight: '500' }}>{user?.company?.companyname}</span>
            </Grid>
            <Grid item xs={12} ml={2} style={{ textAlign: 'start', display: 'flex', justifyContent: 'space-between' }}>
                <Grid item >
                    <span className="span text-h1">
                        <b className="b text-h1" style={{ fontSize: '12px', fontWeight: 500, color: 'black' }}> Branch :  </b>
                        <b className="b text-h1" style={{ color: '#1F77B4', fontSize: '12px', fontWeight: 500 }}>{branchName}</b>
                    </span>
                </Grid>
                <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* Date Picker Box */}
                    <Box sx={{ height: '35px', backgroundColor: 'white', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)', borderRadius: '5px' }}>
                        <FormControl required sx={{ width: '100%' }}>
                            <Select
                                size='small'
                                id="demo-simple-select-filled"
                                value={fyrSelect}
                                onChange={handleChangeFyrselect}
                                style={{height:'35px'}}
                            >
                                {fyrList ? fyrList?.map(item => (<MenuItem key={item.Fyrname} name={item.Fyrname} value={item.Fyrname}  >{setFyrTextfield(item.Fyrfrom, item.Fyrto)}</MenuItem>))
                                    : (<p style={{ marginLeft: '5px' }}>No financial year to display</p>)
                                }
                            </Select>

                        </FormControl>
                    </Box>
                    <Box sx={{ marginLeft: '20px', height:'35px' }}>
                        {/* Search Button */}
                        {!isLoadingTrue() && <Button fullWidth className="btn btn-search" onClick={() => search()} style={{ boxShadow: '0px 2px 4px rgba(0, 1, 0, 0.3)' }}>
                            {t('form.search')}</Button>}
                        {isLoadingTrue() && <Button fullWidth className="btn btn-filter-load" sx={{height:'35px'}}>
                            <span className="btn-text">{t('form.loading')}</span><div className="spinner"></div>
                        </Button>}
                    </Box>
                </Grid>
                <Grid item style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div  >
                        <Tooltip title='Search'>
                            <IconButton size='small' onClick={handleMenu} className='filter-style'><FilterAltIcon className='filter-style' /></IconButton>
                        </Tooltip>
                        <Menu
                            id="search"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            <Box className='search box' >
                                <Grid container spacing={1} >
                                    <Grid item xs={12} ><Typography className='text-color'> {t('home.branch')} </Typography></Grid>
                                    <Grid item xs={12} >
                                        <FormControl required sx={{ width: '100%' }}>
                                            <Select
                                                size="small"
                                                fullWidth
                                                id="demo-simple-select"
                                                name="branchid"
                                                value={branchSelect}
                                                onChange={(e) => { setbranchSelect(e.target.value) }}
                                            >
                                                {branchList?.map((item) => (
                                                    <MenuItem
                                                        key={item.branchid}
                                                        name={item.branchname}
                                                        value={item.branchid}
                                                    >
                                                        {item.branchname}
                                                        {/* <ListItemText primary={item.branchname} /> */}
                                                    </MenuItem>
                                                ))}

                                            </Select>

                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}  >
                                        {!isLoadingTrue() && <Button fullWidth className="btn btn-search" onClick={() => search()} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.7)' }}>
                                            {t('form.search')}</Button>}
                                        {isLoadingTrue() && <Button fullWidth className="btn btn-filter-load">
                                            <span className="btn-text">{t('form.loading')}</span><div className="spinner"></div>
                                        </Button>}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Menu>
                    </div>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <DownloadIcon onClick={exportExcel} className='common-icon' style={{ marginTop: '8px' }} />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ProfitLossHeader