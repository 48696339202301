import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null,
    user: null,
    menu: null,
    selectedParameters: null
    // selectedBranch: null,
    // selectedFyr: null,
    // selectedFromDate: null,
    // selectedToDate: null
}

export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUser: (state, action) =>{
            state.user = action.payload.user           
        },
        setToken: (state, action) =>{
            state.token = action.payload.token
        },
        setMenu: (state, action) =>{
            state.menu = action.payload.menu
        },
        setLogout: (state) => {
            state.user = null;
            state.token = null;
            state.menu = null;
            state.selectedParameters = null;
            // state.selectedBranch = null;
            // state.selectedFyr = null;
            // state.selectedFromDate = null;
            // state.selectedToDate = null;
        },
        setSearchParameters :(state, action) => {
            state.selectedParameters = action.payload.selectedParameters
        },
        clearSearchParameters :(state) => {
            console.log(state.selectedParameters);
            state.selectedParameters = null;
            console.log(state.selectedParameters);
        }
    }
})

export const {setUser, setToken, setMenu, setLogout, setSearchParameters, clearSearchParameters} = authSlice.actions;

export default authSlice.reducer;