import React, { useEffect, useRef } from 'react'
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

function LedgerExtraFilter({
    branchSelect,
    handleSearch,
    isLoadingTrue,
    optionSelect,
    setOptionSelect,
    optionList,
    optionKey
}) {
    const { t } = useTranslation()
    const [inputValue, setInputValue] = React.useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (optionSelect === null) {
            setInputValue('');
        }
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [optionSelect]);

    const search = (optionData) => {
        handleSearch(branchSelect, optionData)
    }
    console.log(optionSelect);
    return (
        <>

            {/* filter box start*/}

            <Box className='search box' >
                <Grid container spacing={1} >

                    <Grid item xs={12} style={{ display: 'flex' }}>
                        <Autocomplete
                            id="option-select"
                            options={optionList || []}
                            value={optionList?.find((item) => item[optionKey] === optionSelect) || null}
                            onChange={(event, newValue) => {
                                setOptionSelect(newValue ? newValue[optionKey] : '');
                                search(newValue ? newValue[optionKey] : '')
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newValue) => {
                                setInputValue(newValue)
                            }}
                            filterOptions={(options, params) => {
                                const input = params.inputValue ? params.inputValue.toLowerCase() : '';
                                return options?.filter(option =>
                                    option[optionKey].toLowerCase().startsWith(input)
                                );
                            }}
                            getOptionLabel={(option) => option[optionKey]}
                            renderOption={(props, option) => {
                                const index = optionList.indexOf(option);
                                return (
                                    <li {...props} key={index}>{option[optionKey]}</li>
                                )
                            }
                            }
                            freeSolo
                            renderInput={(params) => (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderRadius: '5px',
                                    width: '500px', backgroundColor: 'white', boxShadow: '0px 2px 4px rgba(0, 1, 0, 0.3)'
                                    ,
                                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                >
                                    <TextField {...params} variant="outlined" placeholder='Search Ledger' size="small"/>
                                    {/* <TextField
                                        {...params}
                                        label='Search Ledger'
                                        variant="outlined"
                                        inputRef={inputRef} // Attach the ref to the input
                                    /> */}

                                    {/* 
                                    {!isLoadingTrue() && <IconButton fullWidth className='icon background' onClick={() => search()}>
                                        <SearchIcon /></IconButton>}
                                    {isLoadingTrue() && <IconButton fullWidth className='icon background' >
                                        <span className="btn-text" style={{ fontSize: '15px' }}>{t('form.loading')}...</span><div className="spinner"></div>
                                    </IconButton>} */}


                                </Box>
                            )}


                        />
                    </Grid>
                </Grid>
            </Box>

            {/* filter box end */}
        </>
    )
}

export default LedgerExtraFilter