import React,{useState, useEffect} from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSelector } from 'react-redux';


export default function DateCalander({value, setValue, disableFuture, pastDate, frmDate, toDate}) {
const user = useSelector(state =>state.user)
const [dateFormat, setDateFormat] = useState()

useEffect(()=>{
  const dayFormat = (user?.company?.DateFormatname)?.toUpperCase()
  setDateFormat(dayFormat)
}, [user])

const shouldDisableDate = (date) => {
  // date.isBefore(pastDate, 'day');
  return date.isBefore(frmDate, 'day') || date.isAfter(toDate, 'day');
};

return (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value} onChange={(newValue) => setValue(newValue)}
        slotProps={{ textField: { size: 'small', fullWidth: true } }}
        format={dateFormat}
        disableFuture={disableFuture}
        // shouldDisableDate={shouldDisableDate}
        minDate={frmDate}
        maxDate={toDate}
      />        
  </LocalizationProvider>
);
}


