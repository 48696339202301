import axiosInstance from "./axiosInstance";

let api = axiosInstance()

// Voucher Type
export const getVoucherType = async(branchId, frm, to, type, abortToken)=>{
  console.log(branchId, frm, to, type);
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Vouchertype?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&VoucherType=${type}`, config)
}

//Transaction
export const getTransaction = async(branchId, frm, to, voucher, item, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/MISReports/TransactionRegister?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&VoucherType=${voucher}&TransactionType=${item}`, config)
}