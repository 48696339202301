import React, { useEffect, useRef, useState } from 'react'
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../components/styles/theme';
import dayjs from 'dayjs'
import { useSelector } from 'react-redux';
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from '../../api/HandleApiErrors';
import { useTranslation } from 'react-i18next';
import { getPermittedBranch } from '../../api/commonApi';
import { changeFormat, dateConvert, changeCurrencyFormat, getMonthRange } from '../../HelperFile';
import LedgerHeader from '../../components/LedgerHeader';
import LedgerExtraFilter from '../../components/LedgerExtraFilter';
import * as cashbankreportApi from '../../api/cashbankreportapi';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AntSwitch from '../../components/styles/AntSwitch';
import { getFyrByCompany } from '../../api/fyrApi';


function Ledger() {
  const { t } = useTranslation()
  const isBranchList = useRef(false)
  const isFyr = useRef(false)

  const initialLoading = useRef(false)
  const abortConstants = useSignalAbort()
  const token = useSelector(state => state.token)
  const user = useSelector(state => state.user)
  const selectedDetails = useSelector(state => state.selectedParameters)
  const companyId = user?.companyid
  const company = user?.company

  const [branchList, setBranchList] = useState()

  const [branchSelect, setbranchSelect] = useState()
  const [branchSelectSearch, setbranchSelectSearch] = useState()
  const [fyrList, setFyrList] = useState()
  const [fyrSelect, setFyrSelect] = useState('');
  const [toDate, setTodate] = useState('')
  const [frmDate, setFrmdate] = useState('')
  const [frDate, setFrDate] = useState()
  const [tDate, setTDate] = useState()
  const [selectedFyr, setSelectedFyr] = useState()
  const [branchName, setBranchName] = useState()
  const [isLoading, setIsLoading] = useState({})

  const [ledgerSelect, setLedgerSelect] = useState();
  const [Ledgertype, setLedgertype] = useState()
  const [ledgerBook, setLedgerBook] = useState()

  const [openingBalance, setOpeningBalance] = useState(0);
  const [openingBalanceSign, setOpeningBalanceSign] = useState("");
  const [closingBalanceSign, setClosingBalanceSign] = useState("");

  const [closingBalance, setClosingBalance] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [isDetailedView, setIsDetailedView] = useState(false);
  const [isSearch, setIssearch] = useState(false)

  //display message if default branch is not set to the user
  useEffect(() => {
    if (user && !user?.branchid) {
      toast.error("A default branch has not been assigned to you. Please contact your administrator", {
        autoClose: 3000, // Close the toast after 5 seconds
      });
    }
  }, [user])

  useEffect(() => {
    const fetchData = async () => {
      if (!token || !companyId || !user) return;

      if (!isFyr.current) {
        console.log("Fetching financial year data...");
        await getFyrByCriteria(companyId, abortConstants.controllerConfig);
        isFyr.current = true;
      }

      if (!isBranchList.current) {
        console.log("Fetching branch data...");
        await getBranch(user.userid, abortConstants.controllerConfig);
        isBranchList.current = true;
      }

      if (branchSelect && selectedFyr && !initialLoading.current) {
        console.log("Fetching voucher type...");
        const frm = dateConvert(dayjs(selectedFyr.Fyrfrom));
        const to = dateConvert(dayjs(selectedFyr.Fyrto));
        setFrDate(changeFormat(dayjs(frmDate), user?.company?.DateFormatname))//change the format of from date
        setTDate(changeFormat(dayjs(toDate), user?.company?.DateFormatname))//change the format of to date
        await getLedgerType(branchSelect, frm, to, abortConstants.controllerConfig);
        initialLoading.current = true;
      }
      // if (branchSelect && frmDate && toDate && !initialLoading.current) {
      //   console.log("Fetching all data...");
      //   await getAllData(branchSelect, frmDate, toDate, '', abortConstants.controllerConfig)
      //   initialLoading.current = true;
      // }
    };

    fetchData();

    return () => {
      console.log("Cleanup");
      abortConstants.controller.abort();
      toast.dismiss();
    };
  }, [token, companyId, user, branchSelect, selectedFyr, frmDate, toDate]);

  //get branch and financial year list
  // useEffect(() => {
  //   console.log("useeffect");
  //   if (token && user && isBranchList.current === false && frmDate && toDate) {
  //     console.log("User:", user);
  //     let frm = dateConvert(frmDate)
  //     let to = dateConvert(toDate)
  //     console.log(frm, to);
  //     getBranch(user?.userid, frm, to, abortConstants.controllerConfig)

  //   }

  //   return () => {
  //     console.log("inside cleanuo");
  //     // Cancel the request before the component unmounts
  //     abortConstants.controller.abort()
  //     toast.dismiss()
  //   };
  // }, [token, user, frmDate, toDate])

  // //get branch and financial year list
  // useEffect(() => {
  //   console.log("useeffect");
  //   if (token && companyId && isFyr.current === false) {
  //     console.log("fyr");
  //     getFyrByCriteria(companyId, abortConstants.controllerConfig)
  //   }

  //   return () => {
  //     console.log("inside cleanuo");
  //     // Cancel the request before the component unmounts
  //     abortConstants.controller.abort()
  //     toast.dismiss()
  //   };
  // }, [token, companyId])

  //call all api to get data
  // useEffect(() => {
  //   if (branchSelect.length !== 0 && frmDate && toDate && ledgerSelect && initialLoading.current === false) {
  //     console.log(frmDate, toDate);
  //     getAllData(branchSelect.join(','), frmDate, toDate, "", abortConstants.controllerConfig)
  //     initialLoading.current = true
  //   }
  //   return () => {
  //     console.log("inside cleanuo");
  //     // Cancel the request before the component unmounts
  //     abortConstants.controller.abort()
  //     toast.dismiss()
  //   };
  // }, [branchSelect, frmDate, toDate])

  const getFyrByCriteria = async (id, abortToken) => {
    try {
      const result = await getFyrByCompany(id, abortToken);
      setFyrList(result?.data?.FinancialYear);
      isFyr.current = true;

      const fYear = selectedDetails?.selectedFyr
        ? result?.data?.FinancialYear?.find(item => item.Fyrname === selectedDetails?.selectedFyr)
        : result?.data?.FinancialYear?.find(item => item.Iscurrent === 1);

      const fromDate = selectedDetails?.selectedFromDate || getMonthRange(fYear?.Fyrfrom, fYear?.Fyrto, dayjs().month())[0];
      const toDate = selectedDetails?.selectedToDate || getMonthRange(fYear?.Fyrfrom, fYear?.Fyrto, dayjs().month())[1];

      setSelectedFyr(fYear);
      setFyrSelect(fYear?.Fyrname);
      setFrmdate(dayjs(fromDate));
      setTodate(dayjs(toDate));
    } catch (error) {
      handleApiErrors(error, 'Financial Year', 'fyr_response', 'fyr_timeout', 'fyr_request', setIsLoading);
    }
  };

  const getBranch = async (userId, abortToken) => {
    try {
      const result = await getPermittedBranch(userId, abortToken);
      isBranchList.current = true;
      if (result?.data?.branch.length !== 0) {
        setBranchList(result?.data?.branch);
        const selectedBranchId = selectedDetails?.selectedBranch || (user?.branchid || result?.data?.branch[0]?.branchid);
        const selectedBranchName = result.data?.branch
          .filter(item => item.branchid === selectedBranchId)
          .map(item => item.branchname);

        setbranchSelect(selectedBranchId);
        setBranchName(selectedBranchName);
        // getLedgerType(selectedBranchId, frm, to, 'Cash-In-Hand', abortToken);
      } else {
        toast.error(result?.data?.message, {
          autoClose: 3000,
        });
      }
    } catch (error) {
      handleApiErrors(error, 'Branch', 'branch_response', 'branch_timeout', 'branch_request', setIsLoading);
    }
  };

  // const getBranch = async (userId, frm, to, abortToken) => {
  //   console.log("inside branch latest");
  //   try {
  //     const result = await getPermittedBranch(userId, abortConstants.controllerConfig);//api call to get all branch
  //     console.log("dashbranch", result);
  //     isBranchList.current = true;
  //     if (result?.data?.branch.length !== 0) {
  //       setBranchList(result?.data?.branch);
  //       if (!user?.branchid) {
  //         // Set branchSelect to the first branch ID if user does not have a branch ID
  //         setbranchSelect(result?.data?.branch[0].branchid);
  //         getLedgerType(result?.data?.branch[0].branchid, frm, to, abortToken)

  //         const brName = result.data?.branch[0].branchname;
  //         setBranchName(brName);
  //       } else {
  //         getLedgerType(branchSelect, frm, to, abortToken)
  //         const brName = result.data?.branch
  //           .filter(item => branchSelect === item.branchid)
  //           .map(item => item.branchname);
  //         setBranchName(brName);
  //       }
  //     }
  //     else {
  //       toast.error(result?.data?.message, {
  //         autoClose: 3000, // Close the toast after 5 seconds
  //       });
  //     }
  //   } catch (error) {
  //     handleApiErrors(error, 'Branch', 'branch_response', 'branch_timeout', 'branch_request', setIsLoading)
  //   }
  // }

  // const getFyrByCriteria = async (id, abortToken) => {
  //   try {
  //     const result = await getFyrByCompany(id, abortToken)
  //     console.log(result);
  //     setFyrList(result?.data?.FinancialYear)
  //     isFyr.current = true
  //     let fYear = result.data.FinancialYear.find(item => item.Iscurrent === 1)
  //     setSelectedFyr(fYear)
  //     setFyrSelect(fYear?.Fyrname)
  //     setFrmdate(dayjs(fYear?.Fyrfrom))
  //     setTodate(dayjs(fYear?.Fyrto))
  //     setFrDate(changeFormat(dayjs(fYear?.Fyrfrom), user?.company?.DateFormatname))//change the format of from date
  //     setTDate(changeFormat(dayjs(fYear?.Fyrto), user?.company?.DateFormatname))//change the format of to date
  //   }
  //   catch (error) {
  //     handleApiErrors(error, 'Financial Year', 'fyr_response', 'fyr_timeout', 'fyr_request', setIsLoading)
  //   }
  // }


  const getAllData = (branchId, ledger, abortToken) => {
    // setFrDate(changeFormat(dayjs(frm), user?.company?.DateFormatname))//change the format of from date
    // setTDate(changeFormat(dayjs(to), user?.company?.DateFormatname))//change the format of to date
    let fDate = dateConvert(frmDate)
    let tDate = dateConvert(toDate)
    console.log(fDate, tDate);
    getLedgerBook(branchId, fDate, tDate, ledger, abortToken)

  }


  //ledger List
  const getLedgerType = async (branchId, frm, to, abortToken) => {
    console.log(branchId, frm, to);
    try {
      const result = await cashbankreportApi.getLedgerType(branchId, frm, to, "", abortToken)
      console.log("ledger", result);
      setLedgertype(result?.data?.Ledger)
    }
    catch (error) {
      handleApiErrors(error, 'LedgerType', 'ledger_response', 'ledger_timeout', 'ledger_request', setIsLoading)
    }
  }


  //ledgerbook  
  const getLedgerBook = async (branchId, frm, to, ledger, abortToken) => {
    console.log(branchId, frm, to, ledger);
    try {
      const result = await cashbankreportApi.getLedgerBook(branchId, frm, to, ledger, abortToken);
      console.log(result);
      // Extract opening balance
      const openingBalance = result?.data?.ledger[0]?.opbal || 0;
      const absoluteOpening = Math.abs(openingBalance)

      const formattedOpeningBalance = openingBalance !== 0 ? changeCurrencyFormat(absoluteOpening, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL") : '';
      setOpeningBalance(formattedOpeningBalance);

      // Calculate total debit and total credit
      const { totalDebit, totalCredit } = result?.data?.ledger.reduce(
        (totals, row) => {
          if (row.IsdmdPositive === 'Yes') {
            totals.totalDebit += row.NetAmount;
          } else {
            totals.totalCredit += row.NetAmount;
          }
          return totals;
        },
        { totalDebit: 0, totalCredit: 0 }
      );

      // Set total debit and total credit in state
      setTotalDebit(changeCurrencyFormat(totalDebit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL"));
      setTotalCredit(changeCurrencyFormat(totalCredit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL"));

      // Extract closing balance
      const closingBalanceValue = openingBalance + (totalDebit - totalCredit);
      const absoluteClosing = Math.abs(closingBalanceValue)
      const formattedClosingBalance = closingBalanceValue !== 0 ? changeCurrencyFormat(absoluteClosing, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL") : '';
      setClosingBalance(formattedClosingBalance);

      // Determine opening balance sign
      const openingBalanceSign = openingBalance >= 0 ? "Dr" : "Cr";
      setOpeningBalanceSign(openingBalanceSign);

      // Determine closing balance sign
      const closingBalanceSign = closingBalanceValue >= 0 ? "Dr" : "Cr";
      setClosingBalanceSign(closingBalanceSign);

      // ... (existing code)

      const updatedLedgerBook = result?.data?.ledger.map((row) => ({
        ...row,
        Debit: row.IsdmdPositive === 'Yes' ? changeCurrencyFormat(row?.NetAmount, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL") : '',
        Credit: row.IsdmdPositive === 'No' ? changeCurrencyFormat(row?.NetAmount, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL") : '',
        VoucherDate: row.VoucherDate? changeFormat(dayjs(row.VoucherDate), user?.company?.DateFormatname) : '',
      }));
      if(updatedLedgerBook){
        setIssearch(false)
      }
      setLedgerBook(updatedLedgerBook);

    } catch (error) {
      setIssearch(false)
      handleApiErrors(error, 'LedgerBook', 'ledgerbook_response', 'ledgerbook_timeout', 'ledgerbook_request', setIsLoading);
    }
  };



  //for search
  const handleSearchLedger = (branchId, frm, to) => {
    console.log(branchId, frm, to);
    setFrDate(changeFormat(dayjs(frm), user?.company?.DateFormatname))//change the format of from date
    setTDate(changeFormat(dayjs(to), user?.company?.DateFormatname))//change the format of to date
    toast.dismiss()
    setLedgertype(null)
    setLedgerBook(null)
    setIsLoading({})
    getLedgerType(branchId, selectedFyr?.Fyrfrom, selectedFyr?.Fyrto, "", abortConstants.controllerConfig)
    if (ledgerSelect) {
      getAllData(branchId, ledgerSelect, abortConstants.controllerConfig)
    }
  }
  //for search
  const handleSearchClick = (branchId, ledgerData) => {
    ledgerData?setIssearch(true):setIssearch(false)    
    console.log(branchId, ledgerData);
    toast.dismiss()
    setLedgerBook(null)
    setIsLoading({})
    if(ledgerData){
      getAllData(branchId, ledgerData, abortConstants.controllerConfig)
    }
  }
  //for loading circular progress
  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`] ||
      isLoading.branch_response || isLoading.branch_request || isLoading.branch_timeout) {
      return true
    }
    else {
      return false
    }
  }

  // to disable/enable search button
  const isLoadingTrue = () => {
    const state = (Object.keys(isLoading).length === 0) && (!Ledgertype)
    return state
  }

  const exportToExcel = () => {
    // Extracting specific columns from the ledgerBook array
    const dataToExport = ledgerBook.map(({ VoucherDate, CustomerName, VoucherNo, VoucherType, Debit, Credit }) => ({
      VoucherDate,
      CustomerName,
      VoucherNo,
      VoucherType,
      Debit,
      Credit
    }));

    // Define an array containing the opening balance, total transactions, and closing balance rows
    const balanceRows = [
      {
        VoucherDate: "",
        CustomerName: "",
        VoucherNo: "",
        VoucherType: "Opening Balance",
        Debit: openingBalanceSign === 'Dr' ? openingBalance : "",
        Credit: openingBalanceSign === 'Cr' ? openingBalance : ""
      },
      {
        VoucherDate: "",
        CustomerName: "",
        VoucherNo: "",
        VoucherType: "Transactions Total",
        Debit: totalDebit,
        Credit: totalCredit
      },
      {
        VoucherDate: "",
        CustomerName: "",
        VoucherNo: "",
        VoucherType: "Closing Balance",
        Debit: closingBalanceSign === 'Dr' ? closingBalance : "",
        Credit: closingBalanceSign === 'Cr' ? closingBalance : ""
      }
    ];

    // Append the balanceRows array to the dataToExport array
    dataToExport.push(...balanceRows);

    // Creating worksheet
    const ws = XLSX.utils.json_to_sheet(dataToExport);

    // Creating workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Saving workbook as an Excel file
    XLSX.writeFile(wb, 'exported_data.xlsx');
  };




  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container spacing={2}>
          {/* header */}
          {branchList && (
            <LedgerHeader
              branchName={branchName}
              frDate={frDate}
              tDate={tDate}
              isLoadingTrue={isLoadingTrue}
              branchSelect={branchSelect}
              setbranchSelect={setbranchSelect}
              branchList={branchList}
              handleSearchClick={handleSearchLedger}
              setbranchSelectSearch={setbranchSelectSearch}
              setBranchName={setBranchName}
              fyrList={fyrList}
              fyrSelect={fyrSelect}
              setFyrSelect={setFyrSelect}
              setSelectedFyr={setSelectedFyr}
              selectedFyr={selectedFyr}
              frmDate={frmDate}
              toDate={toDate}
              setFrmdate={setFrmdate}
              setTodate={setTodate}
              setOptionSelect={setLedgerSelect}
              exportExcel={exportToExcel}
              companyName={company.companyname}
              title="Ledger Register"
            />
          )}

          <Grid item xs={12}  >
            <Box >
              <Grid xs={12} container display="flex" justify="space-between">
                <Grid item style={{ justifyContent: 'center', marginLeft: '450px' }} >
                  <LedgerExtraFilter
                    isLoadingTrue={isLoadingTrue}
                    branchSelect={branchSelect}
                    optionTitle="Ledger Name"
                    optionList={Ledgertype}
                    optionSelect={ledgerSelect}
                    setOptionSelect={setLedgerSelect}
                    optionKey="LedgerName"
                    handleSearch={handleSearchClick}
                  />
                </Grid>



                <Grid item style={{ marginLeft: 'auto', marginTop: '20px' }}>
                  {/* Consolidated/Detailed Switch */}
                  <Grid item display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" style={{ marginRight: '4px', color: '#1F77B4' }}>
                      Consolidated
                    </Typography> &nbsp; &nbsp;
                    <AntSwitch
                      checked={isDetailedView}
                      onChange={() => setIsDetailedView(!isDetailedView)}

                    /> &nbsp; &nbsp;
                    <Typography variant="body2" style={{ marginLeft: '4px' }}>
                      Detailed
                    </Typography>
                  </Grid>
                </Grid>

              </Grid>



              {/* from here renderLoading */}
              {ledgerBook &&
                <Box  >
                  <>
                    {ledgerBook.length !== 0 ? (
                      <TableContainer component={Paper} sx={{ paddingLeft: '20px', paddingRight: '20px', maxHeight: 500, marginTop: '20px', overflow: 'auto' }}>
                        <Table size='small' stickyHeader style={{ marginTop: '30px', width: '100%' }} aria-label="spanning table">

                          <TableHead >
                            <TableRow>
                              <TableCell style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Date</TableCell>
                              <TableCell align='left' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Account Name</TableCell>

                              <TableCell align='center' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Ref No</TableCell>
                              <TableCell align='left' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Voucher Type</TableCell>
                              <TableCell align='right' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Debit</TableCell>
                              <TableCell align='right' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderBottom: 'none' }}>Credit</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {ledgerBook.map((row, index) => (
                              <TableRow key={index}   >
                                <TableCell style={{ borderRight: '1px solid #ddd' }} >{row.VoucherDate}</TableCell>
                                <TableCell align="left" style={{ borderRight: '1px solid #ddd' }}>{row.CustomerName} {isDetailedView && <div style={{ fontSize: '12px', color: 'gray' }}>
                                  {row.Narration}</div>}
                                </TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid #ddd' }}>{row.VoucherNo}</TableCell>
                                <TableCell align="left" style={{ borderRight: '1px solid #ddd' }}>{row.VoucherType}</TableCell>

                                <TableCell align="right" style={{ borderRight: '1px solid #ddd' }}>{row.Debit}</TableCell>
                                <TableCell align="right" >{row.Credit}</TableCell>
                              </TableRow>
                            ))}

                            {/* Additional rows for Opening Balance, Total Transactions, and Closing Balance */}

                            {/* opening balance */}
                            <TableRow  >
                              <TableCell rowSpan={3} />
                              <TableCell style={{ borderBottom: 'none' }} />
                              <TableCell style={{ borderBottom: 'none' }} />

                              <TableCell colSpan={1} style={{ color: '#489C8F', fontWeight: 'bold' }}>Opening Balance :</TableCell>
                              {openingBalanceSign === 'Dr' ? (
                                <>
                                  <TableCell align="right" style={{ fontWeight: 'bold' }}>{openingBalance}</TableCell>
                                  <TableCell align="right" ></TableCell> {/* Empty cell for Credit */}
                                </>
                              ) : (
                                <>
                                  <TableCell align="right"></TableCell> {/* Empty cell for Debit */}
                                  <TableCell align="right" style={{ fontWeight: 'bold' }}>{openingBalance}</TableCell>
                                </>
                              )}
                            </TableRow>


                            <TableRow  >
                              <TableCell style={{ borderBottom: 'none' }} />
                              <TableCell style={{ borderBottom: 'none' }} />
                              <TableCell colSpan={1} style={{ color: '#489C8F', fontWeight: 'bold' }}>  Transactions Total :</TableCell>
                              <TableCell align="right" style={{ fontWeight: 'bold' }}>{totalDebit}</TableCell>
                              <TableCell align="right" style={{ fontWeight: 'bold' }}>{totalCredit}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell></TableCell>
                              <TableCell></TableCell>

                              <TableCell colSpan={1} style={{ color: '#489C8F', fontWeight: 'bold' }}>Closing Balance :</TableCell>
                              {closingBalanceSign === 'Dr' ? (
                                <>
                                  <TableCell align="right" style={{ fontWeight: 'bold' }}>{closingBalance}</TableCell>
                                  <TableCell align="right"></TableCell> {/* Empty cell for Credit */}
                                </>
                              ) : (
                                <>
                                  <TableCell align="right"></TableCell> {/* Empty cell for Debit */}
                                  <TableCell align="right" style={{ fontWeight: 'bold' }}>{closingBalance}</TableCell>
                                </>
                              )}
                            </TableRow>

                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <h4 style={{ textAlign: "center" }}>No data</h4>
                    )}
                  </>

                </Box>
              }
              {isSearch &&
                <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                  <CircularProgress />
                </Box>
              }
              {/* renderLoading("ledgerbook") */}
              {/* upto here */}





            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default Ledger


