import axiosInstance from "./axiosInstance";

let api = axiosInstance()

export const getInventoryStock = async(branchId, frm, to,group, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 60000, // Set the timeout for this specific request
      };
    return  await api.get(`/MISReports/StockRegister?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&ProductGroup=${group}`, config)
  }

  export const getInventoryItems = async(branchId, frm, to, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 60000, // Set the timeout for this specific request
      };
    return  await api.get(`/Products?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
  }

  export const getInventoryGraph = async(branchId, frm, to, isProduct, products, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 60000, // Set the timeout for this specific request
      };
    return  await api.get(`/MISReports/InventorySalesandPurchase?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Isgroup=${isProduct}&Id=${products}`, config)
  }

  export const getInventoryTable = async(branchId, frm, to, isProduct, products, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 60000, // Set the timeout for this specific request
      };
    return  await api.get(`/MISReports/InventoryMovement?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Isgroup=${isProduct}&Id=${products}`, config)
  }

  export const getInventoryHighestValue = async(branchId, frm, to, products, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 60000, // Set the timeout for this specific request
      };
    return  await api.get(`/MISReports/HighestValueLastTransaction?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Id=${products}`, config)
  }

  export const getInventoryAvgMoving = async(branchId, frm, to, products, abortToken)=>{
    const config = {
        ...abortToken,
        timeout: 60000, // Set the timeout for this specific request
      };
    return  await api.get(`/MISReports/AvgMovingStock?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Id=${products}`, config)
  }