import React from 'react'
import {
    Box, Grid, Tooltip, IconButton, Typography, Menu, Select, FormControl, MenuItem,
    Button, OutlinedInput, Checkbox, ListItemText
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DateCalander from './DateCalander';
import { useEffect } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const filter = createFilterOptions();


function JournalFilter(
    { branchSelect,
        setBranchSelect,
        branchList,
        frmDate,
        toDate,
        setFrmdate,  
        setTodate,
        handleSearch,
        isLoadingTrue,
        setbranchSelectSearch,
        setBranchName,
        optionTitle,
        optionSelect,
        setOptionSelect,
        optionList,
        optionKey
     }) {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
 
    const search = () => {
        handleCloseMenu()
        // const fyear = fyrList.find(item => item.Fyrname === fyrSelect);
        // setAsOnDate(fyear.AsonDate)
        // setFyrName(fyear.Fyrname)

        const brName = branchList?.filter(item => branchSelect.includes(item.branchid))
            .map(item => item.branchname);
        setBranchName(brName)
        setbranchSelectSearch(branchSelect)
        handleSearch(branchSelect.join(','), frmDate, toDate, optionSelect)
        // handleSearch(branchSelect.join(','), frmDate, toDate   )

    }


    return (
        <>
            <Tooltip title='Search'>
                <IconButton size='small' onClick={handleMenu} className='filter-style'><FilterAltIcon className='filter-style' /></IconButton>
            </Tooltip>
            {/* filter box start*/}
            <Menu
                id="search"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <Box className='search box' >
                    <Grid container spacing={1} >
                        <Grid item xs={12} ><Typography className='text-color'> {t('home.branch')} </Typography></Grid>

                        <Grid item xs={12} >
                            <FormControl required sx={{ width: '100%' }}>
                                <Select
                                    size="small"
                                    fullWidth
                                    id="demo-simple-select"
                                    name="branchid"
                                    value={branchSelect}
                                    onChange={(e) => { setBranchSelect(e.target.value) }}
                                >
                                    {branchList?.map((item) => (
                                        <MenuItem
                                            key={item.branchid}
                                            name={item.branchname}
                                            value={item.branchid}
                                        >
                                            {item.branchname}
                                            {/* <ListItemText primary={item.branchname} /> */}
                                        </MenuItem>
                                    ))}

                                </Select>

                            </FormControl>
                        </Grid>


                        {/* <Grid item xs={12} ><Typography className='text-color'>{t('home.fyr')}</Typography></Grid> */}
                        <Grid item xs={12}>
                            <Grid item xs={12} ><Typography className='text-color'>From</Typography></Grid>
                            <Grid item xs={12}>
                                <DateCalander value={dayjs(frmDate)} setValue={setFrmdate} />
                            </Grid>
                            <Grid item xs={12} ><Typography className='text-color'>To</Typography></Grid>
                            <Grid item xs={12}>
                                <DateCalander value={dayjs(toDate)} setValue={setTodate} />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} ><Typography className='text-color'>{optionTitle}</Typography></Grid>
                        <Grid item xs={12}>

<FormControl required sx={{ width: '100%' }}>
    <Select
        size='small'
        id="demo-simple-select-filled"
        value={optionSelect}  
        onChange={(e) => { setOptionSelect(e.target.value) }}
    >
        {optionList ? optionList.map((item, index) => (<MenuItem key={index} name={item[`${optionKey}`]} value={item[`${optionKey}`]} >{item[`${optionKey}`]}</MenuItem>))
            : (<p style={{ marginLeft: '5px' }}>No data to display</p>)
        }
    </Select>

</FormControl>
</Grid>


                        {/* <Grid item xs={12} ><Typography className='text-color'>{optionTitle}</Typography></Grid>


                         {location.pathname === '/Ledger' ? (
                             <Grid item xs={12}>
                                <Autocomplete
                                    id="option-select"
                                    options={optionList || []}
                                    value={optionList.find((item) => item[optionKey] === optionSelect) || null}
                                    onChange={(event, newValue) => {
                                        setOptionSelect(newValue ? newValue[optionKey] : '');
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;
                                        const isExisting = options.some((option) => inputValue === option[optionKey]);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push({
                                                [optionKey]: inputValue,
                                                inputValue,
                                            });
                                        }

                                        return filtered;
                                    }}
                                    getOptionLabel={(option) => {
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option[optionKey];
                                    }}
                                    renderOption={(props, option) => <li {...props}>{option[optionKey]}</li>}
                                    freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" label={optionTitle} size="small" />
                                    )}
                                />
                            </Grid>
                        ) : (
 
                             <Grid item xs={12}>

                                <FormControl required sx={{ width: '100%' }}>
                                    <Select
                                        size='small'
                                        id="demo-simple-select-filled"
                                        value={optionSelect}  
                                        onChange={(e) => { setOptionSelect(e.target.value) }}
                                    >
                                        {optionList ? optionList.map((item, index) => (<MenuItem key={index} name={item[`${optionKey}`]} value={item[`${optionKey}`]} >{item[`${optionKey}`]}</MenuItem>))
                                            : (<p style={{ marginLeft: '5px' }}>No data to display</p>)
                                        }
                                    </Select>

                                </FormControl>
                            </Grid>

                        )} */}


                        <Grid item xs={12}  >
                            {!isLoadingTrue() && <Button fullWidth className="btn btn-search" onClick={() => search()}>
                                {t('form.search')}</Button>}
                            {isLoadingTrue() && <Button fullWidth className="btn btn-filter-load">
                                <span className="btn-text">{t('form.loading')}</span><div className="spinner"></div>
                            </Button>}
                        </Grid>
                    </Grid>
                </Box>
            </Menu>
            {/* filter box end */}
        </>
    )
}

export default JournalFilter