import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
// import OfflineImage from './Assets/Images/offline img.png'

const InternetStatusChecker = ({ children }) => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    useEffect(() => {
        console.log("inside useeffect status");
        const handleOnline = () => {
            setIsOnline(true);
            window.location.reload();
        };

        const handleOffline = () => {
            setIsOnline(false);
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    if (!isOnline) {
        return (
            <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
                <Grid container spacing={1}>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4} display='flex' justifyContent='center' >
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <img src={process.env.PUBLIC_URL + '/assets/images/offline.png'} alt='offline' className='image common' />
                            <Typography variant='h5'>You are currently offline.</Typography>
                            <Typography>We could not load the content. Check your</Typography>
                            <Typography>internet connection and try again.</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
            </Box>
        );
    }

    return children;
};

export default InternetStatusChecker;
