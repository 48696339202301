import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Card, Divider, Typography, CircularProgress, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../components/styles/theme';
import dayjs from 'dayjs'
import { useSelector } from 'react-redux';
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from '../../api/HandleApiErrors';
import { useTranslation } from 'react-i18next';
import { getPermittedBranch } from '../../api/commonApi';
import { changeFormat, dateConvert, changeCurrencyFormat, getMonthRange } from '../../HelperFile';
import TabularHeader from '../../components/TabularHeader';
import * as transactionreportApi from '../../api/transactionreportApi';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AntSwitch from '../../components/styles/AntSwitch';
import { getFyrByCompany } from '../../api/fyrApi';

function Payment() {
  const { t } = useTranslation()
  const isFyr = useRef(false)
  const isBranchList = useRef(false)
  const initialLoading = useRef(false)
  const abortConstants = useSignalAbort()
  const token = useSelector(state => state.token)
  const user = useSelector(state => state.user)
  const selectedDetails = useSelector(state => state.selectedParameters)
  const company = user?.company
  const companyId = user?.companyid
  const [branchList, setBranchList] = useState()
  const [branchSelect, setbranchSelect] = useState()
  const [branchSelectSearch, setbranchSelectSearch] = useState()
  const [fyrList, setFyrList] = useState()
  const [fyrSelect, setFyrSelect] = useState('');
  const [selectedFyr, setSelectedFyr] = useState()
  const [toDate, setTodate] = useState()
  const [frmDate, setFrmdate] = useState()
  const [frDate, setFrDate] = useState()
  const [tDate, setTDate] = useState()
  const [branchName, setBranchName] = useState()
  const [isLoading, setIsLoading] = useState({})
  const [voucherSelect, setVoucherSelect] = useState('');
  const [Vouchertype, setVouchertype] = useState()
  const [payment, setPayment] = useState()
  const [isDetailedView, setIsDetailedView] = useState(false);

  //display message if default branch is not set to the user
  useEffect(() => {
    if (user && !user?.branchid) {
      toast.error("A default branch has not been assigned to you. Please contact your administrator", {
        autoClose: 3000, // Close the toast after 5 seconds
      });
    }
  }, [user])

  useEffect(() => {
    const fetchData = async () => {
      if (!token || !companyId || !user) return;

      if (!isFyr.current) {
        console.log("Fetching financial year data...");
        await getFyrByCriteria(companyId, abortConstants.controllerConfig);
        isFyr.current = true;
      }

      if (!isBranchList.current) {
        console.log("Fetching branch data...");
        await getBranch(user.userid, abortConstants.controllerConfig);
        isBranchList.current = true;
      }

      if (branchSelect && selectedFyr) {
        console.log("Fetching voucher type...");
        const frm = dateConvert(dayjs(selectedFyr.Fyrfrom));
        const to = dateConvert(dayjs(selectedFyr.Fyrto));
        await getVoucherType(branchSelect, frm, to, 'Payment', abortConstants.controllerConfig);
      }

      if (branchSelect && frmDate && toDate && !initialLoading.current) {
        console.log("Fetching all data...");
        await getAllData(branchSelect, frmDate, toDate, '', 'Payment', abortConstants.controllerConfig);
        initialLoading.current = true;
      }
    };

    fetchData();

    return () => {
      console.log("Cleanup");
      abortConstants.controller.abort();
      toast.dismiss();
    };
  }, [token, companyId, user, branchSelect, selectedFyr, frmDate, toDate]);

  const getFyrByCriteria = async (id, abortToken) => {
    try {
      const result = await getFyrByCompany(id, abortToken);
      setFyrList(result?.data?.FinancialYear);
      isFyr.current = true;

      const fYear = selectedDetails?.selectedFyr
        ? result?.data?.FinancialYear?.find(item => item.Fyrname === selectedDetails?.selectedFyr)
        : result?.data?.FinancialYear?.find(item => item.Iscurrent === 1);

      const fromDate = selectedDetails?.selectedFromDate || getMonthRange(fYear?.Fyrfrom, fYear?.Fyrto, dayjs().month())[0];
      const toDate = selectedDetails?.selectedToDate || getMonthRange(fYear?.Fyrfrom, fYear?.Fyrto, dayjs().month())[1];

      setSelectedFyr(fYear);
      setFyrSelect(fYear?.Fyrname);
      setFrmdate(dayjs(fromDate));
      setTodate(dayjs(toDate));
    } catch (error) {
      handleApiErrors(error, 'Financial Year', 'fyr_response', 'fyr_timeout', 'fyr_request', setIsLoading);
    }
  };

  const getBranch = async (userId, abortToken) => {
    try {
      const result = await getPermittedBranch(userId, abortToken);
      isBranchList.current = true;
      if (result?.data?.branch.length !== 0) {
        setBranchList(result?.data?.branch);
        const selectedBranchId = selectedDetails?.selectedBranch || (user?.branchid || result?.data?.branch[0]?.branchid);
        const selectedBranchName = result.data?.branch
          .filter(item => item.branchid === selectedBranchId)
          .map(item => item.branchname);

        setbranchSelect(selectedBranchId);
        setBranchName(selectedBranchName);
      } else {
        toast.error(result?.data?.message, {
          autoClose: 3000,
        });
      }
    } catch (error) {
      handleApiErrors(error, 'Branch', 'branch_response', 'branch_timeout', 'branch_request', setIsLoading);
    }
  };



  const getAllData = (branchId, frm, to, voucher, transaction, abortToken) => {
    setFrDate(changeFormat(dayjs(frm), user?.company?.DateFormatname))//change the format of from date
    setTDate(changeFormat(dayjs(to), user?.company?.DateFormatname))//change the format of to date
    let fDate = dateConvert(frmDate)
    let tDate = dateConvert(toDate)
    getPayment(branchId, fDate, tDate, voucher, transaction, abortToken)

  }


  //voucher List
  const getVoucherType = async (branchId, frm, to, type, abortToken) => {
    console.log(branchId, frm, to, type);
    try {
      const result = await transactionreportApi.getVoucherType(branchId, frm, to, type, abortToken)
      console.log("voucher", result);
      setVouchertype(result?.data?.Vouchers)
      console.log("Vocuchers", result?.data?.Vouchers);
      // setVoucherSelect(result?.data?.Vouchers[0]?.VoucherTypeName)
    }
    catch (error) {
      handleApiErrors(error, 'VoucherType', 'voucher_response', 'voucher_timeout', 'voucher_request', setIsLoading)
    }
  }


  //cashbook  
  const getPayment = async (branchId, frm, to, voucher, transaction, abortToken) => {
    console.log(branchId, frm, to, voucher);
    try {
      //should be revised if error comes
      const result = await transactionreportApi.getTransaction(branchId, frm, to, voucher, transaction, abortToken)
      console.log("Payment", result);


      // Format the voucher dates before setting the payment state
      const formattedPaymentData = result?.data?.Transaction.map(row => ({
        ...row,
        VoucherDate: row.VoucherDate? changeFormat(dayjs(row.VoucherDate), user?.company?.DateFormatname) :''
      }));

      setPayment(formattedPaymentData);
    }
    catch (error) {
      handleApiErrors(error, 'Payment', 'payment_response', 'payment_timeout', 'payment_request', setIsLoading)
    }
  }


  //for search
  const handleSearchClick = (branchId, frm, to, voucherData) => {
    console.log(branchId, frm, to, voucherData);
    toast.dismiss()
    setPayment(null)
    setIsLoading({})
    getAllData(branchId, frm, to, voucherData, "Payment", abortConstants.controllerConfig)
  }

  const changeLedger =()=>{

  }
  
  //for loading circular progress
  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`] ||
      isLoading.branch_response || isLoading.branch_request || isLoading.branch_timeout) {
      return true
    }
    else {
      return false
    }
  }


  // to disable/enable search button

  const isLoadingTrue = () => {
    const state = (Object.keys(isLoading).length === 0) && (!payment)
    return state
  };


  const exportToExcel = () => {
    // Extracting only the required columns
    const paymentDataForExport = payment.map(row => ({
      Date: row.VoucherDate,
      'Account Name': row.CustomerName,
      'Ref No': row.VoucherNo,
      VoucherType: row.VoucherType,
      'Voucher No': row.VoucherNo,
      Debit: changeCurrencyFormat(row.NetAmount, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')
    }));

    // Calculate the debit total
    const debitTotal = payment.reduce((total, row) => total + row.NetAmount, 0);
    const debitTotalRow = {
      Date: '',
      'Account Name': '',
      'Ref No': '',
      VoucherType: '',
      'Voucher No': 'Debit total',
      Debit: changeCurrencyFormat(debitTotal, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')
    };

    // Creating the Excel sheet
    const ws = XLSX.utils.json_to_sheet([...paymentDataForExport, debitTotalRow]);  // Append debit total row
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Payment.xlsx');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container spacing={1}>
          {/* header */}
          {Vouchertype && (
            <TabularHeader
              branchName={branchName}
              frDate={frDate}
              tDate={tDate}
              isLoadingTrue={isLoadingTrue}
              branchSelect={branchSelect}
              setbranchSelect={setbranchSelect}
              branchList={branchList}
              handleSearchClick={handleSearchClick}
              setbranchSelectSearch={setbranchSelectSearch}
              setBranchName={setBranchName}
              frmDate={frmDate}
              toDate={toDate}
              setFrmdate={setFrmdate}
              setTodate={setTodate}
              fyrList={fyrList}
              fyrSelect={fyrSelect}
              setFyrSelect={setFyrSelect}
              selectedFyr={selectedFyr}
              setSelectedFyr={setSelectedFyr}
              optionTitle="Voucher Type"
              optionList={Vouchertype}
              optionSelect={voucherSelect}
              setOptionSelect={setVoucherSelect}
              optionKey="VoucherTypeName"
              exportExcel={exportToExcel}
              companyName={company.companyname}
              title="Payment"
            />
            // <ReportsFilter isLoadingTrue={isLoadingTrue}/>
          )}

          <Grid item xs={12}  >
            <Box >

              <Grid container alignItems="center" justify="space-between">

                {/* <Grid item xs={6}> 
     <TabExtraFilter
      isLoadingTrue={isLoadingTrue}
      branchSelect={branchSelect}
      frmDate={frmDate}
      toDate={toDate}
       optionTitle="Voucher Type"
        optionList={Vouchertype}
       optionSelect={voucherSelect}
       setOptionSelect={setVoucherSelect}
        optionKey="VoucherTypeName"
      handleSearch={handleSearchClick}
     />
  </Grid> */}

                <Grid item xs={12} container justifyContent="flex-end">
                  {/* Consolidated/Detailed Switch */}
                  <Grid item display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" style={{ marginRight: '4px', color: '#1F77B4' }}>
                      Consolidated
                    </Typography> &nbsp; &nbsp;
                    <AntSwitch
                      checked={isDetailedView}
                      onChange={() => setIsDetailedView(!isDetailedView)}

                    /> &nbsp; &nbsp;
                    <Typography variant="body2" style={{ marginLeft: '4px' }}>
                      Detailed
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>







              {/* from here renderLoading */}
              {payment ? (
                <Box  >
                  <div >
                    <>
                      {payment.length !== 0 ? (
                        <TableContainer component={Paper} sx={{ paddingLeft: '20px', paddingRight: '20px', maxHeight: 500, overflow: 'auto', marginTop: '15px' }}>
                          <Table style={{ marginTop: '30px', width: '100%' }} aria-label="spanning table">

                            <TableHead >
                              <TableRow >

                                <TableCell style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Date</TableCell>
                                <TableCell align='left' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Account Name</TableCell>

                                <TableCell align='center' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Ref No</TableCell>
                                <TableCell align='left' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>VoucherType</TableCell>
                                <TableCell align='center' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>VoucherNo</TableCell>

                                <TableCell align='right' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderBottom: 'none' }}>Debit</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {payment.map((row,index) => (
                                <TableRow key={index}>

                                  <TableCell style={{ lineHeight: '0.2', borderRight: '1px solid #ddd' }}>{row.VoucherDate}</TableCell>
                                  <TableCell align="left" style={{ lineHeight: isDetailedView ? '1.2' : '0.2', borderRight: '1px solid #ddd' }}>{row.CustomerName} {isDetailedView && <div style={{ fontSize: '12px', color: 'gray' }}>
                                    {row.Narration}</div>}
                                  </TableCell>
                                  <TableCell align="center" style={{ lineHeight: '0.2', borderRight: '1px solid #ddd' }}>{row.VoucherNo}</TableCell>
                                  <TableCell align="left" style={{ lineHeight: '0.2', borderRight: '1px solid #ddd' }}>{row.VoucherType}</TableCell>
                                  <TableCell align="center" style={{ lineHeight: '0.2', borderRight: '1px solid #ddd' }}>{row.VoucherNo}</TableCell>

                                  <TableCell align="right" style={{ lineHeight: '0.2' }}>
                                    {changeCurrencyFormat(row.NetAmount, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')}

                                  </TableCell>
                                </TableRow>
                              ))}


                              <TableRow >
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell colSpan={1} style={{ color: '#489C8F', fontWeight: 'bold' }}>Debit Total: </TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                  {/* Calculate and display the sum of NetAmount */}
                                  {changeCurrencyFormat(payment.reduce((total, row) => total + row.NetAmount, 0), company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}
                                </TableCell>
                              </TableRow>


                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <h4 style={{ textAlign: "center" }}>No data</h4>
                      )}
                    </>
                  </div>

                </Box>
              ) : (
                <>
                  {renderLoading("payment") ? (
                    <h5 style={{ textAlign: "center" }}>No data</h5>
                  ) : (
                    <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </>
              )}

              {/* upto here */}




            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default Payment


