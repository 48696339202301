// import React, { useEffect, useState } from 'react'
// import rcorLogo from '../images/logo_dark.png'
// import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
// import { Box, Grid, Typography } from '@mui/material';
// import axios from 'axios';
// import { getUserMenu, onBoarding } from '../api/userApi';
// import { setMenu } from '../redux/slices/authSlice';

// const API_URL = process.env.REACT_APP_BASE_URL

// function MainHome() {
//     const token = useSelector(state => state?.token)
//     const user = useSelector(state => state.user)
//     const localToken = localStorage.getItem("token")
//     // const menuArray = useSelector(state => state?.menu)
//     const navigate = useNavigate()
//     const dispatch = useDispatch()
//     const fullURL = window.location.href;
//     const parsedURL = new URL(fullURL)
//     const hostName = parsedURL.hostname + (parsedURL.port ? `:${parsedURL.port}` : '')

//     const [isValidDomain, setIsValidDomain] = useState(false)
//     const [isCompleted, setIsCompleted] = useState(false)

//     useEffect(() => {
//         const getDomain = async () => {
//             try {
//                 const response = await axios.post(`${API_URL}/auth/IsSubDomainValid`, {}, {
//                     headers: {
//                         'Content-Type': 'application/json',
//                         'Accept': 'application/json',
//                         'Authority': hostName,
//                         withCredentials: true,
//                     },
//                     // withCredentials: false,
//                     mode: 'cors',
//                 })
//                 console.log(response);
//                 if (response.data.code === '101') {
//                     // navigate('/invalid_url')
//                     window.location.href = `https://rcorintelliger.co/InvalidURl?url=${hostName}`;
//                 }
//                 else {
//                     setIsValidDomain(true)
//                     getOnboarding()
//                 }
//             }
//             catch (error) {
//                 alert(error.message)
//                 console.log(error);
//             }
//         }
//         getDomain()
//     }, [hostName])

//     useEffect(() => {
//         if (isValidDomain) {
//             if(localToken){
//                 onBoarding()
//             }
//             if (localToken && isCompleted) {
//                 getMenu(user?.usertypeid)
//             }else {
//                 navigate('/loginpage')
//             }
//         }
//     }, [localToken, user, isValidDomain, isCompleted])

//     // API call to get onboarding details
//     const getOnboarding = async () => {
//         try {
//             const result = await onBoarding(abortConstants.controllerConfig)
//             if(response?.data?.OnboardingProcess && response?.data?.OnboardingProcess?.length !==0){
//                 if(result?.data?.OnboardingProcess[0].Id === 4){
//                     setIsCompleted(true)
//                 }else {
//                     window.location.href = '/OnBoarding'
//                 }
//             }
//         }
//         catch (error) {
//             alert(error.message);
//             console.log(error);
//         }
//     }

//     const getMenu = async (userTypeId) => {
//         console.log("inside menu");
//         try {
//             const response = await getUserMenu(userTypeId)
//             console.log("menu", response);
//             if (response?.data?.SubMenu && response?.data?.SubMenu?.length !== 0) {
//                 dispatch(setMenu({ menu: response.data.SubMenu }))
//                 const userHome = response?.data?.SubMenu?.find(item => item.isDashboar === 1 && item.subMenu_order === 1)?.subMenu_route
//                 if (userHome) {
//                     setTimeout(() => {
//                         window.location.href = `/${userHome}`
//                         // navigate(`/${userHome}`)
//                     }, 4000)
//                 }
//                 else {
//                     navigate('/loginpage')
//                 }
//                 // setMenuArray(response.data.SubMenu)
//             }
//         }
//         catch (error) {
//             alert(error.message);
//             navigate('/loginpage')
//         }
//     }

//     return (
//         <>{isValidDomain ?
//             <Box>
//                 <Grid container spacing={1}>
//                     <Grid item xs={4}></Grid>
//                     <Grid item xs={4} display='flex' justifyContent='center'>
//                         <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt='150px'>
//                             <img src={process.env.PUBLIC_URL + `/assets/images/rcorLogo.png`} alt='logo' height={45} />
//                             <Typography mt={5} mb={1} className='typography heading-h1'>Welcome, {user?.firstname}</Typography>
//                             <Typography mb='80px' className='typography heading-h5'>Please wait. We are loading your data</Typography>
//                             <img className="flip-animation" src={process.env.PUBLIC_URL + '/assets/images/rcorlogo_flip.png'} width={114} height={100} />
//                         </Box>
//                     </Grid>
//                     <Grid item xs={4}></Grid>
//                 </Grid>
//             </Box> :
//             <></>
//         }</>
//     )
// }

// export default MainHome

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'
import axios from 'axios'
import { getUserMenu, onBoarding } from '../api/userApi'
import { setMenu } from '../redux/slices/authSlice'

const API_URL = process.env.REACT_APP_BASE_URL

function MainHome() {
    const token = useSelector(state => state?.token)
    const user = useSelector(state => state.user)
    const localToken = localStorage.getItem("token")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const fullURL = window.location.href
    const parsedURL = new URL(fullURL)
    const hostName = parsedURL.hostname + (parsedURL.port ? `:${parsedURL.port}` : '')
    // const hostName = 'demo.rcorintelliger.co'

    const [isValidDomain, setIsValidDomain] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)
    console.log(API_URL, hostName);

    useEffect(() => {
        const getDomain = async () => {
            console.log(hostName);
            
            try {
                const response = await axios.post(`${API_URL}/auth/IsSubDomainValid`, {}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authority': hostName,
                        withCredentials: true,
                    },
                    mode: 'cors',
                })
                console.log(response)
                if (response.data.code === '101') {
                    window.location.href = `https://rcorintelliger.co/InvalidURl?url=${hostName}`
                } else {
                    setIsValidDomain(true)
                }
            } catch (error) {
                // alert(error.message)
                console.log(error)
            }
        }
        if(API_URL && hostName){
            console.log(API_URL, hostName);
            getDomain()
        }
    }, [API_URL, hostName])

    useEffect(() => {
        const initialize = async () => {
            if (isValidDomain) {
                if (localToken) {
                    await checkOnboarding()
                } else {
                    window.location.href = '/loginpage'
                }
            }
        }
        initialize()
    }, [isValidDomain, localToken])

    const checkOnboarding = async () => {
        try {
            const result = await onBoarding()
            if (result?.data?.OnboardingProcess && result?.data?.OnboardingProcess.length !== 0) {
                if (result.data.OnboardingProcess[0].Id === 4) {
                    setIsCompleted(true)
                    getMenu(user?.usertypeid)
                } else {
                    window.location.href = '/OnBoarding'
                }
            }
        } catch (error) {
            alert(error.message)
            window.location.href = '/loginpage'
            console.log(error)
        }
    }

    const getMenu = async (userTypeId) => {
        try {
            const response = await getUserMenu(userTypeId)
            console.log("menu", response)
            if (response?.data?.SubMenu && response?.data?.SubMenu.length !== 0) {
                dispatch(setMenu({ menu: response.data.SubMenu }))
                const userHome = response.data.SubMenu.find(item => item.isDashboar === 1 && item.subMenu_order === 1)?.subMenu_route
                if (userHome) {
                    setTimeout(() => {
                        window.location.href = `/${userHome}`
                    }, 4000)
                } else {
                    window.location.href = `/${response.data.SubMenu[0]?.subMenu_route}`
                }
            }
            else {
                // window.location.href = '/loginpage'
            }
        } catch (error) {
            alert(error.message)
            window.location.href = '/loginpage'
        }
    }
    const handleHardReload = () => {
        window.location.reload(true);
      };

    return (
        <>
            {isValidDomain ?
                <Box>
                    <Grid container spacing={1}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4} display='flex' justifyContent='center'>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt='150px'>
                                <img src={process.env.PUBLIC_URL + `/assets/images/rcorLogo.png`} alt='logo' height={45} />
                                <Typography mt={5} mb={1} className='typography heading-h1'>Welcome, {user?.firstname}</Typography>
                                <Typography mb='80px' className='typography heading-h5'>Please wait. We are loading your data</Typography>
                                <img className="flip-animation" src={process.env.PUBLIC_URL + '/assets/images/rcorlogo_flip.png'} width={114} height={100} />
                            </Box>
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                </Box> 
                :
                <></>
            //      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" mt='150px'>
            //     <Typography mb={2} className='typography heading-h5'>Unable to load data</Typography>
            //     <Button variant="contained" color="primary" onClick={handleHardReload}>
            //       Reload Page
            //     </Button>
            //   </Box>
            }
        </>
    )
}

export default MainHome
