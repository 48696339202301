import React from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

function PageNotFound() {
  const navigate = useNavigate()
  return (
    <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
      <Grid container spacing={1}>
        <Grid item xs={4}></Grid>
        <Grid item xs={4} display='flex' justifyContent='center' >
          <Box display="flex" flexDirection="column" alignItems="center">
            <img src={process.env.PUBLIC_URL + '/assets/images/404 png.png'} alt='not found' className='image common'/>
            <Typography variant='h5'>Sorry, the page not found</Typography>
            <Typography>The link you followed probably broken </Typography>
            <Typography>or the page has been removed.</Typography>
            <Button className="btn btn-search" onClick={() => navigate('/home')}>Back to Home</Button>
          </Box>
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>
    </Box>
  )
}

export default PageNotFound