import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Grid, Divider, FormControl,
  TextField, Typography, ListItemText, Checkbox, MenuItem,
  Paper,
  Popper,
} from '@mui/material';
import * as BranchApi from '../../api/branchApi'
import i18n from '../../i18n';
import AntSwitch from '../styles/AntSwitch';
import { validateForm } from './handleValidation';
import Autocomplete from '@mui/material/Autocomplete';
import { onBoarding } from '../../api/userApi';

function BranchForm({ isEdit, branchId, onBranchCreated, onBranchUpdated, onCancel, onBoarding }) {
  const { t } = useTranslation();
  const token = useSelector(state => state.token)
  const user = useSelector(state => state.user)
  const branchValues = {
    BranchName: "",
    BranchAddress: "",
    EmailId: "",
    City: "",
    Website: "",
    ZipCode: "",
    Phone: "",
    BranchCode: "",
    CompanyId: user?.companyid,
    Isactive: 1,
  }
  const validationRules = {
    BranchName: { required: true },
    // BranchCode: { required: true },
    // BranchAddress: { required: true },
    // City: { required: true },
    Website: { isURL: true },
    // ZipCode: { required: true },
    Phone: { isPhoneNumber: true },
    // EmailId: { required: true },
    TallyBranch: { required: true },
    DailySynch: { required: true },
  };
  const [branchData, setbranchData] = useState(branchValues)
  const [formErrors, setFormerrors] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [tallyBranchSelect, setTallyBranchSelect] = useState([])
  const [dailySyncSelect, setDailySyncSelect] = useState([])
  const [tallyBranchList, setTallyBranchList] = useState([]);

  const [tallybranchOpen, setTallybranchOpen] = useState(false);
  const CustomPaperTallybranch = (props) => {
    return (
      <Paper {...props}>
        {props.children}
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
          <Button
            variant="contained"
            // className="btn btn-search"
            size="small"
            onClick={() => setTallybranchOpen(false)}
          >
            Done
          </Button>
        </div>
      </Paper>
    );
  };

  const [dailysyncOpen, setDailysyncOpen] = useState(false);
  const CustomPaperDailysync = (props) => {
    return (
      <Paper {...props}>
        {props.children}
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
          <Button
            variant="contained"
            // className="btn btn-search"
            size="small"
            onClick={() => setDailysyncOpen(false)}
          >
            Done
          </Button>
        </div>
      </Paper>
    );
  };

  const getTallyBranch = async () => {
    try {
      const result = await BranchApi.getTallyBranches()
      console.log((result?.data?.TallyBranch));
      setTallyBranchList(result?.data?.TallyBranch)
    }
    catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getTallyBranch()
  }, [])

  useEffect(() => {
    if (isEdit) {
      //to pass row data to edit modal from datatgrid
      const getBranch = async () => {
        let branch
        try {
          const result = await BranchApi.getBranchById(branchId, token)
          console.log(result);
          branch = result?.data?.branch
          setbranchData({
            ...branchData,
            BranchId: branch?.branchid,
            BranchName: branch?.branchname,
            BranchAddress: branch?.branchaddress,
            EmailId: branch?.EmailId,
            City: branch?.city,
            Website: branch?.website,
            ZipCode: branch?.zipcode,
            Phone: branch?.phone,
            BranchCode: branch?.branchcode,
            CompanyId: branch?.companyid,
            Isactive: branch?.Isactive,

          });
          const tallyArray = branch.tallyBranch.split(",");
          const dailySynchArray = branch.dailySynch.split(",");

          const filteredTallyBranchList = tallyBranchList.filter((item) => {
            return tallyArray.includes(item.tallybranchcode);
          });

          const filteredDailySyncList = tallyBranchList.filter((item) => {
            return dailySynchArray.includes(item.tallybranchcode);
          });

          console.log(tallyArray, filteredTallyBranchList);
          setTallyBranchSelect(filteredTallyBranchList);
          setDailySyncSelect(filteredDailySyncList);
        } catch (error) {
          console.log(error);
        }
      };
      getBranch();
    }

  }, [branchId, tallyBranchList])



  //For form validation
  const validate = (values) => {
    console.log(values);
    const errors = validateForm(values, validationRules);
    console.log(errors);
    const newError ={...errors,
      TallyBranch: "Select Branch Datasets ",
      DailySynch:"Select Current Year"
    }
    setFormerrors(newError)
    if (Object.keys(errors).length === 0) {
      return true;
    }
    else {
      return false;
    }
  }

  //for add branch api call
  const createBranch = async (data) => {
    console.log(isSaving);
    try {
      //       setTimeout(()=>{
      // setIsSaving(false)
      //       }, 4000)
      const result = await BranchApi.createBranch(data, token)
      if (result) {
        console.log(result);
        onBranchCreated(result?.data?.branch, result?.data?.message)
        setIsSaving(false)
      }
    }
    catch (error) {
      setIsSaving(false)
      console.log(error);
    }
  }
  const updateBranch = async (data) => {
    console.log(data);
    try {
      const result = await BranchApi.updateBranch(branchId, data, token)
      if (result) {
        onBranchUpdated(result?.data?.branch, result?.data?.message)
        setIsSaving(false)
      }
    }
    catch (error) {
      setIsSaving(false)
      console.log(error);
    }
  }
  //To set input values for add branch 
  const handleChange = (e) => {
    setFormerrors({})
    if (e.target.name === 'Phone') {
      // Allow only digits
      if (!/^\d*$/.test(e.target.value)) {
        setFormerrors({ ...formErrors, [e.target.name]: 'Please enter only digits' });
        return;
      }
    }
    setbranchData({ ...branchData, [e.target.name]: e.target.value })
  }

  //To set is active input
  const handleActive = (e) => {
    // e.target.checked ? branchData.Isactive = 1 : branchData.Isactive = 0
    setbranchData({
      ...branchData,
      Isactive: e.target.checked ? 1 : 0
    });
  }
  const handletallyBranchSelect = (event) => {
    const { target: { value }, } = event;
    console.log(event.target.value);
    setTallyBranchSelect(typeof value === 'string' ? value.split(',') : value);
    // setTallyBranchSelect(event.target.value)
  };

  const handledailySyncSelect = (event) => {
    const { target: { value }, } = event;
    console.log(event.target.value);
    setDailySyncSelect(typeof value === 'string' ? value.split(',') : value);
    // setDailySyncSelect(event.target.value)
  };
  //form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormerrors({})
    setIsSaving(true)
    console.log(tallyBranchSelect, dailySyncSelect);

    const data = {
      ...branchData,
      TallyBranch: tallyBranchSelect.map(item => item.tallybranchcode).join(','),
      DailySynch: dailySyncSelect.map(item => item.tallybranchcode).join(',')
    }
    // console.log(data);
    if (validate(data)) {
      if (isEdit) {
        updateBranch(data)
      }
      else {
        createBranch(data)
      }
    }
    else {
      setIsSaving(false)
    }
  }


  const cancelForm = () => {
    setFormerrors({})
    onCancel(isEdit)
  }
  return (
    <div className='form-box' dir={i18n.dir()}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {isEdit && <Typography className='form heading' >
            {t('form.editBranch')}
          </Typography>}
          {!isEdit && <Typography className='form heading'>
            {t('form.newBranch')}
          </Typography>}
          <Divider color="#DADADA" />
        </Grid>
        <Grid item xs={12}>
          <Box >
            <form onSubmit={handleSubmit}>
              <Box mt={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={2.3} className='grid center'><Typography >{t('form.branchName')}<span className="span asterisk">*</span></Typography></Grid>
                  <Grid item xs={12} md={onBoarding ? 9.7 : 3.7} className='grid center'>
                    <TextField
                      fullWidth
                      size='small'
                      // label={t('form.branchName')}
                      variant="outlined"
                      required
                      name='BranchName'
                      error={!!formErrors.BranchName}
                      value={branchData.BranchName}
                      onChange={handleChange} />
                    {formErrors.BranchName && <span style={{ color: 'red' }}>{formErrors.BranchName}</span>}
                  </Grid>
                  {!onBoarding && <>
                    <Grid item xs={12} md={2.3} className='grid center'><Typography >{t('form.branchCode')}</Typography></Grid>
                    <Grid item xs={12} md={3.7} className='grid center'>
                      <TextField
                        fullWidth
                        size='small'
                        // label={t('form.branchCode')}
                        variant="outlined"
                        name='BranchCode'
                        value={branchData.BranchCode}
                        onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={2.3} className='grid center'><Typography >{t('form.branchAddr')}</Typography></Grid>
                    <Grid item xs={12} md={3.7} className='grid center'>
                      <TextField
                        fullWidth
                        size='small'
                        // label={t('form.branchAddr')}
                        variant="outlined"
                        name='BranchAddress'
                        multiline
                        minRows={2}
                        value={branchData.BranchAddress}
                        onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={2.3} className='grid center'><Typography >{t('form.city')}</Typography></Grid>
                    <Grid item xs={12} md={3.7} className='grid center'>
                      <TextField
                        fullWidth
                        size='small'
                        // label={t('form.city')}
                        variant="outlined"
                        name='City'
                        value={branchData.City}
                        onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={2.3} className='grid center'><Typography >{t('form.website')}</Typography></Grid>
                    <Grid item xs={12} md={3.7} className='grid center'>
                      <TextField
                        fullWidth
                        size='small'
                        variant="outlined"
                        name='Website'
                        value={branchData.Website}
                        error={!!formErrors.Website}
                        onChange={handleChange} />
                      {formErrors.Website && <Typography color='error'>{formErrors.Website}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={2.3} className='grid center'><Typography >{t('form.postalCode')}</Typography></Grid>
                    <Grid item xs={12} md={3.7} className='grid center'>
                      <TextField
                        fullWidth
                        size='small'
                        // label={t('form.postalCode')}
                        variant="outlined"
                        name='ZipCode'
                        value={branchData.ZipCode}
                        onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={2.3} className='grid center'><Typography >{t('form.phone')}</Typography></Grid>
                    <Grid item xs={12} md={3.7} className='grid center'>
                      <TextField
                        fullWidth
                        size='small'
                        // label={errMsgPhone ? formErrors.phone : t('form.phone')}
                        variant="outlined"
                        name='Phone'
                        // color={errMsgPhone? 'error' : 'primary'}
                        error={!!formErrors.Phone}
                        value={branchData.Phone}
                        // error={errMsgPhone}
                        onChange={handleChange} />
                      {formErrors.Phone && <Typography color='error'>{formErrors.Phone}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={2.3} className='grid center'><Typography >{t('form.email')}</Typography></Grid>
                    <Grid item xs={12} md={3.7} className='grid center' >
                      <TextField
                        fullWidth
                        size='small'
                        // label={t('form.email')}
                        variant="outlined"
                        type='email'
                        name='EmailId'
                        value={branchData.EmailId}
                        onChange={handleChange} />
                    </Grid>
                  </>}
                  <Grid item xs={12} md={2.3} className='grid center'><Typography>{t("form.TallyBranch")}<span className="span asterisk">*</span></Typography> </Grid>
                  <Grid item xs={12} md={9.7} className='grid center'>
                  <Box className='' display='flex' flexDirection='column' width='100%'>
                    <FormControl required size="small" fullWidth>
                      <Autocomplete
                        multiple
                        // placeholder='Select all financial year datasets for this branch'
                        id="demo-multiple-checkbox"
                        options={tallyBranchList}
                        disableCloseOnSelect
                        open={tallybranchOpen}
                        onOpen={() => setTallybranchOpen(true)}
                        onClose={() => setTallybranchOpen(false)}
                        value={tallyBranchSelect}
                        onChange={(_, newValue) =>
                          handletallyBranchSelect({
                            target: { value: newValue },
                          })
                        }
                        getOptionLabel={(option) => option.tallybranchname + "(" + option.bookStartingFrom + ")"}
                        renderOption={(props, option, { selected }) => (
                          <MenuItem {...props}>
                            <Checkbox checked={selected} />
                            <ListItemText primary={option.tallybranchname + "(" + option.bookStartingFrom + ")"} />
                          </MenuItem>
                        )}
                        PaperComponent={CustomPaperTallybranch}
                        PopperComponent={(props) => <Popper {...props} placement="bottom-start" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            error={!!formErrors.TallyBranch}
                            placeholder={tallyBranchSelect?.length===0?'Select all financial year datasets for this branch':null}
                          />
                        )}
                      />
                    </FormControl>
                    {formErrors.TallyBranch && <Typography color='error'>{formErrors.TallyBranch}</Typography>}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={2.3} className='grid center'><Typography>{t("form.DailySync")}<span className="span asterisk">*</span></Typography></Grid>
                  <Grid item xs={12} md={9.7} className='grid center'>
                  <Box className='' display='flex' flexDirection='column' width='100%'>
                    <FormControl required size="small" fullWidth>
                      <Autocomplete
                        multiple
                        id="demo-multiple-checkbox"
                        options={tallyBranchList}
                        disableCloseOnSelect
                        open={dailysyncOpen}
                        onOpen={() => setDailysyncOpen(true)}
                        onClose={() => setDailysyncOpen(false)}
                        value={dailySyncSelect}
                        onChange={(_, newValue) =>
                          handledailySyncSelect({
                            target: { value: newValue },
                          })
                        }
                        getOptionLabel={(option) => option.tallybranchname + "(" + option.bookStartingFrom + ")"}
                        renderOption={(props, option, { selected }) => (
                          <MenuItem {...props}>
                            <Checkbox checked={selected} />
                            <ListItemText primary={option.tallybranchname + "(" + option.bookStartingFrom + ")"} />
                          </MenuItem>
                        )}
                        PaperComponent={CustomPaperDailysync}
                        PopperComponent={(props) => <Popper {...props} placement="bottom-start" />}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            error={!!formErrors.DailySynch}
                            placeholder={dailySyncSelect?.length===0?'Select only current financial year dataset for this branch':null}
                          />
                        )}
                      />
                    </FormControl>
                    {formErrors.DailySynch && <Typography color='error'>{formErrors.DailySynch}</Typography>}
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={2.3} className='grid center'><Typography >{t('form.active')}</Typography></Grid>
                  <Grid item xs={12} md={3.7} display='flex' alignItems='center'>
                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={branchData.Isactive === 1} onChange={handleActive} />
                  </Grid>
                </Grid>

                <div className='form-button-div'>
                  {!isEdit && !isSaving &&
                    <Button type="submit" className="btn btn-update">
                      {t('form.add')}
                    </Button>
                  }
                  {isEdit && !isSaving &&
                    <Button type="submit" className="btn btn-update">
                      {t('form.edit')}
                    </Button>
                  }
                  {isSaving && <Button className="btn btn-save">
                    <span className="btn-text">{t('form.saving')}</span><div className="spinner"></div>
                  </Button>}
                  <Button className="btn btn-delete" onClick={() => cancelForm()}>
                    {t('form.cancel')}
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>


    </div>
  )
}

export default BranchForm