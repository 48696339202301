import React, { useEffect, useState } from 'react'
import {
  Box, Grid, Button, Accordion, FormControlLabel,
  AccordionSummary, AccordionDetails, Typography,
  Stack, TextField, Switch
} from '@mui/material'
import * as UserApi from '../api/userApi'
import { useSelector } from 'react-redux'
import PermissionTable from '../components/userRole/PermissionTable'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PermissionEditTable from '../components/userRole/PermissionEditTable'

// let validActions = []
function ActionSetiings({ setIsNewRole, roleId, operation }) {
  const token = useSelector(state => state.token)
  const [actions, setActions] = useState()
  const [mainMenu, setMainMenu] = useState()
  const [roleName, setRoleName] = useState('')
  const [active, setActive] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [editActions, setEditActions] = useState([])

  useEffect(() => {
    const getActions = async () => {
      try {
        const result = await UserApi.getActions(roleId, token)
        console.log(result);
        setActions(result.data.Actions)
        setMainMenu([...new Map(result.data.Actions?.map(item =>
          [item['menu_id'], item])).values()].sort((a, b) => a.menu_order - b.menu_order));
      }
      catch (error) {
        console.log(error);
      }
    }
    const getUserRole = async () => {
      try {
        const result = await UserApi.getUserRole(roleId, token)
        console.log(result);
        setRoleName(result.data.UserType.usertypename)
        setActive(result.data.UserType.isactive)
        setIsActive(result.data.UserType.isactive===1?true:false)
        setEditActions(result.data.UserType.permitted_actions.filter(item => item.isPermit === 1).map(element => element.actions_id))
      }
      catch (error) {
        console.log(error);
      }
    }
    getActions()
    if (operation === 'edit') {
      getUserRole()
    }
  }, [token])

  const handleActive = (e) => {
    e.target.checked ? setActive(1) : setActive(0)
  }

  const handleEditActive = (e) => {
    e.target.checked ? setActive(1) : setActive(0)
    setIsActive(e.target.checked)
  }

  const handleChange = (id) => {
    let index = editActions.indexOf(id)
    index === -1 ? editActions.push(id) : editActions.splice(index, 1)
  };

  const handleEditChange = (id) => {
    console.log(id);
    const index = editActions.indexOf(id);
    if (index === -1) {
      setEditActions([...editActions, id]);
    } 
    else {
      const newArr = [...editActions];
      newArr.splice(index, 1);
      setEditActions(newArr);
    }
  };
  const createRole = async (roleData) => {
    try {
      const result = await UserApi.createUserType(roleData, token)
      console.log(result);
      setIsNewRole(false)
      alert(result.data.message)
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const stringActions = editActions.map(num => num.toString());
    const roleData = {
      "usertypename": roleName,
      "Actions": stringActions,
      "isactive": active,
      "createdby": 1,
    }
    editActions.length !== 0? createRole(roleData): alert('Set Permission')
  }

  const updateRole = async (roleData) => {
    try {
      console.log(roleData);
      const result = await UserApi.updateUserType(roleId, roleData, token)
      console.log(result);
      if(result.data.UserType){
        window.location.reload();
      }
      setIsNewRole(false)
      alert(result.data.message)
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const stringActions = editActions.map(num => num.toString());
    const roleData = {
      "usertypename": roleName,
      "Actions": stringActions,
      "isactive": active,
      "createdby": 1,
    }
    console.log(roleData);
    editActions.length !== 0? updateRole(roleData) : alert('Set Permission')
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{
            float: 'right'
          }}>
            <Button sx={{ height: '30px' }} onClick={() => setIsNewRole(false)} variant="outlined">View Roles</Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
         {operation === 'add' &&
          <form onSubmit={handleSubmit}>
          <Box sx={{ '& .MuiFormControl-root': { mb: 1 } }}>
            <Stack direction="row" spacing={1}>
              <TextField
                size='small'
                sx={{ width: '100%', backgroundColor: 'white' }}
                label="Role Name"
                required
                name='RoleName'
                value={roleName}
                onChange={(e) => { setRoleName(e.target.value) }} />
            </Stack>
            <Stack direction="row" spacing={1}>
              <span style={{ marginLeft: '10px', marginBottom: '10px' }}>Set Permissions  :</span>
            </Stack>
            <Stack >
              {mainMenu?.map(item => (
                <Box key={item.menu_id} mb={1} >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    // sx={{ backgroundColor: '#404040', color: 'white' }}
                    >
                      <Typography>{item.menu_name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PermissionTable subMenu={actions?.filter(element => element.menu_id === item.menu_id)} handleChange={handleChange} />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))}
            </Stack>
            <Stack sx={{ marginLeft: '5px' }} direction="row" spacing={1}>
              <FormControlLabel control={<Switch />} label="Is Active" onChange={handleActive} />
            </Stack>
            <div style={{ display: 'flex', marginTop: '10px', marginRight: '10px' }}>
                <Button sx={{ marginRight: '10px' }} type="submit" variant="outlined" color="success"  >
                  ADD
                </Button>
              <Button variant="outlined" color="error" onClick={() => setIsNewRole(false)}>
                CANCEL
              </Button>
            </div>
          </Box>
        </form>
         }
          {operation === 'edit' && 
           <form onSubmit={handleEditSubmit}>
           <Box sx={{ '& .MuiFormControl-root': { mb: 1 } }}>
             <Stack direction="row" spacing={1}>
               <TextField
                 size='small'
                 sx={{ width: '100%', backgroundColor: 'white' }}
                 label="Role Name"

                 required
                 name='RoleName'
                 value={roleName}
                 onChange={(e) => { setRoleName(e.target.value) }} />
             </Stack>
             <Stack direction="row" spacing={1}>
               <span style={{ marginLeft: '10px', marginBottom: '10px' }}>Set Permissions  :</span>
             </Stack>
             <Stack >
               {mainMenu?.map(item => (
                 <Box key={item.menu_id} mb={1} >
                   <Accordion>
                     <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                     // sx={{ backgroundColor: '#404040', color: 'white' }}
                     >
                       <Typography>{item.menu_name}</Typography>
                     </AccordionSummary>
                     <AccordionDetails>
                         <PermissionEditTable subMenu={actions?.filter(element => element.menu_id === item.menu_id)} handleEditChange={handleEditChange} editActions={editActions} />
                     </AccordionDetails>
                   </Accordion>
                 </Box>
               ))}
             </Stack>
             <Stack sx={{ marginLeft: '5px' }} direction="row" spacing={1}>
             <FormControlLabel control={<Switch checked={isActive} onChange={handleEditActive}/>} label="Is Active"  />             
             </Stack>
             <div style={{ display: 'flex', marginTop: '10px', marginRight: '10px' }}>
                 <Button sx={{ marginRight: '10px' }} type="submit" variant="outlined" color="success">
                   UPDATE
                 </Button>
               <Button variant="outlined" color="error" onClick={() => setIsNewRole(false)}>
                 CANCEL
               </Button>
             </div>
           </Box>
         </form>
          }
        </Grid>
      </Grid>
    </Box>
  )
}

export default ActionSetiings