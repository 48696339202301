import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { changeCurrencyFormat, graphCurrencyFormat } from '../../HelperFile';
import { useSelector } from 'react-redux';

const customColors = ['#1F77B4', '#909090', '#0693A0', '#B45C1F', '#489C8F', '#0086AE', '#74cf6c'];

const ProductGraph = ({ chartData, isUnit }) => {
    console.log(chartData);
    const user = useSelector(state => state.user);
    const company = user?.company;
    const yValue = user?.company?.currencyName;
    const [data, setData] = useState()

    useEffect(() => {
        const allTransactionTypes = Array.from(new Set(chartData?.map(item => item.VoucherType)));
        const convertedData = chartData?.reduce((result, item) => {
            const { Month, VoucherType } = item;
            const TransValue = isUnit? item.Units : item.TransValue
            
            // Create an entry for the month if it doesn't exist
            result[Month] = result[Month] || { Month, Purchase: 0, Sales: 0 };
            // Set the value for the TransValue in the current month
            result[Month][VoucherType] = TransValue;

            return result;
        }, {});
        // Convert the object into an array
        const finalResult = Object.values(convertedData);
        console.log(finalResult);
        const newData = finalResult?.sort((a, b) => new Date(a.Month) - new Date(b.Month))
        setData(newData)

    }, [chartData, isUnit])

    const CustomLegend = ({ payload }) => {
        // console.log(payload);
        return (
            <ul style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                {payload.filter(item => item.dataKey !== 'Profitperc').map((entry, index) => (
                    <li key={`legend-${index}`} style={{ marginRight: 10 }}>
                        <svg height="8" width="20"><line x1="0" y1="5" x2="30" y2="5" stroke={entry.color} strokeWidth="4" /></svg>
                        <span style={{ color: 'black', marginLeft: 5 }}>{entry.value}</span>
                    </li>
                ))}
            </ul>
        );
    };

    const tooltipFormatter = (value, name) => {
        // Customize the tooltip content here
        return [
            <span style={{ fontSize: '10px' }}>{`${name}: ${isUnit? value :changeCurrencyFormat(value, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}`}</span>
        ];
    };
    return (
        <Box height='330px'>
            {data &&
                <ResponsiveContainer width="100%" height='100%'>
                    <LineChart
                        data={data}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 10,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid stroke='#E6E6E6' strokeDasharray="5 5" />
                        <XAxis dataKey="Month" fontSize={10} fontWeight={400} tick={{ fill: 'black', dy: 15 }} tickLine={false} axisLine={false} />
                        <YAxis label={{ value: `( ${yValue} )`, angle: -90, dx: -20, fontSize: 12, fill: 'black' }} fontSize={12} fontWeight={500} tick={{ fill: 'black' }} tickLine={false} axisLine={false}
                            tickFormatter={(value) => graphCurrencyFormat(value, company?.currencyCode)} />
                        <Tooltip formatter={tooltipFormatter} />
                        <Legend
                            content={<CustomLegend />}
                            wrapperStyle={{ paddingTop: '10px', fontSize: '10px', fontWeight: 400 }}
                        />
                        <Line dataKey="Sales" stroke="#1F77B4" strokeWidth={1.5} dot={{ fill: "#489C8F", r:2 }} />
                        <Line dataKey="Purchase" stroke="#489C8F" strokeWidth={1.5} dot={{ fill: "#1F77B4", r:2 }} />
                        {/* {Object.keys(data[0]).map((key, index) => {
                            if (key !== 'Month') {
                                return (
                                    <Line
                                        key={index}
                                        type="monotone"
                                        fill={customColors[index]}
                                        dataKey={key}
                                        stroke={customColors[index]}
                                        strokeWidth={1.5}
                                        dot={{ r: 2 }}
                                    >
                                    </Line>
                                );
                            }
                            return null;
                        })} */}
                    </LineChart>
                </ResponsiveContainer>
            }
        </Box>
    );
};

export default ProductGraph;
