import axiosInstance from "./axiosInstance";

let api = axiosInstance()

export const getFilterType = (abortToken) => {
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.get('/FilterType', config);
}

export const getPermittedBranch = (userId, abortToken) => {
    const config = {
        ...abortToken,
        timeout: 30000, // Set the timeout for this specific request
      };
    return api.get(`/branch/Permitted/${userId}`, config);
}