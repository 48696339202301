import axiosInstance from "./axiosInstance";

let api

export const getAllFyr =(token)=>{
    api= axiosInstance(token)
    return api.get(`/FinancialYear`)
}

export const getFyrByCriteria =(branchId, token, abortToken)=>{
    api= axiosInstance(token)
    return api.get(`/FinancialYear?BranchId=${branchId}`, abortToken)
}

export const getFyrById =(id, token)=>{
    api= axiosInstance(token)
    return api.get(`/FinancialYear/${id}`)
}

export const createFyr =(data,token)=>{
    api= axiosInstance(token)
    return api.post(`/FinancialYear`, data)
}

export const updateFyr =(id, data, token)=>{
    api= axiosInstance(token)
    return api.put(`/FinancialYear/${id}`, data)
}

export const deleteFyr =(id, token)=>{
    api= axiosInstance(token)
    return api.delete(`/FinancialYear/${id}`)
}

export const getFyrByCompany =(id)=>{
    api= axiosInstance()
    return api.get(`/FinancialYearByCompany/${id}`)
}