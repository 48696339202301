import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import defaultimage from "../../../images/default.png";

const MAX_FILE_SIZE_MB = 1;

const UploadImage = ({ selectedImage, setSelectedImage, setSelectedImageBase64 }) => {

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        alert(`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please choose a smaller file.`);
        return;
      }

      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
      if (!allowedTypes.includes(file.type)) {
        alert('Invalid file type. Please choose a valid image file.');
        return;
      }

      // Create a new instance of FileReader
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const base64Data = reader.result.split(',')[1]; // Get base64 data excluding the data URI scheme
          setSelectedImageBase64(base64Data)
          setSelectedImage(reader.result);
        } catch (error) {
          console.error('Error handling image data:', error);
          // Handle the error (e.g., show an error message to the user)
        }
      };

      reader.readAsDataURL(file);

    } else {
      setSelectedImage(null);
    }
  };

  const reader = new FileReader();

  reader.onloadend = () => {
    console.log(reader.result); // Log the result here
  };


  const handleDeleteImage = () => {
    setSelectedImage(null);
  };
  return (
    <Box>
      <Grid container >
        <Grid item xs={12}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="icon-button-file"
            type="file"
            onChange={handleImageChange}
          />
          {!selectedImage && (
            <label htmlFor="icon-button-file">
              <IconButton sx={{ color: "#DADADA" }} aria-label="upload picture" component="span">
              <img src={process.env.PUBLIC_URL + `/assets/images/add_photo.png`} alt='logo' height={20} width={20} />
              </IconButton>
            </label>
          )}
        </Grid>
        <Grid item xs={12} >
          {selectedImage ? (
            <div style={{ paddingTop: '20px' }}>
              <Box className="box center">
                <img src={selectedImage} alt="no image" height={100} width={100}/>
              </Box>
              <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <IconButton aria-label="delete picture" onClick={handleDeleteImage} >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            </div>
          ) : (
            <Box className="box center">
              <img src={process.env.PUBLIC_URL + `/assets/images/no_image.png`} alt='logo'  height={80} width={130}/>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
    // <div style={{height: '100%'}}>
    //  <div style={{height: selectedImage?'0':'20%'}}>
    //  <input
    //     accept="image/*"
    //     style={{ display: 'none' }}
    //     id="icon-button-file"
    //     type="file"
    //     onChange={handleImageChange}
    //   />
    //   {!selectedImage  && (
    //     <label htmlFor="icon-button-file">
    //       <IconButton sx={{ color: "#DADADA" }} aria-label="upload picture" component="span">
    //         <PhotoCamera />
    //       </IconButton>
    //     </label>
    //   )}
    //  </div>

    //   {selectedImage ? (
    //     <div style={{height: '90%', paddingTop: '10px'}}>
    //       <Box height='90%' className="box center">
    //         <img src={selectedImage} alt="no image" height='100%' width='40%'/>
    //       </Box>
    //       <Box style={{ display: 'flex', justifyContent: 'flex-end', height:'10%' }}>
    //         <IconButton aria-label="delete picture" onClick={handleDeleteImage} >
    //           <DeleteIcon fontSize="small"/>
    //         </IconButton>
    //       </Box>
    //     </div>
    //   ) : (
    //     <div style={{height: '80%'}} className="box center">
    //       <img
    //         src={defaultimage}
    //         alt="no image"
    //         height='90%'
    //       />
    //     </div>
    //   )}
    // </div>
  );
};

export default UploadImage;