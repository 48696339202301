import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../components/styles/theme';
import dayjs from 'dayjs'
import { useSelector } from 'react-redux';
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from '../../api/HandleApiErrors';
import { useTranslation } from 'react-i18next';
import { getPermittedBranch } from '../../api/commonApi';
import { changeFormat, dateConvert, changeCurrencyFormat, getMonthRange } from '../../HelperFile';
import JournalHeader from '../../components/JournalHeader';
import AntSwitch from '../../components/styles/AntSwitch';
import * as transactionreportApi from '../../api/transactionreportApi';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import JournalExtraFilter from '../../components/JournalExtraFilter';
import { getFyrByCompany } from '../../api/fyrApi';
import TabularHeader from '../../components/TabularHeader';


function Journal() {
  const { t } = useTranslation()
  const isFyr = useRef(false)
  const isBranchList = useRef(false)
  const initialLoading = useRef(false)
  const abortConstants = useSignalAbort()
  const token = useSelector(state => state.token)
  const user = useSelector(state => state.user)
  const selectedDetails = useSelector(state => state.selectedParameters)
  const companyId = user?.companyid
  const company = user?.company
  const [branchList, setBranchList] = useState()
  const [branchSelect, setbranchSelect] = useState()
  const [branchSelectSearch, setbranchSelectSearch] = useState()
  const [fyrList, setFyrList] = useState()
  const [fyrSelect, setFyrSelect] = useState('');
  const [selectedFyr, setSelectedFyr] = useState()
  const [toDate, setTodate] = useState('')
  const [frmDate, setFrmdate] = useState('')
  const [frDate, setFrDate] = useState()
  const [tDate, setTDate] = useState()
  const [branchName, setBranchName] = useState()
  const [isLoading, setIsLoading] = useState({})
  const [voucherSelect, setVoucherSelect] = useState('');
  const [Vouchertype, setVouchertype] = useState()
  const [journal, setJournal] = useState()
  const [isDetailedView, setIsDetailedView] = useState(false);


  //display message if default branch is not set to the user
  useEffect(() => {
    if (user && !user?.branchid) {
      toast.error("A default branch has not been assigned to you. Please contact your administrator", {
        autoClose: 3000, // Close the toast after 5 seconds
      });
    }
  }, [user])

  useEffect(() => {
    const fetchData = async () => {
      if (!token || !companyId || !user) return;

      if (!isFyr.current) {
        console.log("Fetching financial year data...");
        await getFyrByCriteria(companyId, abortConstants.controllerConfig);
        isFyr.current = true;
      }

      if (!isBranchList.current) {
        console.log("Fetching branch data...");
        await getBranch(user.userid, abortConstants.controllerConfig);
        isBranchList.current = true;
      }

      if (branchSelect && selectedFyr) {
        console.log("Fetching voucher type...");
        const frm = dateConvert(dayjs(selectedFyr.Fyrfrom));
        const to = dateConvert(dayjs(selectedFyr.Fyrto));
        await getVoucherType(branchSelect, frm, to, 'Journal', abortConstants.controllerConfig);
      }

      if (branchSelect && frmDate && toDate && !initialLoading.current) {
        console.log("Fetching all data...");
        await getAllData(branchSelect, frmDate, toDate, '', 'Journal', abortConstants.controllerConfig);
        initialLoading.current = true;
      }
    };

    fetchData();

    return () => {
      console.log("Cleanup");
      abortConstants.controller.abort();
      toast.dismiss();
    };
  }, [token, companyId, user, branchSelect, selectedFyr, frmDate, toDate]);

    //get branch and financial year list
  //   useEffect(() => {
  //     console.log("useeffect");
  //     if (token && companyId && isFyr.current === false) {
  //       console.log("fyr");
  //       getFyrByCriteria(companyId, abortConstants.controllerConfig)
  //     }
  
  //     return () => {
  //       console.log("inside cleanuo");
  //       // Cancel the request before the component unmounts
  //       abortConstants.controller.abort()
  //       toast.dismiss()
  //     };
  //   }, [token, companyId])

  // //get branch and financial year list
  // useEffect(() => {
  //   if (token && user && isBranchList.current === false && selectedFyr) {
  //     console.log("useeffect");
  //     let frm = dateConvert(dayjs(selectedFyr.Fyrfrom))
  //     let to = dateConvert(dayjs(selectedFyr.Fyrto))
  //     getBranch(user?.userid, frm, to, abortConstants.controllerConfig)
  //   }

  //   return () => {
  //     console.log("inside cleanuo");
  //     // Cancel the request before the component unmounts
  //     abortConstants.controller.abort()
  //     toast.dismiss()
  //   };
  // }, [token, user, selectedFyr])

  // //call all api to get data
  // useEffect(() => {
  //   console.log("inside getall");
  //   if (branchSelect && frmDate && toDate &&  initialLoading.current === false) {
  //     console.log(frmDate, toDate);
  //     getAllData(branchSelect, frmDate, toDate, '', "Journal", abortConstants.controllerConfig)
  //     initialLoading.current = true
  //   }
  //   return () => {
  //     console.log("inside cleanuo");
  //     // Cancel the request before the component unmounts
  //     abortConstants.controller.abort()
  //     toast.dismiss()
  //   };
  // }, [branchSelect, frmDate, toDate])

  // const getFyrByCriteria = async (id, abortToken) => {
  //   try {
  //     const result = await getFyrByCompany(id, abortToken)
  //     console.log(result);
  //     setFyrList(result?.data?.FinancialYear)
  //     isFyr.current = true
  //     let fYear = result.data.FinancialYear.find(item => item.Iscurrent === 1)
  //     let range = getMonthRange(fYear?.Fyrfrom, fYear?.Fyrto, dayjs().month())
  //     setSelectedFyr(fYear)
  //     setFyrSelect(fYear?.Fyrname)
  //     setFrmdate(range[0])
  //     setTodate(range[1])
  //     // setSelectedFyr(fYear)
  //     // setFyrSelect(fYear?.Fyrname)
  //     // setFrmdate(dayjs(fYear?.Fyrfrom))
  //     // setTodate(dayjs(fYear?.Fyrto))
  //   }
  //   catch (error) {
  //     handleApiErrors(error, 'Financial Year', 'fyr_response', 'fyr_timeout', 'fyr_request', setIsLoading)
  //   }
  // }

  // const getBranch = async (userId, frm, to, abortToken) => {
  //   console.log("inside branch latest");
  //   try {
  //     const result = await getPermittedBranch(userId, abortToken);//api call to get all branch
  //     console.log("dashbranch", result);
  //     isBranchList.current = true;
  //     if (result?.data?.branch.length !== 0) {
  //       setBranchList(result?.data?.branch);
  //       if (!user?.branchid) {
  //         // Set branchSelect to the first branch ID if user does not have a branch ID
  //         setbranchSelect(result?.data?.branch[0].branchid);
  //         getVoucherType(result?.data?.branch[0].branchid, frm, to, 'Journal', abortToken)

  //         const brName = result.data?.branch[0].branchname;
  //         setBranchName(brName);
  //       } else {
  //         getVoucherType(branchSelect, frm, to, 'Journal', abortToken)

  //         const brName = result.data?.branch
  //           .filter(item => branchSelect ===item.branchid)
  //           .map(item => item.branchname);
  //         setBranchName(brName);
  //       }
  //     }
  //     else {
  //       toast.error(result?.data?.message, {
  //         autoClose: 3000, // Close the toast after 5 seconds
  //       });
  //     }
  //   } catch (error) {
  //     handleApiErrors(error, 'Branch', 'branch_response', 'branch_timeout', 'branch_request', setIsLoading)
  //   }
  // }

  const getFyrByCriteria = async (id, abortToken) => {
    try {
      const result = await getFyrByCompany(id, abortToken);
      setFyrList(result?.data?.FinancialYear);
      isFyr.current = true;

      const fYear = selectedDetails?.selectedFyr
        ? result?.data?.FinancialYear?.find(item => item.Fyrname === selectedDetails?.selectedFyr)
        : result?.data?.FinancialYear?.find(item => item.Iscurrent === 1);

      const fromDate = selectedDetails?.selectedFromDate || getMonthRange(fYear?.Fyrfrom, fYear?.Fyrto, dayjs().month())[0];
      const toDate = selectedDetails?.selectedToDate || getMonthRange(fYear?.Fyrfrom, fYear?.Fyrto, dayjs().month())[1];

      setSelectedFyr(fYear);
      setFyrSelect(fYear?.Fyrname);
      setFrmdate(dayjs(fromDate));
      setTodate(dayjs(toDate));
    } catch (error) {
      handleApiErrors(error, 'Financial Year', 'fyr_response', 'fyr_timeout', 'fyr_request', setIsLoading);
    }
  };

  const getBranch = async (userId, abortToken) => {
    try {
      const result = await getPermittedBranch(userId, abortToken);
      isBranchList.current = true;
      if (result?.data?.branch.length !== 0) {
        setBranchList(result?.data?.branch);
        const selectedBranchId = selectedDetails?.selectedBranch || (user?.branchid || result?.data?.branch[0]?.branchid);
        const selectedBranchName = result.data?.branch
          .filter(item => item.branchid === selectedBranchId)
          .map(item => item.branchname);

        setbranchSelect(selectedBranchId);
        setBranchName(selectedBranchName);
      } else {
        toast.error(result?.data?.message, {
          autoClose: 3000,
        });
      }
    } catch (error) {
      handleApiErrors(error, 'Branch', 'branch_response', 'branch_timeout', 'branch_request', setIsLoading);
    }
  };

  const getAllData = (branchId, frm, to, voucher, transaction, abortToken) => {
    console.log(branchId, frm, to, voucher, transaction);
    setFrDate(changeFormat(dayjs(frm), user?.company?.DateFormatname))//change the format of from date
    setTDate(changeFormat(dayjs(to), user?.company?.DateFormatname))//change the format of to date
    let fDate = dateConvert(frmDate)
    let tDate = dateConvert(toDate)
    getJournal(branchId, fDate, tDate, voucher, transaction, abortToken)

  }


  //voucher List
  const getVoucherType = async (branchId, frm, to, type, abortToken) => {
    console.log(branchId, frm, to);
    try {
      const result = await transactionreportApi.getVoucherType(branchId, frm, to, type, abortToken)
      console.log("voucher", result);
      setVouchertype(result?.data?.Vouchers)
      console.log("Vocuchers", result?.data?.Vouchers);
      // setVoucherSelect(result?.data?.Vouchers[0]?.VoucherTypeName)
    }
    catch (error) {
      handleApiErrors(error, 'VoucherType', 'voucher_response', 'voucher_timeout', 'voucher_request', setIsLoading)
    }
  }

  //cashbook  
  // const getJournal = async (branchId, frm, to, voucher,transaction, abortToken) => {
  //   console.log(branchId, frm, to, voucher);
  //   try {
  //      const result = await transactionreportApi.getTransaction(branchId, frm, to, voucher,transaction, abortToken)
  //     console.log("Journal", result);


  //     setJournal(result?.data?.Transaction); 
  //   }
  //   catch (error) {
  //     handleApiErrors(error, 'Journal',  'journal_response', 'journal_timeout', 'journal_request', setIsLoading)
  //   }
  // }
  const getJournal = async (branchId, frm, to, voucher, transaction, abortToken) => {
    console.log(branchId, frm, to, voucher, transaction);
    try {
      //should be revised if error comes
      const result = await transactionreportApi.getTransaction(branchId, frm, to, voucher, transaction, abortToken)
      console.log("Journal", result);

      // Apply changeFormat to VoucherDate field
      const formattedTransactions = result.data.Transaction.map(transaction => ({
        ...transaction,
        VoucherDate: transaction.VoucherDate ? changeFormat(dayjs(transaction.VoucherDate), user?.company?.DateFormatname) : ''
      }));

      // setJournal(result?.data?.Transaction);
      const groupedTransactions = {};
      formattedTransactions.forEach(transaction => {
        const key = `${transaction.VoucherNo}_${transaction.VoucherDate}`;
        if (groupedTransactions[key]) {
          groupedTransactions[key].push(transaction);
        } else {
          groupedTransactions[key] = [transaction];
        }
      });
      console.log(Object.values(groupedTransactions));
      setJournal(Object.values(groupedTransactions));
    }
    catch (error) {
      handleApiErrors(error, 'Journal', 'journal_response', 'journal_timeout', 'journal_request', setIsLoading)
    }
  }

  //for search
  const handleSearchClick = (branchId, frm, to, voucherData) => {
    console.log(branchId, frm, to, voucherData);
    toast.dismiss()
    setJournal(null)

    setIsLoading({})
    getAllData(branchId, frm, to, voucherData, "Journal", abortConstants.controllerConfig)
  }

  //for loading circular progress
  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`] ||
      isLoading.branch_response || isLoading.branch_request || isLoading.branch_timeout
    ) {
      return true
    }
    else {
      return false
    }
  }

  // to disable/enable search button
  const isLoadingTrue = () => {
    const state = (Object.keys(isLoading).length === 0) && (!journal)
    return state
  }

  const exportToExcel = () => {
    // Extracting only the desired columns from the journal state
    const dataToExport = journal?.map(subArray => {
      return subArray.map(entry => ({
        Date: entry.VoucherDate,
        Particulars: entry.Narration,
        VoucherType: entry.VoucherType,
        "Ref No": entry.VoucherNo,
        Debit: entry?.IsdmdPositive === "Yes" ? entry.TransValue : "",
        Credit: entry?.IsdmdPositive === "No" ? entry.TransValue : "",
      }));
    }).flat(); // Flatten the nested array

    // Calculate the total debit and total credit
    const totalDebit = journal?.reduce((total, row) => {
      return row[0]?.IsdmdPositive === "Yes" ? total + parseFloat(row[0].TransValue) : total;
    }, 0);

    const totalCredit = journal?.reduce((total, row) => {
      return row[0]?.IsdmdPositive === "No" ? total + parseFloat(row[0].TransValue) : total;
    }, 0);

    // Append the total amount to the dataToExport array
    dataToExport.push({
      Date: "", // You can leave the date empty for the total row
      Particulars: "", // Leave other fields empty as they don't apply to the total row
      VoucherType: "",
      "Ref No": "Total Amount :",
      Debit: totalDebit,
      Credit: totalCredit,
    });

    // Converting the data to worksheet
    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Journal.xlsx');
  };




  // const calculateTotalCredit = () => {
  //     return rows.reduce((total, row) => total + row.credit, 0);
  // };

  // const calculateTotalDebit = () => {
  //     return rows.reduce((total, row) => total + row.debit, 0);
  // };

  const totalDebit = journal?.reduce((total, row) => {
    return row[0]?.IsdmdPositive === "Yes" ? total + parseFloat(row[0].TransValue) : total;
  }, 0);

  const totalCredit = journal?.reduce((total, row) => {
    return row[0]?.IsdmdPositive === "No" ? total + parseFloat(row[0].TransValue) : total;
  }, 0);




  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container spacing={2}>
          {/* header */}
          {branchList && fyrList &&  (
            <TabularHeader
              branchName={branchName}
              frDate={frDate}
              tDate={tDate}
              isLoadingTrue={isLoadingTrue}
              branchSelect={branchSelect}
              setbranchSelect={setbranchSelect}
              branchList={branchList}
              handleSearchClick={handleSearchClick}
              setbranchSelectSearch={setbranchSelectSearch}
              setBranchName={setBranchName}
              fyrList={fyrList}
              fyrSelect={fyrSelect}
              setFyrSelect={setFyrSelect}
              selectedFyr={selectedFyr}
              setSelectedFyr={setSelectedFyr}
              frmDate={frmDate}
              toDate={toDate}
              setFrmdate={setFrmdate}
              setTodate={setTodate}
              optionTitle="Voucher Type"
              optionList={Vouchertype}
              optionSelect={voucherSelect}
              setOptionSelect={setVoucherSelect}
              optionKey="VoucherTypeName"
              exportExcel={exportToExcel}
              companyName={company.companyname}
              title="Journal"
            />
          )}

          <Grid item xs={12}  >
            <Box >


              <Grid container alignItems="center" justify="space-between">

                {/* <Grid item xs={6}> 
     <JournalExtraFilter
      isLoadingTrue={isLoadingTrue}
      branchSelect={branchSelect}
      frmDate={frmDate}
      toDate={toDate}
      optionTitle="Voucher Type"
      optionList={Vouchertype}
      optionSelect={voucherSelect}
      setOptionSelect={setVoucherSelect}
      optionKey="VoucherTypeName"
      handleSearch={handleSearchClick}
      
    />
  </Grid> */}

                <Grid item xs={12} container justifyContent="flex-end">
                  {/* Consolidated/Detailed Switch */}
                  <Grid item display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" style={{ marginRight: '4px', color: '#1F77B4' }}>
                      Consolidated
                    </Typography> &nbsp; &nbsp;
                    <AntSwitch
                      checked={isDetailedView}
                      onChange={() => setIsDetailedView(!isDetailedView)}

                    /> &nbsp; &nbsp;
                    <Typography variant="body2" style={{ marginLeft: '4px' }}>
                      Detailed
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>




              {/* from here renderLoading */}
              {journal ? (
                <Box  >
                  <div  >
                    <>
                      {journal.length !== 0 ? (
                        //                    <TableContainer component={Paper} sx={{paddingLeft:'20px',paddingRight:'20px', maxHeight:500, overflow: 'auto' }}>
                        //                    <Table  style={{marginTop:'30px', width:'100%' }} aria-label="spanning table">

                        //                 <TableHead >
                        //                 <TableRow >
                        //                   <TableCell  style={{ position: 'sticky', top: 0,color: '#489C8F', fontWeight: 'bold' , background: '#fff' , borderRight: '1px solid #ddd' ,borderBottom:'none'}}>Date</TableCell>
                        //                     <TableCell  align='center' style={{ position: 'sticky', top: 0,color: '#489C8F', fontWeight: 'bold', background: '#fff' , borderRight: '1px solid #ddd' ,borderBottom:'none' }}>Ref No</TableCell>
                        //                     <TableCell   align='left' style={{ position: 'sticky', top: 0,color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd' ,borderBottom:'none'  }}>VoucherType</TableCell>
                        //                     <TableCell align='left' style={{ position: 'sticky', top: 0,color: '#489C8F', fontWeight: 'bold' , background: '#fff' , borderRight: '1px solid #ddd' ,borderBottom:'none'}}>Particulars</TableCell>
                        //                     <TableCell align='left' style={{ position: 'sticky', top: 0,color: '#489C8F', fontWeight: 'bold' , background: '#fff' , borderRight: '1px solid #ddd' ,borderBottom:'none'}}>Customer Name</TableCell>

                        //                     <TableCell  align='right' style={{ position: 'sticky', top: 0,color: '#489C8F', fontWeight: 'bold' , background: '#fff',  borderBottom:'none' }}>Net Amount</TableCell>
                        //                    </TableRow>
                        //                 </TableHead>

                        //                 <TableBody>
                        //                   {journal.map((row, index) => (
                        //                     <TableRow key={index}>
                        //                       <TableCell style={{lineHeight:'0.2', borderRight: '1px solid #ddd'}}>{row.VoucherDate}</TableCell>
                        //                       <TableCell align="center"  style={{lineHeight:'0.2', borderRight: '1px solid #ddd'}}>{row.VoucherNo}</TableCell>
                        //                       <TableCell align="left"  style={{lineHeight:'0.2', borderRight: '1px solid #ddd'}}>{row.VoucherType}</TableCell>
                        //                       <TableCell align="left" style={{ lineHeight: isDetailedView ? '1.2' : '0.2' , borderRight: '1px solid #ddd'}}>
                        //                       <div style={{marginBottom: '20px'}}>{row.Narration}</div>
                        //                           <div>{row.Narration}</div>

                        //                       {isDetailedView && <div style={{ fontSize: '12px', color: 'gray' }}>
                        //                       {row.Narration}</div>}
                        //                         </TableCell> 
                        //                         <TableCell align="left"  style={{lineHeight:'0.2', borderRight: '1px solid #ddd'}}>{row.CustomerName}</TableCell>

                        //                        <TableCell align="right"  style={{lineHeight:'0.2'}}>
                        //                        { changeCurrencyFormat(row.TransValue , company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')}

                        //                        {/* { row.NetAmount} */}

                        //                          </TableCell>
                        //                      </TableRow>
                        //                   ))}


                        //        <TableRow >
                        //            <TableCell  />
                        //         <TableCell  />
                        //         <TableCell  />
                        //            <TableCell align="center" colSpan={1} style={{ color: '#489C8F', fontWeight: 'bold'  }}>Total Amount :</TableCell>
                        //         <TableCell align="right" style={{  fontWeight: 'bold'  }}>
                        //           {/* Calculate and display the sum of NetAmount */}
                        // { changeCurrencyFormat(journal.reduce((total, row) => total + row.TransValue, 0) , company?.localeID, company?.currencyCode, Number(company?.decimalPlaces),"No_SYMBOL")}
                        //            </TableCell>
                        //       </TableRow>  


                        // </TableBody>
                        // </Table>
                        //                     </TableContainer>    
                        <TableContainer component={Paper} sx={{ paddingLeft: '20px', paddingRight: '20px', maxHeight: 500, overflow: 'auto' }}>
                          <Table style={{ marginTop: '30px', width: '100%' }} aria-label="spanning table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Date</TableCell>
                                <TableCell align='left' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Particulars</TableCell>
                                <TableCell align='left' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>VoucherType</TableCell>

                                <TableCell align='center' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Ref No</TableCell>

                                <TableCell align='right' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Debit</TableCell>
                                <TableCell align='right' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderBottom: 'none' }}>Credit</TableCell>

                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {journal.sort((a, b) => a[0].VoucherNo - b[0].VoucherNo).map((subArray, index) => (
                                <React.Fragment key={index}>
                                  {/* Main row */}
                                  <TableRow style={{ borderTop: '1px solid #ddd' }}>
                                    <TableCell style={{  borderRight: '1px solid #ddd', borderBottom: 'none' }}>{subArray[0].VoucherDate}</TableCell>
                                    <TableCell align="left" style={{  borderRight: '1px solid #ddd', borderBottom: 'none' }}>
                                      <div  > {subArray[0]?.CustomerName}</div>

                                      {isDetailedView && <div style={{ fontSize: '12px', color: 'gray' }}>
                                        {subArray[0]?.Narration}</div>}
                                    </TableCell>
                                    <TableCell align="left" style={{  borderRight: '1px solid #ddd', borderBottom: 'none' }}>{subArray[0]?.VoucherType}</TableCell>

                                    <TableCell align="center" style={{  borderRight: '1px solid #ddd', borderBottom: 'none' }}>{subArray[0]?.VoucherNo}</TableCell>



                                    <TableCell align="right" style={{  borderRight: '1px solid #ddd', borderBottom: 'none' }}>{subArray[0]?.IsdmdPositive === "Yes" ? changeCurrencyFormat(subArray[0].TransValue, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL") : ""}</TableCell>
                                    <TableCell align="right" style={{  borderBottom: 'none' }}>{subArray[0]?.IsdmdPositive === "No" ? changeCurrencyFormat(subArray[0].TransValue, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL") : ""}</TableCell>

                                  </TableRow>
                                  {/* Sub-rows */}
                                  {subArray.slice(1).map((item, subIndex) => (
                                    <TableRow key={`${index}-${subIndex}`}>
                                      <TableCell style={{ borderRight: '1px solid #ddd', borderBottom: 'none' }}></TableCell>

                                      {/* <TableCell>{item?.Narration}</TableCell> */}
                                      <TableCell align="left" style={{  borderRight: '1px solid #ddd', borderBottom: 'none' }}>
                                        <div  > {item?.CustomerName}</div>

                                        {isDetailedView && <div style={{ fontSize: '12px', color: 'gray' }}>
                                          {item?.Narration}</div>}
                                      </TableCell>
                                      <TableCell style={{ borderRight: '1px solid #ddd', borderBottom: 'none' }}></TableCell>
                                      <TableCell style={{ borderRight: '1px solid #ddd', borderBottom: 'none' }}></TableCell>
                                      <TableCell align="right" style={{ borderRight: '1px solid #ddd', borderBottom: 'none' }} >{item?.IsdmdPositive === "Yes" ? changeCurrencyFormat(item.TransValue, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL") : ""}</TableCell>
                                      <TableCell align="right" style={{ borderBottom: 'none' }}>{item?.IsdmdPositive === "No" ? changeCurrencyFormat(item.TransValue, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL") : ""}</TableCell>

                                    </TableRow>


                                  ))}

                                </React.Fragment>


                              ))}

                              <TableRow style={{ borderTop: '1px solid #ddd' }}>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell align="center" colSpan={1} style={{ color: '#489C8F', fontWeight: 'bold' }}>Total Amount :</TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                  {/* Display total debit */}
                                  {changeCurrencyFormat(totalDebit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}

                                </TableCell>
                                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                  {/* Display total credit */}
                                  {changeCurrencyFormat(totalCredit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}

                                </TableCell>
                              </TableRow>

                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <h4 style={{ textAlign: "center" }}>No data</h4>
                      )}
                    </>
                  </div>

                </Box>
              ) : (
                <>
                  {renderLoading("journal") ? (
                    <h5 style={{ textAlign: "center" }}>No data</h5>
                  ) : (
                    <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </>
              )}

              {/* upto here */}

            </Box>
          </Grid>


        </Grid>
      </Box>
    </ThemeProvider>
  )
}

export default Journal


