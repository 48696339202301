import React, { useState } from 'react'
import { AppBar, Avatar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from '../redux/slices/authSlice';
import { logout } from '../api/userApi';

function Header() {
    const user = useSelector(state => state.user);
    console.log(user);
    const dispatch = useDispatch();
    const [openMenu, setOpenMenu] = useState(null);

    const handleMenu = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const handleClose = () => {
        setOpenMenu(null);
    };
    const logOut = () => {
        serverLogout(user?.userid)
        localStorage.removeItem("token")
        localStorage.clear()
        dispatch(setLogout())
        window.location.href = '/loginpage'
        // setTimeout(() => {
        //     window.location.href = '/loginpage'
        // }, 3000)
    }

    const serverLogout = async (id) => {
        console.log(id);
        try {
            const response = await logout(id)
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    }

    const handleLogoutConfirmation = () => {
        const confirmation = window.confirm("Are you sure you want to logout?");
        if (confirmation) {
            logOut()
        } else {

        }
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ backgroundColor: 'white', boxShadow: 'none' }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        <img src={process.env.PUBLIC_URL + `/assets/images/rcorLogo.png`} alt='logo' height={37} />
                    </Typography>
                    <Box>
                        <span className='header title'>Welcome, <span className='header subtitle'>{user?.firstname}</span> </span>
                        <IconButton onClick={handleMenu}>
                            <Avatar alt="User" src={user?.photo ? `${process.env.REACT_APP_BASE_URL}${user.photo}` : process.env.PUBLIC_URL + `/assets/images/user_avatar.png`} sx={{ height: 25, width: 25 }} />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Menu
                id="menu-appbar"
                anchorEl={openMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(openMenu)}
                onClose={handleClose}
            >
                <MenuItem key='logout' onClick={() => { handleLogoutConfirmation() }} sx={{ fontSize: '14px' }}><PowerSettingsNewIcon sx={{ mr: '10px', fontSize: '18px' }} /> Logout</MenuItem>
            </Menu>
        </Box>
    )
}

export default Header