import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '../../redux/slices/authSlice';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Grid,
  Divider, FormControl, MenuItem, Select, Tooltip,
  TextField, Typography, Modal, IconButton, ListItemText, Checkbox, OutlinedInput, InputAdornment,
  Paper, Popper
} from '@mui/material';
import * as BranchApi from '../../api/branchApi'
import * as UserApi from '../../api/userApi'
import { getLedgerUser } from '../../api/ledgerApi';
import i18n from '../../i18n';
import AntSwitch from '../styles/AntSwitch';
import { validateForm } from './handleValidation';
import EditIcon from '@mui/icons-material/Edit';
import Autocomplete from '@mui/material/Autocomplete';
import BranchForm from './BranchForm';
import UploadImage from '../../pages/Settings/CompanySettings/UploadImage';
import DateCalander from '../DateCalander';
import dayjs from 'dayjs';
import { dateConvert } from '../../HelperFile';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import { handleApiErrors } from '../../api/HandleApiErrors';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSignalAbort } from '../../api/useSignalAbort';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CustomPopper = (props) => {
  return <Popper {...props} placement="bottom-start" />;
};

const CustomPaper = (props) => {
  return (
    <Paper {...props}>
      {props.children}
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
        <Button
          variant="contained"
          // className="btn btn-search"
          size="small"
        // onClick={() => setLedgerOpen(false)}
        >
          Done
        </Button>
      </div>
    </Paper>
  );
};

function UserForm({ isEdit, userId, onUserCreated, onUserUpdated, onCancel, onBoarding, goToPreviousStep }) {
  const { t } = useTranslation();
  const abortConstants = useSignalAbort()
  const token = useSelector(state => state.token)
  const loggedUser = useSelector(state => state.user)
  const dispatch = useDispatch()

  const editmodal = (branchid, branchname) => {
    setBranchId(branchid);
    handleBrOpen()
  };

  const userValues = {
    firstname: "",
    lastname: "",
    address: "",
    code: "",
    usertypeid: "",
    gender: "",
    email: "",
    branches: "",
    mobilenumber: "",
    username: "",
    password: "",
    designationid: "",
    doj: "",
    bod: "",
    dor: "",
    martialstatus: "",
    createdby: "",
    // Role: "",
    CompanyId: loggedUser?.companyid,
    isactive: 1,
    ledgerGroup: '' ////////////////////////////////////
  }

  const validationRules = {
    firstname: { required: true },
    // lastname: { required: true },
    // code: { required: true },
    usertypeid: { required: true },
    // email: { required: true },
    branches: { required: true },
    mobilenumber: { isPhoneNumber: true },
    username: { required: isEdit ? false : true },
    password: { isPassword: true, required: isEdit ? false : true },
    // designationid: { required: true },
    branchid: { required: true },
    ledgerGroup: { required: true },
  };

  const [userData, setuserData] = useState(userValues)
  const [formErrors, setFormerrors] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [roleSelect, setRoleSelect] = useState('')
  const [branchSelect, setbranchSelect] = useState([]);
  const [defaultbranchSelect, setbranchdefaultSelect] = useState([]);
  const [desigSelect, setDesigSelect] = useState('')
  const [roleList, setRoleList] = useState()
  const [branchList, setBranchList] = useState()
  const [desigList, setDesigList] = useState()
  const [open, setOpen] = useState(false);
  const [openBr, setOpenBr] = useState(false);
  const [ledgergroupSelect, setLedgerGroupSelect] = useState([]);    ///////////////////////////////////
  const [ledgergroupList, setLedgerGroupList] = useState([]);    ////////////////////////////////////
  const [BranchId, setBranchId] = useState()
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImageBase64, setSelectedImageBase64] = useState("");
  const [expiryDate, setExpiryDate] = useState(dayjs(new Date()).add(1, 'day'))
  const [viewPassword, setViewPassword] = useState(false)
  const [isLoading, setIsLoading] = useState({})
  const [branchesOpen, setBranchesOpen] = useState(false);

  //for add branch modal
  const handleOpen = (event) => {
    event.stopPropagation();
    setOpen(true)
  };

  const handleClose = () => {
    setOpen(false)
  };
  const handleBrOpen = () => {
    // event.stopPropagation();
    setOpenBr(true)
  };
  const handleBrClose = () => {
    setOpenBr(false)
  };
  const getRole = async () => {
    try {
      const result = await UserApi.getUserType(token)
      setRoleList(result.data.UserType)
    }
    catch (error) {
      console.log(error);
    }
  }

  const getBranch = async () => {
    try {
      const result = await BranchApi.getBranch(token)
      setBranchList(result.data.branch)
      // if(onBoarding){
      //   setbranchSelect(result.data.branch?.map(item=>item.branchid))
      // }
    }
    catch (error) {
      console.log(error);
    }
  }

  const getDesignation = async () => {
    try {
      const result = await UserApi.getDesignation(token)
      setDesigList(result.data.designation)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getLedgerGroup = async (branchid) => {
    console.log(branchid);
    try {
      const result = await getLedgerUser(branchid)
      // const ledgerName = [...new Set(   (result?.data?.LedgerGroup)     .filter((item) => item.IsReserved === 'Yes')     .map((element) => element.ReservedName) )];
      console.log("ledger", result);
      setLedgerGroupList(result?.data?.LedgerGroup.map(item => item.GroupName))
      if (!onBoarding && !isEdit) {
        setLedgerGroupSelect(result?.data?.LedgerGroup.map(item => item.GroupName))
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  //////////////////////////////////

  useEffect(() => {
    getBranch()
    getRole()
    getDesignation()
    getLedgerGroup(0)   ///////////////////////////////////////
  }, [])

  useEffect(() => {
    if (isEdit && onBoarding && branchList && ledgergroupList && roleList) {
      //to pass row data to edit modal from datatgrid
      const getUser = async () => {
        let user
        try {
          const result = await UserApi.getUserById(userId, token)
          console.log(result);
          user = result?.data?.User
          setbranchSelect(branchList?.map(item => item.branchid))
          setLedgerGroupSelect(ledgergroupList)
          setRoleSelect(user?.usertypeid)
          setuserData({
            ...userData,
            firstname: user?.firstname,
            lastname: user?.lastname,
            code: user?.code,
            email: user?.email,
            mobilenumber: user?.mobilenumber?.substring(1),
            username: user?.usertypename,
            isactive: user?.isactive
          });
        }
        catch (error) {
          console.log(error);
        }
      }
      getUser()
    }

  }, [userId, branchList, ledgergroupList])

  useEffect(() => {
    if (isEdit && !onBoarding) {
      //to pass row data to edit modal from datatgrid
      const getUser = async () => {
        let user
        try {
          const result = await UserApi.getUserById(userId, token)
          console.log(result);
          user = result?.data?.User
          // getLedgerGroup(result?.data?.User.branches)
          // getLedgerGroup()
          const brArray = result?.data?.User.branches.split(",").map(Number);
          const ledgerGroupString = result?.data?.User.ledgerGroup;
          const ledgerArray = (ledgerGroupString && ledgerGroupString.length !== 0) ? ledgerGroupString.split(",") : []
          setbranchSelect(brArray)
          setLedgerGroupSelect(ledgerArray)
          setuserData({
            ...userData,
            firstname: user?.firstname,
            lastname: user?.lastname,
            code: user?.code,
            email: user?.email,
            mobilenumber: user?.mobilenumber,
            username: user?.usertypename,
            isactive: user?.isactive
          });
          setSelectedImage(user.photo ? `${process.env.REACT_APP_BASE_URL}${user.photo}` : "")
        }
        catch (error) {
          console.log(error);
        }
        // setbranchSelect(user?.branchid)
        setbranchdefaultSelect(user?.branchid)
        setDesigSelect(user?.designationid)
        setRoleSelect(user?.usertypeid)
        setExpiryDate(dayjs(user?.ExpiredOn))
        // setLedgerGroupSelect(user?.ledgergroupId)     ///////////////////////////////////////////
      }
      getUser()
    }

  }, [userId])

  const customizeErrorMessages = (errors) => {
    const customErrors = {};
    Object.keys(errors).forEach(key => {
      if (key === 'ledgerGroup') {
        customErrors[key] = "Please select Ledger Group";
      } else if (key === 'branchid') {
        customErrors[key] = "Please select Default branch";
      } else {
        customErrors[key] = errors[key]; // Retain original error messages for other fields
      }
    });
    return customErrors;
  };

  //For form validation
  const validate = (values) => {
    const errors = validateForm(values, validationRules);
    const customizedError = customizeErrorMessages(errors)
    console.log(customizedError);
    setFormerrors(customizedError)
    if (Object.keys(customizedError).length === 0) {
      return true;
    }
    else {
      return false;
    }
  }

  //for add branch api call
  const createUser = async (data) => {
    console.log(data);
    try {
      //       setTimeout(()=>{
      // setIsSaving(false)
      //       }, 4000)
      const result = await UserApi.createUser(data, token)
      console.log(result);
      if (result) {
        onUserCreated(result?.data?.message)
        setIsSaving(false)
      }
    }
    catch (error) {
      setIsSaving(false)
      console.log(error);
      handleApiErrors(error, 'User form', 'adduser_response', 'adduser_timeout', 'adduser_request', setIsLoading)
    }
  }

  const updateUser = async (data) => {
    console.log(userId, data);
    try {
      const result = await UserApi.updateUser(userId, data, token)
      console.log(result);
      if (result) {
        setIsSaving(false)
        if (result?.data?.User && loggedUser.userid === result?.data?.User?.userid) {
          if (onBoarding) {
            dispatch(setUser({
              user: {
                ...loggedUser,
                firstname: result?.data?.User?.firstname,
                branchid: result?.data?.User?.branchid,
                branches: result?.data?.User?.branches,
                ledgerGroup: result?.data?.User?.ledgerGroup
              }
            }))
          } else {
            dispatch(setUser({
              user: {
                ...loggedUser,
                photo: result?.data?.User?.photo,
                firstname: result?.data?.User?.firstname,
                branchid: result?.data?.User?.branchid,
                companyid: result?.data?.User?.companyid,
                lastname: result?.data?.User?.lastname,
                code: result?.data?.User?.code,
                usertypeid: result?.data?.User?.usertypeid,
                email: result?.data?.User?.email,
                mobilenumber: result?.data?.User?.mobilenumber,
                username: result?.data?.User?.username,
                password: result?.data?.User?.password,
                isactive: result?.data?.User?.isactive,
                designationid: result?.data?.User?.designationid,
                branches: result?.data?.User?.branches,
                ExpiredOn: result?.data?.User?.ExpiredOn,
                ledgerGroup: result?.data?.User?.ledgerGroup
              }
            }))
          }
        }
        onUserUpdated(result?.data?.message)
      }
    }
    catch (error) {
      setIsSaving(false)
      console.log(error);
      handleApiErrors(error, 'User form', 'updateuser_response', 'updateuser_timeout', 'updateuser_request', setIsLoading)
    }
  }

  //To set input values for add branch 
  const handleChange = (e, event) => {
    setFormerrors({})
    if (e.target.name === 'mobilenumber') {
      // Allow only digits
      if (!/^\d*$/.test(e.target.value)) {
        setFormerrors({ ...formErrors, [e.target.name]: 'Please enter only digits' });
        return;
      }
    }
    setuserData({ ...userData, [e.target.name]: e.target.value },)
  }

  const handleActive = (e) => {
    setuserData({
      ...userData,
      isactive: e.target.checked ? 1 : 0
    });

    console.log("Isactive:", e.target.checked ? 1 : 0);
  }


  //form submission
  const handleSubmit = (e) => {
    e.preventDefault()
    setFormerrors({})
    setIsSaving(true)
    const data = {
      "branchid": Number(defaultbranchSelect),
      "companyid": loggedUser?.companyid,
      "firstname": userData.firstname,
      "lastname": userData.lastname,
      "address": "nil",
      "code": userData.code,
      "usertypeid": onBoarding ? roleList?.find(item => item.usertypename === 'Admin')?.usertypeid : Number(roleSelect),
      "gender": "n",
      "email": userData.email,
      "mobilenumber": userData.mobilenumber,
      "username": userData.username,
      "password": userData.password,
      "isactive": userData.isactive,
      "designationid": Number(desigSelect),
      "branches": branchSelect.join(','),
      "photo": selectedImageBase64,
      "ExpiredOn": roleList?.find(item => item.usertypeid === roleSelect)?.usertypename === 'Demo User' ? dateConvert(expiryDate) : null,
      "doj": "2023-04-05 21:23:59",
      "bod": "2023-04-26 21:23:59",
      "dor": "2023-04-19 21:23:59",
      "martialstatus": "n",
      "ledgerGroup": ledgergroupSelect.join(',')
      // ...(roleList?.find(item => item.usertypeid === roleSelect)?.usertypename === 'Demo User' && { ExpiredOn: dateConvert(expiryDate) })   //////////////////////////////////
    }
    console.log(data);
    if (onBoarding) {
      updateUser(data)
    } else {
      if (validate(data)) {
        if (isEdit) {
          updateUser(data)
        }
        else {
          createUser(data)
        }
      }
      else {
        setIsSaving(false)
      }
    }
  }

  const cancelForm = () => {
    console.log("inside useform");
    setFormerrors({})
    onCancel(isEdit)
  }

  const handleRoleSelect = (e) => {
    setRoleSelect(e.target.value);
  };

  const handleBranchSelect = (event) => {
    const { target: { value } } = event;
    const branch = typeof value === 'string' ? value.split(',') : value
    setbranchSelect(branch);
    // getLedgerGroup(branch.join(','))
  };

  const handledefaultbranchSelect = (event) => {
    setbranchdefaultSelect(event.target.value)
  };


  const handleledgergroupSelect = (event) => {
    const { target: { value }, } = event;
    console.log(event.target.value);
    setLedgerGroupSelect(typeof value === 'string' ? value.split(',') : value);
  };


  const handleDesigSelect = (e) => {
    setDesigSelect(e.target.value);
  };

  const branchCreate = (newBranch) => {
    // Update branchList immutably
    setBranchList(prevBranchList => [...prevBranchList, newBranch]);
    // Update branchSelect immutably
    setbranchSelect(prevBranchSelect => [...prevBranchSelect, newBranch.branchid])
    handleClose()
    console.log(newBranch);
  };

  const branchUpdate = (newBranch) => {
    if (newBranch.branchid === branchSelect) {
      setBranchList((prevBranchList) =>
        prevBranchList.map((item) => (item.branchid === newBranch.branchid ? newBranch : item))
      );
    } else {
      setBranchList([...branchList, newBranch]);
    }
    handleBrClose()
    console.log(newBranch);
  };

  const cancelOperation = (edit) => {
    if (edit) {
      handleBrClose()
    }
    else {
      handleClose()
    }
  };

  return (
    <div className="form-box" dir={i18n.dir()}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {isEdit && !onBoarding && (
            <Typography className="form heading">
              {t("form.editUser")}
            </Typography>
          )}
          {!isEdit && !onBoarding && (
            <Typography className="form heading">
              {t("form.newUser")}
            </Typography>
          )}
          {isEdit && onBoarding && (
            <Typography className="form-onboarding title" mb='20px'>
              Set up your user profile
            </Typography>
          )}
          {/* <Divider color="#DADADA" /> */}
        </Grid>
        <Grid item xs={12}>
          <Box mt={1} maxHeight='500px' overflow='auto'>
            <form onSubmit={handleSubmit}>
              <Box>
                {!onBoarding && <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={4} className='grid center'>
                        <Typography className='span form-text'>{t("form.firstName")}<span className="span asterisk">*</span></Typography>
                      </Grid>
                      <Grid item xs={12} md={8} className='grid center'>
                        <TextField
                          fullWidth
                          size="small"
                          // label={t('form.UserName')}
                          variant="outlined"
                          required
                          name="firstname"
                          error={!!formErrors.firstname}
                          value={userData.firstname}
                          onChange={handleChange}
                        />
                        {formErrors.firstname && (
                          <span style={{ color: "red" }}>
                            {formErrors.firstname}
                          </span>
                        )}
                      </Grid>
                      <Grid item xs={12} md={4} className='grid center'>
                        <Typography className='span form-text'>{t("form.lastName")}</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} className='grid center'>
                        <TextField
                          fullWidth
                          size="small"
                          // label={t('form.branchCode')}
                          variant="outlined"
                          name="lastname"
                          value={userData.lastname}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={4} className='grid center'>
                        <Typography className='span form-text'>{t("form.branchCode")}</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} className='grid center'>
                        <TextField
                          fullWidth
                          size="small"
                          // label={t('form.city')}
                          variant="outlined"
                          type="code"
                          name="code"
                          value={userData.code}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={4} className='grid center'>
                        <Typography className='span form-text'>Photo</Typography>
                      </Grid>
                      <Grid item xs={12} md={8} >
                        <Box
                          sx={{
                            borderRadius: "5px",
                            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.3)",
                            // width: "220px",
                            height: "135px", // adjust the height to match the width
                            // ml: "25px",
                            // position: "relative",
                          }}
                        >
                          <UploadImage selectedImage={selectedImage} setSelectedImage={setSelectedImage} setSelectedImageBase64={setSelectedImageBase64} />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} mt={1}>
                  <Divider color="#DADADA"/>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>

                      {!isEdit &&
                        <>
                          <Grid item xs={12} md={2} className='grid center'>
                            <Typography className='span form-text'>{t("form.Username")}<span className="span asterisk">*</span></Typography>
                          </Grid>
                          <Grid item xs={12} md={4} className='grid center'>
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              required
                              name="username"
                              value={userData.username}
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} className='grid center'>
                            <Typography className='span form-text'>{t("form.Password")}<span className="span asterisk">*</span></Typography>
                          </Grid>
                          <Grid item xs={12} md={4} className='grid center'>
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              required
                              name="password"
                              type={viewPassword ? 'text' : 'password'}
                              InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() => setViewPassword(!viewPassword)}
                                    // onMouseDown={handleMouseDownPassword}
                                    >
                                      {viewPassword ? <VisibilityOff className="icon style-i" /> : <Visibility className="icon style-i" />}
                                    </IconButton>
                                  </InputAdornment>),
                              }}
                              value={userData.password}
                              onChange={handleChange}
                            />
                          </Grid>
                        </>
                      }

                      <Grid item xs={12} md={2} className='grid center'>
                        <Typography className='span form-text'>{t("form.phone")}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4} className='grid center'>
                        <Box className='' display='flex' flexDirection='column' width='100%'>
                          <TextField
                            fullWidth
                            size="small"
                            // label={errMsgPhone ? formErrors.phone : t('form.phone')}
                            variant="outlined"
                            name="mobilenumber"
                            // color={errMsgmobilenumber? 'error' : 'primary'}
                            error={!!formErrors.mobilenumber}
                            value={userData.mobilenumber}
                            // error={errMsgmobilenumber}
                            onChange={handleChange}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                          />
                          {formErrors.mobilenumber && (
                            <Typography color="error">
                              {formErrors.mobilenumber}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={2} className='grid center'>
                        <Typography className='span form-text'>{t("form.email")}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4} className='grid center'>
                        <TextField
                          fullWidth
                          size="small"
                          // label={t('form.email')}
                          variant="outlined"
                          type='email'
                          name="email"
                          value={userData.email}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={2} className='grid center'>
                        <Typography className='span form-text'>{t("form.role")}<span className="span asterisk">*</span></Typography>
                      </Grid>
                      <Grid item xs={12} md={4} className='grid center'>
                        <FormControl required size="small" fullWidth>
                          <Autocomplete
                            size="small"
                            options={roleList || []}
                            getOptionLabel={(option) => option?.usertypename || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Select Role"
                                variant="outlined"
                                required
                                inputProps={{
                                  ...params.inputProps,
                                  "aria-label": "Without label",
                                }}
                              />
                            )}
                            value={
                              roleList
                                ? roleList.find(
                                  (item) => item.usertypeid === roleSelect
                                ) || null
                                : null
                            }
                            onChange={(event, newValue) =>
                              handleRoleSelect({
                                target: { value: newValue?.usertypeid || "" },
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      {roleList?.find(item => item.usertypeid === roleSelect)?.usertypename === 'Demo User' &&
                        <>
                          <Grid item xs={12} md={2} className='grid center'>
                            <Typography className='span form-text'>Account Expiry</Typography>
                          </Grid>
                          <Grid item xs={12} md={4} className='grid center'>
                            <DateCalander value={dayjs(expiryDate)} setValue={setExpiryDate} frmDate={dayjs(new Date()).add(1, 'day')} />
                          </Grid>
                        </>
                      }
                      <Grid item xs={12} md={2} className='grid center'>
                        <Typography className='span form-text'>{t("form.designation")}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4} className='grid center'>
                        <FormControl size="small" fullWidth>
                          <Select
                            size="small"
                            fullWidth
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            id="demo-simple-select-filled"
                            name="designationid"
                            value={desigSelect}
                            onChange={handleDesigSelect}
                          >
                            {desigList?.map((item) => (
                              <MenuItem
                                key={item.designationid}
                                name={item.designationname}
                                value={item.designationid}
                              >
                                {item.designationname}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} className='grid center'>
                        <Typography className='span form-text'>{t("form.Defaultbranch")}<span className="span asterisk">*</span></Typography>
                      </Grid>
                      <Grid item xs={12} md={4} className='grid center'>
                        <Box className='' display='flex' flexDirection='column' width='100%'>
                          <FormControl required size="small" fullWidth>
                            <Select
                              size="small"
                              fullWidth
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              id="demo-simple-select-filled"
                              name="branchid"
                              error={!!formErrors?.branchid}
                              value={defaultbranchSelect}
                              onChange={handledefaultbranchSelect}
                            >
                              {branchList?.map((item) => (
                                <MenuItem
                                  key={item.branchid}
                                  name={item.branchname}
                                  value={item.branchid}
                                >{item.branchname}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {formErrors.branchid && (
                            <span style={{ color: "red" }}>
                              {formErrors.branchid}
                            </span>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={2} className='grid center'>
                        <Typography className='span form-text'>{t("form.branches")}<span className="span asterisk">*</span></Typography>
                      </Grid>
                      <Grid item xs={12} md={roleList?.find(item => item.usertypeid === roleSelect)?.usertypename === 'Demo User' ? 10 : 4} className='grid center'>
                        <FormControl required size="small" fullWidth>
                          <Select
                            size="small"
                            fullWidth
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            id="demo-multiple-checkbox"
                            multiple
                            name="branchid"
                            value={branchSelect}
                            open={branchesOpen}
                            onOpen={() => setBranchesOpen(true)}
                            onClose={() => setBranchesOpen(false)}
                            onChange={handleBranchSelect}
                            input={<OutlinedInput />}
                            renderValue={(selected) =>
                              selected
                                .map(
                                  (id) =>
                                    branchList?.find(
                                      (branch) => branch.branchid === id
                                    )?.branchname
                                )
                                .filter(Boolean)
                                .join(", ")
                            }
                          >
                            {branchList?.map((item) => (
                              <MenuItem
                                key={item.branchid}
                                name={item.branchname}
                                value={item.branchid}
                              >
                                <Checkbox checked={branchSelect.indexOf(item.branchid) > -1} />
                                <ListItemText primary={item.branchname} />
                                {branchSelect.length === 1 && branchSelect.includes(item.branchid) && (
                                  <Tooltip title="Edit">
                                    <IconButton
                                      sx={{ color: "#1F77B4" }}
                                      size="small"
                                      onClick={() => {
                                        editmodal(item.branchid);
                                      }}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}

                              </MenuItem>
                            ))}
                            <Box className='box space'>
                              <Button
                                type="submit"
                                className="btn btn-u"
                                size="small"
                                onClick={(e) => handleOpen(e)}
                              >
                                Add Branch
                              </Button>
                              <ListItemText sx={{ display: 'flex', justifyContent: "flex-end", mr: 1 }}>
                                <Button
                                  type="submit"
                                  variant='contained' size="small"
                                  onClick={(e) => {
                                    e.stopPropagation(); // Prevent the Select from closing when button is clicked
                                    setBranchesOpen(false) // Open the dropdown menu again after the button is clicked
                                  }}
                                >
                                  Done
                                </Button>
                              </ListItemText>
                            </Box>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={2} className='grid center'>
                        <Typography className='span form-text'>{t("form.ledgerGroups")}<span className="span asterisk">*</span></Typography>
                      </Grid>
                      <Grid item xs={12} md={10} className='grid center'>
                        <Box className='' display='flex' flexDirection='column' width='100%'>
                          <Autocomplete
                            style={{ maxHeight: '100px', overflow: 'auto' }}
                            multiple
                            required
                            id="demo-multiple-checkbox"
                            options={ledgergroupList}
                            disableCloseOnSelect
                            // open={ledgerOpen}
                            // onOpen={() => setLedgerOpen(true)}
                            // onClose={() => setLedgerOpen(false)}
                            PopperComponent={CustomPopper}
                            PaperComponent={CustomPaper}
                            value={ledgergroupSelect}
                            onChange={(_, newValue) =>
                              handleledgergroupSelect({
                                target: { value: newValue },
                              })
                            }
                            getOptionLabel={(option) => option}
                            renderOption={(props, option, { selected }) => {
                              const { key, ...optionProps } = props;
                              return (
                                <li key={key} {...optionProps}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              );
                            }}
                            // renderOption={(props, option, { selected }) => (
                            //   <MenuItem {...props}>
                            //     <Checkbox checked={selected} />
                            //     <ListItemText primary={option} />
                            //   </MenuItem>
                            // )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                // label={t("form.ledgerGroup")}
                                size="small"
                              />
                            )}
                          />
                          {formErrors.ledgerGroup && (
                            <span style={{ color: "red" }}>
                              {formErrors.ledgerGroup}
                            </span>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={2} className='grid center'>
                        <Typography className='span form-text'>{t("form.active")}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4} className='grid center'>
                        <AntSwitch
                          inputProps={{ "aria-label": "ant design" }}
                          checked={userData.isactive === 1}
                          onChange={handleActive}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>}
                {onBoarding &&
                  <Grid container spacing={1} columnSpacing={3}>
                    <Grid item xs={12} md={3} className='grid center'>
                      <Typography className='span form-text'>{t("form.firstName")}<span className="span asterisk">*</span></Typography>
                    </Grid>
                    <Grid item xs={12} md={9} className='grid center'>
                      <TextField
                        fullWidth
                        size="small"
                        // label={t('form.UserName')}
                        variant="outlined"
                        required
                        name="firstname"
                        error={!!formErrors.firstname}
                        value={userData.firstname}
                        onChange={handleChange}
                      />
                      {formErrors.firstname && (
                        <span style={{ color: "red" }}>
                          {formErrors.firstname}
                        </span>
                      )}
                    </Grid>

                    <Grid item xs={12} md={3} className='grid center'>
                      <Typography className='span form-text'>{t("form.Defaultbranch")}<span className="span asterisk">*</span></Typography>
                    </Grid>
                    <Grid item xs={12} md={9} className='grid center'>
                      <Box className='' display='flex' flexDirection='column' width='100%'>
                        <FormControl required size="small" fullWidth>
                          <Select
                            size="small"
                            fullWidth
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            id="demo-simple-select-filled"
                            name="branchid"
                            error={!!formErrors?.branchid}
                            value={defaultbranchSelect}
                            onChange={handledefaultbranchSelect}
                          >
                            {branchList?.map((item) => (
                              <MenuItem
                                key={item.branchid}
                                name={item.branchname}
                                value={item.branchid}
                              >{item.branchname}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {formErrors.branchid && (
                          <span style={{ color: "red" }}>
                            {formErrors.branchid}
                          </span>
                        )}
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} md={3} className='grid center'>
                          <Typography className='span form-text'>{t("form.branches")}</Typography>
                        </Grid>
                        <Grid item xs={12} md={9} className='grid center'>
                          <FormControl required size="small" fullWidth>
                            <Select
                              size="small"
                              fullWidth
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              id="demo-multiple-checkbox"
                              multiple
                              name="branchid"
                              value={branchSelect}
                              open={branchesOpen}
                              onOpen={() => setBranchesOpen(true)}
                              onClose={() => setBranchesOpen(false)}
                              onChange={handleBranchSelect}
                              input={<OutlinedInput />}
                              renderValue={(selected) =>
                                selected
                                  .map(
                                    (id) =>
                                      branchList?.find(
                                        (branch) => branch.branchid === id
                                      )?.branchname
                                  )
                                  .filter(Boolean)
                                  .join(", ")
                              }
                            >
                              {branchList?.map((item) => (
                                <MenuItem
                                  key={item.branchid}
                                  name={item.branchname}
                                  value={item.branchid}
                                >
                                  <Checkbox checked={branchSelect.indexOf(item.branchid) > -1} />
                                  <ListItemText primary={item.branchname} />
                                  {branchSelect.length === 1 && branchSelect.includes(item.branchid) && (
                                    <Tooltip title="Edit">
                                      <IconButton
                                        sx={{ color: "#1F77B4" }}
                                        size="small"
                                        onClick={() => {
                                          editmodal(item.branchid);
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}

                                </MenuItem>
                              ))}
                              <Box className='box space'>
                                <Button
                                  type="submit"
                                  className="btn btn-u"
                                  size="small"
                                  onClick={(e) => handleOpen(e)}
                                >
                                  Add Branch
                                </Button>
                                <ListItemText sx={{ display: 'flex', justifyContent: "flex-end", mr: 1 }}>
                                  <Button
                                    type="submit"
                                    variant='contained' size="small"
                                    onClick={(e) => {
                                      e.stopPropagation(); // Prevent the Select from closing when button is clicked
                                      setBranchesOpen(false) // Open the dropdown menu again after the button is clicked
                                    }}
                                  >
                                    Done
                                  </Button>
                                </ListItemText>
                              </Box>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} className='grid center'>
                          <Typography className='span form-text'>{t("form.ledgerGroups")}</Typography>
                        </Grid>
                        <Grid item xs={12} md={9} className='grid center'>
                          <Box className='' display='flex' flexDirection='column' width='100%'>
                            <FormControl required size="small" fullWidth>
                              <Autocomplete
                                style={{ maxHeight: '100px', overflow: 'auto' }}
                                multiple
                                required
                                id="demo-multiple-checkbox"
                                options={ledgergroupList}
                                disableCloseOnSelect
                                PopperComponent={CustomPopper}
                                PaperComponent={CustomPaper}
                                value={ledgergroupSelect}
                                onChange={(_, newValue) =>
                                  handleledgergroupSelect({
                                    target: { value: newValue },
                                  })
                                }
                                getOptionLabel={(option) => option}
                                renderOption={(props, option, { selected }) => {
                                  const { key, ...optionProps } = props;
                                  return (
                                    <li key={key} {...optionProps}>
                                      <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                      />
                                      {option}
                                    </li>
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    // label={t("form.ledgerGroup")}
                                    size="small"
                                  />
                                )}
                              />
                            </FormControl>
                            {formErrors.ledgerGroup && (
                              <span style={{ color: "red" }}>
                                {formErrors.ledgerGroup}
                              </span>
                            )}
                          </Box>
                        </Grid> */}
                    <Grid item xs={12}>
                      <div className="form-button-div">
                        {/* {!isEdit && !onBoarding && !isSaving && (
                              <Button type="submit" className="btn btn-update">
                                {t("form.add")}
                              </Button>
                            )} */}
                        {(isEdit || onBoarding) && !isSaving && (
                          <Button type="submit" className="btn btn-update">
                            {/* {t("form.edit")} */}
                            Save
                          </Button>
                        )}
                        {isSaving && (
                          <Button className="btn btn-save">
                            <span className="btn-text">{t("form.saving")}</span>
                            <div className="spinner"></div>
                          </Button>
                        )}
                        {<Button
                          className="btn btn-delete"
                          onClick={() => goToPreviousStep()}
                        >
                          Back
                        </Button>}
                        {/* <Button
                            type="submit"
                              className="btn btn-delete"
                              onClick={() => goToPreviousStep()}
                            >
                              Skip
                            </Button> */}
                      </div>
                    </Grid>
                  </Grid>}
                {!onBoarding && <div className="form-button-div">
                  {!isEdit && !onBoarding && !isSaving && (
                    <Button type="submit" className="btn btn-update">
                      {t("form.add")}
                    </Button>
                  )}
                  {(isEdit || onBoarding) && !isSaving && (
                    <Button type="submit" className="btn btn-update">
                      {t("form.edit")}
                    </Button>
                  )}
                  {isSaving && (
                    <Button className="btn btn-save">
                      <span className="btn-text">{t("form.saving")}</span>
                      <div className="spinner"></div>
                    </Button>
                  )}
                  <Button
                    className="btn btn-delete"
                    onClick={() => cancelForm()}
                  >
                    {t("form.cancel")}
                  </Button>
                </div>}
              </Box>
            </form>
          </Box>
          <Modal
            open={open}
            // onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={style}>
              <BranchForm
                onBranchCreated={branchCreate}
                onCancel={cancelOperation}
              />
            </Box>
          </Modal>

          <Modal
            open={openBr}
            // onClose={handleBrClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
          >
            <Box sx={style}>
              <BranchForm
                isEdit
                branchId={BranchId}
                onBranchUpdated={branchUpdate}
                onCancel={cancelOperation}
              />
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </div>
  );
}

export default UserForm