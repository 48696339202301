import React from 'react'
import Header from './Header'
import CompanySettings from '../pages/Settings/CompanySettings/CompanySettings'
import { Box} from '@mui/material'

function CompanyProfile({getOnboardingStep, goToNextStep}) {
    return (
        <Box>
            <Header />
            <Box sx={{mt:'100px', ml: '150px', mr: '150px', backgroundColor:'white'}}>
               <Box>
               <CompanySettings onBoarding={true} getOnboardingStep ={getOnboardingStep} goToNextStep={goToNextStep}/>
               </Box>
            </Box>
        </Box>
    )
}

export default CompanyProfile