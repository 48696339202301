import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, FunnelChart, Funnel, LabelList } from 'recharts';
import { useSelector } from 'react-redux';
import { graphCurrencyFormat, changeCurrencyFormat, currencyFormatWithDecimal } from '../../HelperFile';

const ProfitLossChart = ({ chartData, back }) => {
    const user = useSelector(state => state.user)
    const company = user?.company
    const yValue = user?.company?.currencyName
    // const [data, setData] = useState()

    // useEffect(() => {
    //     // const newArray = chartData?.map(item => ({
    //     //   ...item,
    //     //   value: changeCurrencyFormat(item.value, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL"),
    //     // }));
    //     // console.log(newArray);
    //     const newArray = chartData.sort((a, b) => b.value - a.value)
    //     setData(newArray)
    // }, [chartData])

    const tooltipFormatter = (value, name) => {
        // Customize the tooltip content here
        return [
            <span style={{ fontSize: '9px' }}>{`${name}: ${changeCurrencyFormat(value, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}`}</span>
        ];
    };

    const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
        return <text
            x={x + width + 4} // Adjust the x position to position the label to the right of the bar
            y={y + height / 2} // Adjust the y position to center the label vertically
            fontSize={10}
            fontWeight={400}
            fill="black"
            textAnchor="start" // Align the text to the start of the label
            dy={4} // Adjust the vertical offset of the label if needed
        >
            {currencyFormatWithDecimal(value, company?.currencyCode)}
        </text>;
    };

    //change title to receivables and payables
    const changeTitle = (title) => {
        if (title === "Sundry Debtors") {
            return "Receivable"
        } else if (title === "Sundry Creditors") {
            return "Payable"
        } else {
            return title
        }
    }

    return (
        <>
            {chartData &&
                <ResponsiveContainer width="100%" height='100%'>
                    <BarChart
                        data={chartData}
                        layout="vertical" // Set layout to "vertical" for a horizontal bar chart
                        margin={{ top: 30, right: 50, left: 20, bottom: 20 }}
                    >
                        <CartesianGrid stroke='#E6E6E6' strokeDasharray="5 5" />
                        <XAxis type='number' tick={{ fill: 'black' }} fontSize={10} fontWeight={400} axisLine={false} tickLine={false} tickFormatter={(value) => graphCurrencyFormat(value, company?.currencyCode)} />
                        <YAxis dataKey="name" type='category' tick={{ fill: 'black' }} axisLine={false} tickLine={false} fontSize={12} fontWeight={500} width={130} />
                        <Tooltip formatter={tooltipFormatter} />
                        <Bar
                            dataKey="value"
                            // shape={<CustomBar />}
                            radius={[0, 8, 8, 0]}
                            barSize={25}
                            label={renderCustomBarLabel}
                        >
                            {chartData.map((entry, index) => (
                                <React.Fragment key={index}>
                                    <Cell key={`cell-${index}`} fill={entry.fill} />
                                </React.Fragment>
                            ))}
                        </Bar>
                        {/* <Legend
              align="center"
              verticalAlign="bottom"
              wrapperStyle={{ fontSize: '12px', fontWeight: 400, color: 'black' }}
              content={() => (
                <div className='box center'>
                  <div className='box center' style={{ marginRight: '10px', marginTop: '20px' }}>
                    <div style={{ color: 'black' }}>{changeTitle(chartData[0]?.transactionType)} ( {yValue} )</div>
                  </div>
                </div>
              )}
            /> */}
                    </BarChart>
                      {/* <FunnelChart margin={{ top: 30, right: 30, left: 30 }}>
                        <Tooltip formatter={tooltipFormatter} />
                        <Funnel
                            dataKey="value"
                            data={data}
                            isAnimationActive
                        >
                            <LabelList position="right" fill="#000" stroke="none" dataKey="name" fontSize={10} />
                        </Funnel>
                    </FunnelChart> */}
                </ResponsiveContainer>
            }
        </>
    );
};

export default ProfitLossChart;
