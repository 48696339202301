import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography, CircularProgress, Modal, Tooltip, IconButton, TableFooter } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../components/styles/theme';
import dayjs from 'dayjs'
import { useSelector } from 'react-redux';
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from '../../api/HandleApiErrors';
import { useTranslation } from 'react-i18next';
import { getPermittedBranch } from '../../api/commonApi';
import { changeFormat, dateConvert, changeCurrencyFormat } from '../../HelperFile';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

import { getFyrByCompany } from '../../api/fyrApi';
import { getBalanceSheet, getProfitLoss, getTrialBalance } from '../../api/profitlossApi';
import ProfitLossHeader from '../../components/ProfitLossHeader';
import TrialDetailTable from './TrialDetailTable';
import AntSwitch from '../../components/styles/AntSwitch';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function BalanceSheet() {

    const { t } = useTranslation()
    const isBranchList = useRef(false)

    const initialLoading = useRef(false)
    const abortConstants = useSignalAbort()
    const token = useSelector(state => state.token)
    const user = useSelector(state => state.user)
    const companyId = user?.companyid
    const company = user?.company
    const isFyr = useRef(false);
    const [fyrList, setFyrList] = useState()
    const [fyrSelect, setFyrSelect] = useState('');
    const [fyrName, setFyrName] = useState()
    const [branchList, setBranchList] = useState()

    const [branchSelect, setbranchSelect] = useState(user?.branchid)
    const [branchSelectSearch, setbranchSelectSearch] = useState(user?.branchid)
    const [toDate, setTodate] = useState()
    const [selectedFyr, setSelectedFyr] = useState()
    const [frmDate, setFrmdate] = useState()
    const [frDate, setFrDate] = useState()
    const [tDate, setTDate] = useState()
    const [branchName, setBranchName] = useState()
    const [isLoading, setIsLoading] = useState({})
    const [groupSelect, setGroupSelect] = useState();
    const [Grouptype, setGrouptype] = useState()
    const [balanceData, setBalanceData] = useState()
    const [totalDebit, setTotalDebit] = useState()
    const [totalCredit, setTotalCredit] = useState()
    const [profitLoss, setProfitLoss] = useState()
    const [grossProfit, setGrossProfit] = useState()
    const [netProfit, setNetProfit] = useState()
    const [otherExpenses, setOtherExpenses] = useState()
    const [detailData, setDetailData] = useState()
    const excludedTypes = ['Fixed Assets', 'Current Assets'];
    const [path, setPath] = useState([]);
    const [open, setOpen] = useState(false);
    const [isDetail, setIsDetail] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        setDetailData(null)
    }

    //display message if default branch is not set to the user
    useEffect(() => {
        if (user && !user?.branchid) {
            toast.error("A default branch has not been assigned to you. Please contact your administrator", {
                autoClose: 3000, // Close the toast after 5 seconds
            });
        }
    }, [user])

    useEffect(() => {
        const fetchData = async () => {
            if (!token || !companyId || !user) return;

            if (!isFyr.current) {
                console.log("Fetching financial year data...");
                await getFyrByCriteria(companyId, abortConstants.controllerConfig);
                isFyr.current = true;
            }

            if (!isBranchList.current) {
                console.log("Fetching branch data...");
                await getBranch(user.userid, abortConstants.controllerConfig);
                isBranchList.current = true;
            }

            if (branchSelect && selectedFyr && !initialLoading.current) {
                console.log("Fetching all data...");
                await getAllData(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, 'Assets, Liabilities', abortConstants.controllerConfig)
                initialLoading.current = true;
            }
        };

        fetchData();

        return () => {
            console.log("Cleanup");
            abortConstants.controller.abort();
            toast.dismiss();
        };
    }, [token, companyId, user, branchSelect, selectedFyr]);

    const getBranch = async (userId, abortToken) => {
        console.log("inside branch latest");
        try {
            const result = await getPermittedBranch(userId, abortToken);//api call to get all branch
            console.log("dashbranch", result);
            isBranchList.current = true;
            if (result?.data?.branch.length !== 0) {
                setBranchList(result?.data?.branch);
                if (!user?.branchid) {
                    // Set branchSelect to the first branch ID if user does not have a branch ID
                    setbranchSelect(result?.data?.branch[0].branchid);
                    const brName = result.data?.branch[0].branchname;
                    setBranchName(brName);
                } else {
                    const brName = result.data?.branch
                        .filter(item => branchSelect === item.branchid)
                        .map(item => item.branchname);
                    setBranchName(brName);
                }
            }
            else {
                toast.error(result?.data?.message, {
                    autoClose: 3000, // Close the toast after 5 seconds
                });
            }
        } catch (error) {
            handleApiErrors(error, 'Branch', 'branch_response', 'branch_timeout', 'branch_request', setIsLoading)
        }
    }

    const getFyrByCriteria = async (id, abortToken) => {
        console.log("inside fyr", id);
        try {
            const result = await getFyrByCompany(id, abortToken)
            console.log("fyr", result);
            setFyrList(result.data.FinancialYear)
            isFyr.current = true
            let fYear = result.data.FinancialYear.find(item => item.Iscurrent === 1)
            setSelectedFyr(fYear)
            setFyrSelect(fYear?.Fyrname)
            setFyrName(fYear?.Fyrname)
            setFrmdate(fYear?.Fyrfrom)
            setTodate(fYear?.Fyrto)
        }
        catch (error) {
            handleApiErrors(error, 'Financial Year', 'fyr_response', 'fyr_timeout', 'fyr_request', setIsLoading)
        }
    }


    const getAllData = (branchId, frm, to, head, abortToken) => {
        setFrDate(changeFormat(dayjs(frm), user?.company?.DateFormatname))//change the format of from date
        setTDate(changeFormat(dayjs(to), user?.company?.DateFormatname))//change the format of to date
        getBalaceData(branchId, frm, to, head, abortToken)
    }

    //profitloss  
    const getBalaceData = async (branchId, frm, to, head, abortToken) => {
        console.log(branchId, frm, to, head);
        try {
            //should be revised if error comes
            const result = await getBalanceSheet(branchId, frm, to, head, abortToken);
            console.log(result);
            const newData = result?.data?.BalanceSheet
            // const newData = result?.data?.BalanceSheet?.map((row) => ({
            //     ...row,
            //     Debit: row.value > 0 ? row.value : '',
            //     Credit: row.value < 0 ? row.value : ''
            // }));
            // const liabilities = newData?.filter(item => !excludedTypes.includes(item.transactionType))
            setBalanceData(newData)
            const totDebit = result?.data?.BalanceSheet?.filter(item => !excludedTypes.includes(item.transactionType))?.reduce((total, item) => {
                return total + (Number(item.value) || 0);
            }, 0)
            setTotalDebit(totDebit)
            const totCredit = result?.data?.BalanceSheet?.filter(item => excludedTypes.includes(item.transactionType))?.reduce((total, item) => {
                return total + (Number(item.value) || 0);
            }, 0)
            setTotalCredit(totCredit)
            // setProfitLoss(newData?.find(item => item.transactionType === 'Profit & Loss A/c'))
        }
        catch (error) {
            handleApiErrors(error, 'Profit & Loss', 'profitloss_response', 'profitloss_timeout', 'profitloss_request', setIsLoading)
        }
    }

    const getBalanceDiggedData = async (branchId, frm, to, head, abortToken) => {
        console.log(branchId, frm, to, head);
        try {
            //should be revised if error comes
            const result = await getBalanceSheet(branchId, frm, to, head, abortToken);
            console.log(result);
            setDetailData(result?.data?.BalanceSheet)

        }
        catch (error) {
            handleApiErrors(error, 'Profit & Loss', 'profitloss_response', 'profitloss_timeout', 'profitloss_request', setIsLoading)
        }
    }

    //for search
    const handleSearchClick = (branchId, frm, to) => {
        console.log(branchId, frm, to);
        toast.dismiss()
        setBalanceData(null)
        setIsLoading({})
        getAllData(branchId, frm, to, 'Assets, Liabilities', abortConstants.controllerConfig)
    }

    //for loading circular progress
    const renderLoading = (item) => {
        if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`] ||
            isLoading.branch_response || isLoading.branch_request || isLoading.branch_timeout) {
            return true
        }
        else {
            return false
        }
    }

    // to disable/enable search button
    const isLoadingTrue = () => {
        const state = (Object.keys(isLoading).length === 0) && (!balanceData)
        return state
    }

    const exportToExcel = () => {
        // Extract only the required fields from inventoryStock
        const dataToExport = balanceData.map(item => ({
            'Particulars': item.transactionType,
            '': changeCurrencyFormat(item.Debit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL'),
            '': changeCurrencyFormat(item.Credit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL'),
        }));

        // Append Total Stock Value as a row to dataToExport
        dataToExport.push({
            'Particulars': 'Grand Total',
            'Debit': changeCurrencyFormat(totalDebit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL'),
            'Credit': changeCurrencyFormat(totalCredit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')
        });


        const ws = XLSX.utils.json_to_sheet(dataToExport);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'Balance Sheet.xlsx');
    };

    const getCurrencyFormat = (value) => {
        return changeCurrencyFormat(value, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")
    }

    const getDetail = (head) => {
        setDetailData(null)
        handleOpen()
        setPath([head])
        getBalanceDiggedData(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, head, abortConstants.controllerConfig)
    }

    const getDiggedDetail = (head) => {
        setDetailData(null)
        getBalanceDiggedData(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, head, abortConstants.controllerConfig)
    }

    const close = () => {
        if (detailData || renderLoading('profitloss') === true) {
            handleClose()
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Grid container >
                    {/* header */}
                    <Grid item xs={12}>
                        {branchList && fyrList && (
                            <ProfitLossHeader
                                branchName={branchName}
                                frDate={frDate}
                                tDate={tDate}
                                isLoadingTrue={isLoadingTrue}
                                branchSelect={branchSelect}
                                setbranchSelect={setbranchSelect}
                                branchList={branchList}
                                handleSearch={handleSearchClick}
                                setbranchSelectSearch={setbranchSelectSearch}
                                setBranchName={setBranchName}
                                frmDate={frmDate}
                                toDate={toDate}
                                setFrmdate={setFrmdate}
                                setTodate={setTodate}
                                fyrSelect={fyrSelect}
                                setFyrSelect={setFyrSelect}
                                fyrList={fyrList}
                                setFyrName={setFyrName}
                                selectedFyr={selectedFyr}
                                setSelectedFyr={setSelectedFyr}
                                optionTitle="Group selection"
                                optionList={Grouptype}
                                optionSelect={groupSelect}
                                setOptionSelect={setGroupSelect}
                                optionKey="ProductGroupName"
                                exportExcel={exportToExcel}
                                companyName={company.companyname}
                                title="Balance Sheet"
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} display='flex' justifyContent='end'>
                        <Box className='box flex' >
                            <Typography className='typography-switch size'>Vertical</Typography>&nbsp;&nbsp;
                            <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={isDetail} onChange={() => setIsDetail(!isDetail)} />&nbsp;&nbsp;
                            <Typography className='typography-switch size'>Horizontal</Typography>
                        </Box>
                    </Grid>
                    {!isDetail && <Grid item xs={12} mt={2}>
                        <Box>
                            {/* from here renderLoading */}
                            {balanceData ? (<>{balanceData?.length !== 0 ?
                                <Box display='flex' justifyContent='center' mb={2}>
                                    <TableContainer component={Paper} sx={{ maxHeight: 500, position: 'relative', width: '70%' }}>
                                        <Table size="small" stickyHeader aria-label="spanning table">
                                            <TableHead>
                                                <TableRow >
                                                    <TableCell align="left" className="table tableCell-heading tableCellPadding">Particulars</TableCell>
                                                    <TableCell align="right" className="table tableCell-heading tableCellPadding"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {balanceData?.filter(item => excludedTypes.includes(item.transactionType)).map(item => (
                                                    <TableRow className='common-icon' onClick={() => getDetail(item.transactionType)}>
                                                        <TableCell>{item.transactionType}</TableCell>
                                                        <TableCell align='right'>
                                                            {changeCurrencyFormat(item.value, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow sx={{ position: 'sticky', bottom: 0, backgroundColor: '#fff', zIndex: 1 }}>
                                                    <TableCell className="table tableCell-heading ">Total</TableCell>
                                                    <TableCell className='table tableCellBold' align="right">{changeCurrencyFormat(totalCredit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}</TableCell>
                                                </TableRow>
                                                {balanceData?.filter(item => !excludedTypes.includes(item.transactionType)).map(item => (
                                                    <TableRow className='common-icon' onClick={() => getDetail(item.transactionType)}>
                                                        <TableCell>{item.transactionType}</TableCell>
                                                        <TableCell align='right'>
                                                            {changeCurrencyFormat(item.value, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow sx={{ position: 'sticky', bottom: 0, backgroundColor: '#fff', zIndex: 1 }}>
                                                    <TableCell className="table tableCell-heading ">Total</TableCell>
                                                    <TableCell className='table tableCellBold' align="right">{changeCurrencyFormat(totalDebit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                : <h5 style={{ textAlign: "center" }}>No data</h5>
                            }</>) : (
                                <>
                                    {renderLoading("profitloss") ? (
                                        <h5 style={{ textAlign: "center" }}>No data</h5>
                                    ) : (
                                        <Box className='box center group' sx={{ marginTop: "20px" }}>
                                            <img src={process.env.PUBLIC_URL + '/assets/images/loading.png'} alt="loading" width={250} height={250} />
                                            <span style={{ marginBottom: '10px' }}>Almost There! Balance Sheet is on Its Way...</span>
                                            <CircularProgress />
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                    </Grid>}
                    {isDetail && <Grid item xs={12} mt={2}>
                        {balanceData ? (<>{balanceData?.length !== 0 ?
                            <Grid container>
                                <Grid item xs={6}>
                                    <Box mb={2} width='100%' p={1}>
                                        <TableContainer component={Paper} sx={{ height: 380, position: 'relative', overflow: 'auto' }} >
                                            <Table size="small" stickyHeader aria-label="spanning table">
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell align="left" className="table tableCell-heading tableCellPadding">Liabilities</TableCell>
                                                        <TableCell align="right" className="table tableCell-heading tableCellPadding"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {balanceData?.filter(item => !excludedTypes.includes(item.transactionType)).map(item => (
                                                        <TableRow className='common-icon' onClick={() => getDetail(item.transactionType)}>
                                                            <TableCell>{item.transactionType}</TableCell>
                                                            <TableCell align='right'>
                                                                {changeCurrencyFormat(item.value, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box>
                                            <TableContainer component={Paper}>
                                                <Table size="small" stickyHeader aria-label="spanning table">
                                                <TableRow >
                                                        <TableCell className="table tableCell-heading">Total</TableCell>
                                                        <TableCell className='table tableCellBold' align="right">{changeCurrencyFormat(totalDebit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}</TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box mb={2} width='100%' p={1}>
                                        <TableContainer component={Paper} sx={{ height: 380, position: 'relative', overflow: 'auto' }}>
                                            <Table size="small" stickyHeader aria-label="spanning table">
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell align="left" className="table tableCell-heading tableCellPadding">Assets</TableCell>
                                                        <TableCell align="right" className="table tableCell-heading tableCellPadding"></TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {balanceData?.filter(item => excludedTypes.includes(item.transactionType)).map(item => (
                                                        <TableRow className='common-icon' onClick={() => getDetail(item.transactionType)}>
                                                            <TableCell>{item.transactionType}</TableCell>
                                                            <TableCell align='right'>
                                                                {changeCurrencyFormat(item.value, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Box>
                                            <TableContainer component={Paper}>
                                                <Table size="small" stickyHeader aria-label="spanning table">
                                                <TableRow >
                                                    <TableCell className="table tableCell-heading">Total</TableCell>
                                                    <TableCell className='table tableCellBold' align="right">{changeCurrencyFormat(totalCredit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}</TableCell>
                                                </TableRow>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                            : <h5 style={{ textAlign: "center" }}>No data</h5>
                        }</>) : (
                            <>
                                {renderLoading("profitloss") ? (
                                    <h5 style={{ textAlign: "center" }}>No data</h5>
                                ) : (
                                    <Box className='box center group' sx={{ marginTop: "20px" }}>
                                        <img src={process.env.PUBLIC_URL + '/assets/images/loading.png'} alt="loading" width={250} height={250} />
                                        <span style={{ marginBottom: '10px' }}>Almost There! Balance Sheet is on Its Way...</span>
                                        <CircularProgress />
                                    </Box>
                                )}
                            </>
                        )}

                    </Grid>}

                </Grid>

                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        {/* <Typography id="modal-modal-title" variant="h6" component="h2">
              Detail
            </Typography> */}
                        {/* <Box sx={{ float: 'right' }}>
                            <Tooltip title={(detailData || renderLoading('profitloss') === true) ? 'Close' : 'Fetching data, please wait.....'}>
                                <IconButton className='icon delete' onClick={() => close()}><CloseIcon /></IconButton>
                            </Tooltip>
                        </Box> */}
                        <Typography id="modal-modal-description" >
                            {/* {detailData ? <>
                {detailData.length !== 0 ? */}
                            <TrialDetailTable tableData={detailData} path={path} setPath={setPath} getDiggedDetail={getDiggedDetail} renderLoading={renderLoading} detailData={detailData} close={close} />
                            {/* :
                  <h5 style={{ textAlign: "center" }}>No data</h5>
                }
              </> :
                <>
                  {renderLoading("profitloss") ? (
                    <h5 style={{ textAlign: "center" }}>No data</h5>
                  ) : (
                    <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </>
              } */}
                        </Typography>
                    </Box>
                </Modal>
            </Box>
        </ThemeProvider>)
}

export default BalanceSheet