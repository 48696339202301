import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function Home() {
    const navigate = useNavigate()
    return (
        <div>
            <Grid container spacing={1} mt={3}>
                <Grid item xs={12} md={3}>
                <Card className='refresh-icon' onClick={() => navigate('/accounts_dashboard')}>
                <CardContent sx={{display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                  <Typography>Accounts Dashboard</Typography>
                </CardContent>
            </Card>
                </Grid>
            </Grid>
        </div>
    )
}

export default Home