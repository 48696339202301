import React from 'react'
import {
    Box, Grid, Tooltip, IconButton, Typography, Menu, Select, FormControl, MenuItem,
    Button, OutlinedInput, Checkbox, ListItemText
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DateCalander from './DateCalander';
import { useEffect } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const filter = createFilterOptions();


function InventoryFilter(
    {
        branchSelect,
        setBranchSelect,
        branchList,
        frmDate,
        toDate,
        fyrName,
        fyrList,
        fyrSelect,
        setFyrSelect,
        setFyrName,
        selectedFyr,
        setSelectedFyr,
        handleSearchClick,
        setFrmdate,
        setTodate,

        handleSearch,
        isLoadingTrue,
        setbranchSelectSearch,
        setBranchName,
        optionTitle,
        optionSelect,
        setOptionSelect,
        optionList,
        optionKey
    }) {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleChangeFyrselect = (e) => {
        setFyrSelect(e.target.value);
        let fYear = fyrList?.find(item => item.Fyrname === e.target.value)
        setSelectedFyr(fYear)
    }

    const search = () => {
        handleCloseMenu()
        // setAsOnDate(fyear.AsonDate)
        setFyrName(selectedFyr.Fyrname)

        const brName = branchList?.filter(item => branchSelect === item.branchid)
            .map(item => item.branchname);
        console.log(brName);
        setBranchName(brName)
        // setbranchSelectSearch(branchSelect)
        handleSearch(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto)
    }


    return (
        <>
            <Tooltip title='Search'>
                <IconButton size='small' onClick={handleMenu} className='filter-style'><FilterAltIcon className='filter-style' /></IconButton>
            </Tooltip>
            {/* filter box start*/}
            <Menu
                id="search"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
            >
                <Box className='search box' >
                    <Grid container spacing={1} >
                        <Grid item xs={12} ><Typography className='text-color'> {t('home.branch')} </Typography></Grid>

                        <Grid item xs={12} >
                            <FormControl required sx={{ width: '100%' }}>
                                <Select
                                    size="small"
                                    fullWidth
                                    id="demo-simple-select"
                                    name="branchid"
                                    value={branchSelect}
                                    onChange={(e) => { setBranchSelect(e.target.value) }}
                                >
                                    {branchList?.map((item) => (
                                        <MenuItem
                                            key={item.branchid}
                                            name={item.branchname}
                                            value={item.branchid}
                                        >
                                            {item.branchname}
                                            {/* <ListItemText primary={item.branchname} /> */}
                                        </MenuItem>
                                    ))}

                                </Select>

                            </FormControl>
                        </Grid>

                        <Grid item xs={12} ><Typography className='text-color'> {t('home.fyr')} </Typography></Grid>

                        <Grid item xs={12} >
                            <FormControl required sx={{ width: '100%' }}>

                                <Select
                                    size='small'
                                    id="demo-simple-select-filled"
                                    value={fyrSelect}
                                    onChange={handleChangeFyrselect}

                                >
                                    {fyrList ? fyrList?.map(item => (<MenuItem key={item.Fyrname} name={item.Fyrname} value={item.Fyrname}  >{item.Fyrname}</MenuItem>))
                                        : (<p style={{ marginLeft: '5px' }}>No financial year to display</p>)
                                    }
                                </Select>

                            </FormControl>
                        </Grid>

                        {/* <Grid item xs={12} ><Typography className='text-color'>{t('home.fyr')}</Typography></Grid> */}
                        {/* <Grid item xs={12}>
                            <Grid item xs={12} ><Typography className='text-color'>From</Typography></Grid>
                            <Grid item xs={12}>
                                <DateCalander value={dayjs(frmDate)} setValue={setFrmdate} />
                            </Grid>
                            <Grid item xs={12} ><Typography className='text-color'>To</Typography></Grid>
                            <Grid item xs={12}>
                                <DateCalander value={dayjs(toDate)} setValue={setTodate} />
                            </Grid>
                        </Grid> */}





                        <Grid item xs={12}  >
                            {!isLoadingTrue() && <Button fullWidth className="btn btn-search" onClick={() => search()}>
                                {t('form.search')}</Button>}
                            {isLoadingTrue() && <Button fullWidth className="btn btn-filter-load">
                                <span className="btn-text">{t('form.loading')}</span><div className="spinner"></div>
                            </Button>}
                        </Grid>
                    </Grid>
                </Box>
            </Menu>
            {/* filter box end */}
        </>
    )
}

export default InventoryFilter