import axiosInstance from "./axiosInstance";

let api = axiosInstance()

 
export const getSalesSummary = async(branchId, frm, to, AsonDate, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/Summary?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&AsonDate=${AsonDate}`, config)
}
 
// SalesAndProfit
export const getSalesProfit = async(branchId, frm, to,Voucher, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/MonthWise?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&&VoucherType=${Voucher}`, config)
}

 // SalesAndProfit daywise
export const getSalesProfitDaywise = async(branchId, frm, to,Voucher, month, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/SalesDateWise?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&&VoucherType=${Voucher}&Month=${month}`, config)
}
// Voucher Type Graph
export const getVoucherType = async(branchId, frm, to, type, Month, abortToken)=>{
  console.log(branchId, frm, to, type, Month);
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/VoucherTypeSummary?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&VoucherType=${type}&&Month=${Month}`, config)
}
 
// Get product group
export const getProductGroup = async(branchId, frm, to, abortToken)=>{
  console.log(branchId, frm, to);
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/ProductGroup?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}
 
// Sales Vs Collection Graph
export const getSalesVsCollection = async(branchId, frm, to, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/SalesandCollection?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}
 
// Top Cost Centre
export const getCostCentre = async(branchId, frm, to, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/TopCostCentre?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getSalesReturn = async(branchId, frm, to, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/ReturnMonthWise?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}


// SalesReturn daywise
// export const getSalesReturnDaywise = async(branchId, frm, to, month, abortToken)=>{
//   const config = {
//       ...abortToken,
//       timeout: 30000, // Set the timeout for this specific request
//     };
//   return  await api.get(`/Sales/ReturnDateWise?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Month=${month}`, config)
// }
export const getSalesReturnDaywise = async(branchId, frm, to, month, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/ReturnDateWise?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Month=${month}`, config)
}
//Sales Return Product
export const getSalesReturnProductwise = async(branchId, frm, to, month, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/SalesReturnByType?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Month=${month}`, config)
}

 //SalesReturn Table
export const getSalesReturnTable = async(branchId, frm, to, month, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/SalesReturnTabular?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Month=${month}`, config)
}
//Top10ProductReturn
export const getTop10ProductReturn = async(branchId, frm, to, Product, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/TopProductSalesReturn?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&ProductGroup=${Product}`, config)
}
 
//Top10CustomerReturn
export const getTop10CustomerReturn = async(branchId, frm, to, Product, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Sales/TopCustomerSalesReturn?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&ProductGroup=${Product}`, config)
}