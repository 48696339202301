import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography, CircularProgress, Card, Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../components/styles/theme';
import dayjs from 'dayjs'
import { useSelector } from 'react-redux';
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from '../../api/HandleApiErrors';
import { useTranslation } from 'react-i18next';
import { getPermittedBranch } from '../../api/commonApi';
import { changeFormat, dateConvert, changeCurrencyFormat, convertCurrency } from '../../HelperFile';
import InventoryHeader from '../../components/InventoryHeader';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as inventoryStockReportApi from '../../api/inventoryStockReportApi';
import { getProductGroup } from '../../api/saleshomeApi';
//  import * as saleshomeApi from '../../api/saleshomeApi';
import InventoryExtraFilter from '../../components/InventoryExtraFilter'
import { getFyrByCompany } from '../../api/fyrApi';
import ProfitLossCard from '../../components/ProfitLossCard';
import InventoryTable from './InventoryTable';
import ProductTable from './ProductTable';
import BarChartIcon from '@mui/icons-material/BarChart';
import ProductGraph from './ProductGraph';
import ProductFilter from './ProductFilter';
import StockHeader from '../../components/StockHeader';
import AntSwitch from '../../components/styles/AntSwitch';

function InventoryAnalysis() {

  const { t } = useTranslation()
  const isBranchList = useRef(false)

  const initialLoading = useRef(false)
  const abortConstants = useSignalAbort()
  const token = useSelector(state => state.token)
  const user = useSelector(state => state.user)
  const companyId = user?.companyid
  const company = user?.company
  const isFyr = useRef(false);
  const [fyrList, setFyrList] = useState()
  const [fyrSelect, setFyrSelect] = useState('');
  const [fyrName, setFyrName] = useState()
  const [branchList, setBranchList] = useState()

  const [branchSelect, setbranchSelect] = useState(user?.branchid)
  const [branchSelectSearch, setbranchSelectSearch] = useState(user?.branchid)
  const [toDate, setTodate] = useState()
  const [selectedFyr, setSelectedFyr] = useState()
  const [frmDate, setFrmdate] = useState()
  const [frDate, setFrDate] = useState()
  const [tDate, setTDate] = useState()
  const [branchName, setBranchName] = useState()
  const [isLoading, setIsLoading] = useState({})
  const [groupSelect, setGroupSelect] = useState();
  const [Grouptype, setGrouptype] = useState()
  const [products, setProducts] = useState()
  const [inventoryStock, setInventoryStock] = useState()
  const [inventoryStockTable, setInventoryStockTable] = useState()
  const [inventoryStockHighest, setInventoryStockHighest] = useState()
  const [inventoryStockAvgMoving, setInventoryStockAvgMoving] = useState()
  const [productSelect, setProductSelect] = useState()
  const [isProduct, setIsProduct] = useState(true)
  const [isTable, setIsTable] = useState(false)
  const [isUnit, setIsUnit] = useState(false)
  const [avgSales, setAvgSales] = useState(false)

  //display message if default branch is not set to the user
  useEffect(() => {
    if (user && !user?.branchid) {
      toast.error("A default branch has not been assigned to you. Please contact your administrator", {
        autoClose: 3000, // Close the toast after 5 seconds
      });
    }
  }, [user])

  useEffect(() => {
    const fetchData = async () => {
      if (!token || !companyId || !user) return;

      if (!isFyr.current) {
        console.log("Fetching financial year data...");
        await getFyrByCriteria(companyId, abortConstants.controllerConfig);
        isFyr.current = true;
      }

      if (!isBranchList.current) {
        console.log("Fetching branch data...");
        await getBranch(user.userid, abortConstants.controllerConfig);
        isBranchList.current = true;
      }

      if (branchSelect && selectedFyr) {
        console.log("Fetching voucher type...");
        // await getGroupType(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, abortConstants.controllerConfig);
        await getProducts(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, abortConstants.controllerConfig);
      }

      // if (branchSelect && selectedFyr && !initialLoading.current) {
      //   console.log("Fetching all data...");
      //   await getAllData(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, '', abortConstants.controllerConfig)
      //   initialLoading.current = true;
      // }
    };

    fetchData();

    return () => {
      abortConstants.controller.abort();
      toast.dismiss();
    };
  }, [token, companyId, user, branchSelect, selectedFyr]);

  const getBranch = async (userId, abortToken) => {
    try {
      const result = await getPermittedBranch(userId, abortToken);//api call to get all branch
      isBranchList.current = true;
      if (result?.data?.branch.length !== 0) {
        setBranchList(result?.data?.branch);
        if (!user?.branchid) {
          // Set branchSelect to the first branch ID if user does not have a branch ID
          setbranchSelect(result?.data?.branch[0].branchid);
          // getGroupType(result?.data?.branch[0].branchid, frm, to, abortToken)

          const brName = result.data?.branch[0].branchname;
          setBranchName(brName);
        } else {
          // getGroupType(branchSelect.join(','), frm, to,   abortToken)
          const brName = result.data?.branch
            .filter(item => branchSelect === item.branchid)
            .map(item => item.branchname);
          setBranchName(brName);
        }
      }
      else {
        toast.error(result?.data?.message, {
          autoClose: 3000, // Close the toast after 5 seconds
        });
      }
    } catch (error) {
      handleApiErrors(error, 'Branch', 'branch_response', 'branch_timeout', 'branch_request', setIsLoading)
    }
  }

  const getFyrByCriteria = async (id, abortToken) => {
    try {
      const result = await getFyrByCompany(id, abortToken)
      setFyrList(result.data.FinancialYear)
      isFyr.current = true
      let fYear = result.data.FinancialYear.find(item => item.Iscurrent === 1)
      setSelectedFyr(fYear)
      setFyrSelect(fYear?.Fyrname)
      setFyrName(fYear?.Fyrname)
      setFrmdate(fYear?.Fyrfrom)
      setTodate(fYear?.Fyrto)
    }
    catch (error) {
      handleApiErrors(error, 'Financial Year', 'fyr_response', 'fyr_timeout', 'fyr_request', setIsLoading)
    }
  }


  const getAllData = (branchId, frm, to, abortToken) => {
    setFrDate(changeFormat(dayjs(frm), user?.company?.DateFormatname))//change the format of from date
    setTDate(changeFormat(dayjs(to), user?.company?.DateFormatname))//change the format of to date
    // let fDate = dateConvert(frmDate)
    // let tDate = dateConvert(toDate)
    // getGroupType(branchId, frm, to, abortToken);
    getProducts(branchId, frm, to, abortToken);
    // getInventoryStock(branchId, frm, to, productgroup, abortToken)

  }


  //product group
  const getGroupType = async (branchId, frm, to, abortToken) => {
    console.log(branchId, frm, to);
    try {
      const result = await getProductGroup(branchId, frm, to, abortToken)
      console.log("group", result);
      setGrouptype(result?.data?.ProductGroup)
      console.log("groups", result?.data?.ProductGroup);

    }
    catch (error) {
      handleApiErrors(error, 'GroupType', 'group_response', 'group_timeout', 'group_request', setIsLoading)
    }
  }

  //products
  const getProducts = async (branchId, frm, to, abortToken) => {
    // console.log(branchId, frm, to);
    try {
      const result = await inventoryStockReportApi.getInventoryItems(branchId, frm, to, abortToken)
      // console.log("group", result);
      setProducts(result?.data?.Product)
    }
    catch (error) {
      handleApiErrors(error, 'GroupType', 'group_response', 'group_timeout', 'group_request', setIsLoading)
    }
  }

  //inventory analysis graph  
  const getInventoryStock = async (branchId, frm, to, isGroup, productSelected, abortToken) => {
    // console.log(branchId, frm, to, isGroup, productSelected);
    try {
      const result = await inventoryStockReportApi.getInventoryGraph(branchId, frm, to, isGroup, productSelected, abortToken)
      console.log("InventoryStock", result);
      setInventoryStock(result?.data?.Inventory);
    }
    catch (error) {
      handleApiErrors(error, 'InventoryStock', 'inventoryStock_response', 'inventoryStock_timeout', 'inventoryStock_request', setIsLoading)
    }
  }

  //inventory analysis table  
  const getInventoryStockTable = async (branchId, frm, to, isGroup, productSelected, abortToken) => {
    // console.log(branchId, frm, to, isGroup, productSelected);
    try {
      const result = await inventoryStockReportApi.getInventoryTable(branchId, frm, to, isGroup, productSelected, abortToken)
      // console.log("InventoryStock", result);
      setInventoryStockTable(result?.data?.Inventory);

    }
    catch (error) {
      handleApiErrors(error, 'InventoryStock', 'inventoryStock_response', 'inventoryStock_timeout', 'inventoryStock_request', setIsLoading)
    }
  }

  const getInventoryStockAvgMoving = async (branchId, frm, to, productSelected, abortToken) => {
    console.log(branchId, frm, to, productSelected);
    try {
      const result = await inventoryStockReportApi.getInventoryAvgMoving(branchId, frm, to, productSelected, abortToken)
      console.log("InventoryStock", result);
      setInventoryStockAvgMoving(result?.data?.Inventory[0]);
    }
    catch (error) {
      handleApiErrors(error, 'InventoryStock', 'inventoryStock_response', 'inventoryStock_timeout', 'inventoryStock_request', setIsLoading)
    }
  }

  const getInventoryStockHighestValue = async (branchId, frm, to, productSelected, abortToken) => {
    console.log(branchId, frm, to, productSelected);
    try {
      const result = await inventoryStockReportApi.getInventoryHighestValue(branchId, frm, to, productSelected, abortToken)
      console.log("InventoryStock", result);
      setInventoryStockHighest(result?.data?.Inventory);
    }
    catch (error) {
      handleApiErrors(error, 'InventoryStock', 'inventoryStock_response', 'inventoryStock_timeout', 'inventoryStock_request', setIsLoading)
    }
  }

  const getTabularView = () => {
    setIsTable(true)
    getInventoryStockTable(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, isProduct ? 0 : 1, productSelect, abortConstants.controllerConfig)
  }

  //for search
  const handleSearch = (isGroup, group) => {
    console.log(isGroup, group);
    toast.dismiss()
    setInventoryStock(null)
    setInventoryStockTable(null)
    setInventoryStockAvgMoving(null)
    setIsTable(false)
    setIsLoading({})
    getInventoryStock(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, isGroup, group, abortConstants.controllerConfig)
    getInventoryStockAvgMoving(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, group, abortConstants.controllerConfig)
    getInventoryStockHighestValue(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, group, abortConstants.controllerConfig)
  }

  //for search
  const handleSearchClick = (branchId, frm, to) => {
    console.log(branchId, frm, to);
    toast.dismiss()
    setProducts(null)
    setInventoryStock(null)
    setInventoryStockTable(null)
    setInventoryStockAvgMoving(null)
    setIsLoading({})
    getAllData(branchId, frm, to, abortConstants.controllerConfig)
  }

  //for loading circular progress
  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`] ||
      isLoading.branch_response || isLoading.branch_request || isLoading.branch_timeout) {
      return true
    }
    else {
      return false
    }
  }

  // to disable/enable search button
  const isLoadingTrue = () => {
    const state = (Object.keys(isLoading).length === 0) && (!products)
    return state
  }

  const exportToExcel = () => {
    // Extract only the required fields from inventoryStock
    const filteredData = inventoryStockTable?.filter(row => row.ProductName !== 'Opening Balance');
    // Find the opening balance
    const openingBalance = inventoryStockTable.find(item => item.ProductName === 'Opening Balance')?.StockIn || 0;
    const customRow = {
      ProductName: '',
      VoucherDate: '',
      VoucherNo: '',
      CustomerName: 'Opening Balance',
      VoucherType: '',
      StockIn: '',
      StockOut: '',
      CostPrice: '',
      MRP: '',
      Profit: '',
      isCustom: true, // Custom flag to identify the custom row
      row_id: -1, // Unique ID for the custom row
      Balance: openingBalance
    };
    const newData = [];
    let currentBalance = openingBalance;
console.log(filteredData);

    filteredData.forEach((row, index) => {
        currentBalance += (row.StockIn || 0) - (row.StockOut || 0);
        newData.push({
            ...row,
            row_id: index,
            Balance: currentBalance,
            VoucherDate: row.VoucherDate ? changeFormat(dayjs(row.VoucherDate), user?.company?.DateFormatname) : '',
            CostPrice: changeCurrencyFormat(row.CostPrice, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces),'No_SYMBOL'),
            MRP: changeCurrencyFormat(row.MRP, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces),'No_SYMBOL')

        });
    });
    const data = ([customRow, ...newData]);
    const dataToExport = data.map(item => ({
      'Date': item.VoucherDate,
      'Ref No': item.VoucherNo,
      'Customer/ Vendor': item.CustomerName,
      'Voucher Type': item.VoucherType,
      'Stock In': item.StockIn,
      'Cost': item.CostPrice,
      'Stock Out': item.StockOut,
      'MRP': item.MRP,
      'Balance': item.Balance,
    }));

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Inventory Report.xlsx');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container spacing={2}>
          {/* header */}
          {branchList && fyrList && (
            <Grid item xs={12}>
              <StockHeader
                branchName={branchName}
                isLoadingTrue={isLoadingTrue}
                branchSelect={branchSelect}
                setbranchSelect={setbranchSelect}
                branchList={branchList}
                handleSearchGlobal={handleSearchClick}
                setbranchSelectSearch={setbranchSelectSearch}
                setBranchName={setBranchName}
                frmDate={frmDate}
                toDate={toDate}
                setFrmdate={setFrmdate}
                setTodate={setTodate}
                fyrSelect={fyrSelect}
                setFyrSelect={setFyrSelect}
                fyrList={fyrList}
                setFyrName={setFyrName}
                selectedFyr={selectedFyr}
                setSelectedFyr={setSelectedFyr}
                optionTitle="Group selection"
                exportExcel={exportToExcel}
                title="Inventory Analysis"
                optionList={isProduct ? products : Grouptype}
                optionKey={isProduct ? 'productName' : 'ProductGroupName'}
                optionSelect={productSelect}
                setOptionSelect={setProductSelect}
                optionId={isProduct ? 'productId' : 'ProductGroupId'}
                isProduct={isProduct}
                setIsProduct={setIsProduct}
                handleSearch={handleSearch}
                isTable={isTable}
                tableData={inventoryStockTable}
              />
            </Grid>
          )}
          {/* <Grid item xs={12} mt={0} pt={0}>
            <ProductFilter optionList={isProduct ? products : Grouptype}
              optionKey={isProduct ? 'productName' : 'ProductGroupName'}
              optionSelect={productSelect}
              setOptionSelect={setProductSelect}
              optionId={isProduct ? 'productId' : 'ProductGroupId'}
              isProduct={isProduct}
              setIsProduct={setIsProduct}
              handleSearch={handleSearch} />
          </Grid> */}
          <Grid item xs={12} mt={1} >
            {/* from here renderLoading */}
            {inventoryStock ? (
              <Box pl={2}>
                <>
                  {inventoryStock.length !== 0 ? (
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12} md={3}>
                        <Card className='card boder-r' sx={{ height: '400px' }} ></Card>
                      </Grid> */}
                      <Grid item xs={12} >
                        <Card className='card boder-r' sx={{ height: '420px' }}>
                          <Box className='box align' p={1} pb={2} pt={2}>
                            <Box className='box flex flex-center'>
                              <span className='span text-h3'>{isTable ? 'Tabular View' : 'Monthly View'}</span>
                            </Box>
                            {!isTable && <Box className='box flex' sx={{ float: 'right' }} mr={2}>
                              <Typography className='typography-switch size'>Value</Typography>&nbsp;&nbsp;
                              <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={isUnit} onChange={() => setIsUnit((preState) => !preState)} />&nbsp;&nbsp;
                              <Typography className='typography-switch size'>Units</Typography>
                            </Box>}
                            <Box mr={2}>
                              <Tooltip title={isTable ? 'Graphical view' : 'Tabular view'}>
                                {!isTable ? <img src={process.env.PUBLIC_URL + '/assets/images/table icon.png'} className='chevron-icon' height={18} width={18} onClick={() => getTabularView()} /> : <BarChartIcon className='chevron-icon' onClick={() => setIsTable(false)} />}
                              </Tooltip>
                            </Box>
                          </Box>
                          <Box maxHeight='350px' overflow='auto' >
                            {isTable ? <ProductTable tableData={inventoryStockTable} /> : <ProductGraph chartData={inventoryStock} isUnit={isUnit} />}
                          </Box>
                        </Card>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            <ProfitLossCard title='Average Moving Per Month' value={inventoryStockAvgMoving?.AvgMovPerMonth || 0} unit={inventoryStockAvgMoving?.UnitName} />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <ProfitLossCard title='Gross Profit Margin'
                              value={convertCurrency(inventoryStockAvgMoving?.AvgMargin, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces)) || 0}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <ProfitLossCard title='Average Stock Per Month' value={inventoryStockAvgMoving?.AvgStockPerMonth || 0} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} pb={3}>
                        {inventoryStockHighest &&
                          <InventoryTable data={inventoryStockHighest} />
                        }
                      </Grid>
                    </Grid>
                  ) : (
                    <h4 style={{ textAlign: "center" }}>No data</h4>
                  )}
                </>

              </Box>
            ) : (
              <>
                {/* {renderLoading("journal") ? (
                  <h5 style={{ textAlign: "center" }}>No data</h5>
                ) : (
                  <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                    <CircularProgress />
                  </Box>
                )} */}
              </>
            )}

            {/* upto here */}
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>)
}

export default InventoryAnalysis