import axiosInstance from "./axiosInstance";

let api = axiosInstance()


export const getDateFormat = async(separtor)=>{
    return await api.get(`/DateFormat?Separator=${separtor}`)
}
export const getTimeZone = async()=>{
    return await api.get(`/TimeZone`)
}

export const getOrganizationType = async()=>{
    return await api.get(`/CompanyType`)
}

export const getLanguage = async()=>{
    return await api.get(`/Language`)
}

export const getCountry = async()=>{
    return await api.get(`/Country`)
}

export const getCurrency = async(id)=>{
    return await api.get(`/Currency?CountryId=${id}`)
}

export const getCompanyData = async(id)=>{
    return await api.get(`/company/${id}`)
}

export const updateCompanyData = async(id, data)=>{
    return await api.put(`/company/${id}`,data)
}
 

