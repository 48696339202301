import { useMediaQuery } from '@mui/material';
import React, { createContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const isNonMobile = useMediaQuery("(min-width: 600px)");
    const mainDrawerWidth = 235; // Example constant 1
    const iconDrawerWidth = 80; // Example constant 2

    return (
        <AppContext.Provider value={{ isSidebarOpen, setIsSidebarOpen, isNonMobile, mainDrawerWidth, iconDrawerWidth }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;
