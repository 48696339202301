import React, { useEffect, useState } from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Box } from '@mui/material'
import { DataGrid, GridOverlay } from '@mui/x-data-grid';
import muiStyles from '../../components/styles/muiStyles';
import { changeCurrencyFormat, changeFormat } from '../../HelperFile';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

function ProductTable({ tableData }) {
    const [data, setData] = useState()
    const user = useSelector(state => state.user);
    const company = user?.company;

    useEffect(() => {
        if (tableData) {
            // Filter out the row where ProductName is 'openingbalance'
            const filteredData = tableData?.filter(row => row.ProductName !== 'Opening Balance');
            // Find the opening balance
            const openingBalance = tableData.find(item => item.ProductName === 'Opening Balance')?.StockIn || 0;
            const customRow = {
                ProductName: '',
                VoucherDate: '',
                VoucherNo: '',
                CustomerName: 'Opening Balance',
                VoucherType: '',
                StockIn: '',
                StockOut: '',
                CostPrice: '',
                MRP: '',
                Profit: '',
                isCustom: true, // Custom flag to identify the custom row
                row_id: -1, // Unique ID for the custom row
                Balance: openingBalance
            };
            console.log(filteredData);

            // Calculate the balance for each row
            // const newData = filteredData.map((row, index, array) => {
            //     const previousBalance = index === 0 ? openingBalance : array[index - 1].Balance;
            //     const currentBalance = previousBalance + (row.StockIn || 0) - (row.StockOut || 0);
            //     return {
            //         ...row,
            //         row_id: index,
            //         Balance: currentBalance,
            //     };
            // });

            // Calculate the balance for each row
            const newData = [];
            let currentBalance = openingBalance;

            filteredData.forEach((row, index) => {
                currentBalance += (row.StockIn || 0) - (row.StockOut || 0);
                newData.push({
                    ...row,
                    row_id: index,
                    Balance: currentBalance,
                    VoucherDate: row.VoucherDate ? changeFormat(dayjs(row.VoucherDate), user?.company?.DateFormatname) : '',
                    CostPrice: changeCurrencyFormat(row.CostPrice, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces),'No_SYMBOL'),
                    MRP: changeCurrencyFormat(row.MRP, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces),'No_SYMBOL')

                });
            });
            console.log(newData);

            setData([customRow, ...newData]);
        }
    }, [tableData])

    const columns = [
        // { field: 'ProductName', headerName: 'Name', headerClassName: 'super-app-theme--header', flex: 2 },
        { field: 'VoucherDate', headerName: 'Date', align: 'center', headerAlign: 'center', headerClassName: 'super-app-theme--header', flex: 1 },
        { field: 'VoucherNo', headerName: 'Ref No', align: 'center', headerAlign: 'center', headerClassName: 'super-app-theme--header', flex: 0.7 },
        { field: 'CustomerName', headerName: 'Customer/ Vendor', headerAlign: 'center', headerClassName: 'super-app-theme--header', flex: 2 },
        { field: 'VoucherType', headerName: 'Voucher Type', align: 'center', headerAlign: 'center', headerClassName: 'super-app-theme--header', flex: 1 },
        { field: 'StockIn', headerName: 'Stock In', align: 'center', headerAlign: 'center', headerClassName: 'super-app-theme--header', flex: 0.7 },
        { field: 'CostPrice', headerName: 'Cost', align: 'right', headerAlign: 'center', headerClassName: 'super-app-theme--header', flex: 1 },
        { field: 'StockOut', headerName: 'Stock Out', align: 'center', headerAlign: 'center', headerClassName: 'super-app-theme--header', flex: 0.7 },
        { field: 'MRP', headerName: 'MRP', align: 'right', headerAlign: 'center', headerClassName: 'super-app-theme--header', flex: 1 },
        { field: 'Balance', headerName: 'Balance', align: 'center', headerAlign: 'center', headerClassName: 'super-app-theme--header', flex: 1 },
    ];

    return (
        <>{data &&
            <DataGrid
                sx={muiStyles.gridStyles.root}
                columnHeaderHeight={30}
                rowHeight={30}
                rows={data}
                columns={columns}
                getRowId={(row) => row.row_id}
                disableRowSelectionOnClick
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 9, page: 0 },
                    },
                }}

            // hideFooter
            // slots={{ columnMenu: CustomColumnMenu }}
            />
            //     <TableContainer style={{ maxHeight: '350px', overflow: 'auto' }}>
            //     <Table size="small" stickyHeader aria-label="spanning table">
            //         <TableHead>
            //             <TableRow>
            //                 <TableCell className="table tableCell tableCell-heading tableCellNoBorder">Name</TableCell>
            //                 <TableCell className="table tableCell tableCell-heading tableCellNoBorder">Date</TableCell>
            //                 <TableCell className="table tableCell tableCell-heading tableCellNoBorder">Ref No</TableCell>
            //                 <TableCell className="table tableCell tableCell-heading tableCellNoBorder">Customer/ Vendor</TableCell>
            //                 <TableCell className="table tableCell tableCell-heading tableCellNoBorder">Voucher Type</TableCell>
            //                 <TableCell className="table tableCell tableCell-heading tableCellNoBorder">Stock In</TableCell>
            //                 <TableCell className="table tableCell tableCell-heading tableCellNoBorder">Cost</TableCell>
            //                 <TableCell className="table tableCell tableCell-heading tableCellNoBorder">Stock Out</TableCell>
            //                 <TableCell className="table tableCell tableCell-heading tableCellNoBorder">MRP</TableCell>
            //                 <TableCell className="table tableCell-heading tableCellNoBorder">Balance</TableCell>
            //             </TableRow>
            //         </TableHead>
            //         <TableBody>
            //             {/* {tableData?.map((item, index) => (
            //                 <TableRow key={index}>
            //                     <TableCell className="table tableCell">Highest value purchased </TableCell>
            //                     <TableCell className="table tableCell">M/s. Force Motors Ltd</TableCell>
            //                     <TableCell align="right" className="table tableCell">01/06/2024</TableCell>
            //                     <TableCell align="right" className="table tableCell">23</TableCell>
            //                     <TableCell align="right" className="table tableCell">13231.00</TableCell>
            //                     <TableCell align="right" className="table tableCell">23</TableCell>
            //                     <TableCell align="right" className="table tableCell">23</TableCell>
            //                     <TableCell align="right" className="table tableCell">23</TableCell>
            //                     <TableCell align="right" className="table tableCell">13231.00</TableCell>
            //                     <TableCell align="right">13231.00</TableCell>
            //                 </TableRow>
            //             ))} */}
            //             <TableRow>
            //                 <TableCell className="table tableCell">Highest value purchased </TableCell>
            //                 <TableCell className="table tableCell">M/s. Force Motors Ltd</TableCell>
            //                 <TableCell align="right" className="table tableCell">01/06/2024</TableCell>
            //                 <TableCell align="right" className="table tableCell">23</TableCell>
            //                 <TableCell align="right" className="table tableCell">13231.00</TableCell>
            //                 <TableCell align="right" className="table tableCell">23</TableCell>
            //                 <TableCell align="right" className="table tableCell">23</TableCell>
            //                 <TableCell align="right" className="table tableCell">23</TableCell>
            //                 <TableCell align="right" className="table tableCell">13231.00</TableCell>
            //                 <TableCell align="right">13231.00</TableCell>
            //             </TableRow>
            //             <TableRow>
            //                 <TableCell className="table tableCell">Highest sales to customer</TableCell>
            //                 <TableCell className="table tableCell">Mr. A One Auto</TableCell>
            //                 <TableCell align="right" className="table tableCell">01/06/2024</TableCell>
            //                 <TableCell align="right" className="table tableCell">16</TableCell>
            //                 <TableCell align="right" className="table tableCell">13231.00</TableCell>
            //                 <TableCell align="right" className="table tableCell">23</TableCell>
            //                 <TableCell align="right" className="table tableCell">23</TableCell>
            //                 <TableCell align="right" className="table tableCell">23</TableCell>
            //                 <TableCell align="right" className="table tableCell">13231.00</TableCell>
            //                 <TableCell align="right">13231.00</TableCell>
            //             </TableRow>
            //             <TableRow>
            //                 <TableCell className="table tableCell">Last purchase date</TableCell>
            //                 <TableCell className="table tableCell">M/s. Force Motors Ltd</TableCell>
            //                 <TableCell align="right" className="table tableCell">01/06/2024</TableCell>
            //                 <TableCell align="right" className="table tableCell">30</TableCell>
            //                 <TableCell align="right" className="table tableCell">13231.00</TableCell>
            //                 <TableCell align="right" className="table tableCell">23</TableCell>
            //                 <TableCell align="right" className="table tableCell">23</TableCell>
            //                 <TableCell align="right" className="table tableCell">23</TableCell>
            //                 <TableCell align="right" className="table tableCell">13231.00</TableCell>
            //                 <TableCell align="right">13231.00</TableCell>
            //             </TableRow>
            //         </TableBody>
            //     </Table>
            // </TableContainer>
        }</>
    )
}

export default ProductTable