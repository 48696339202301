import React from 'react'
import Header from './Header'
import { Box, Button} from '@mui/material'
import Branch from '../pages/masterFiles/Branch'
import { useLocation, useNavigate } from 'react-router-dom'

function BranchProfile({getOnboardingStep, goToNextStep, goToPreviousStep}) {
    const navigate = useNavigate()
    const location = useLocation()
    const hideBackButton = location.state?.hideBackButton
    return (
        <Box>
            <Header />
            <Box sx={{mt:'100px', ml: '150px', mr: '150px', backgroundColor:'white'}}>
               <Box sx={{p:3}}>
               <Branch onBoarding={true}/>
               </Box>
               <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 3 }}>
                    <Button className='btn btn-update' onClick={()=>getOnboardingStep()}>Next</Button>
                    <Button className="btn btn-delete" onClick={() => goToPreviousStep()}>Back</Button>
                </Box>
            </Box>
        </Box>
    )
}

export default BranchProfile