import React from 'react'
import {
    Box, Grid, Tooltip, IconButton, Typography, Menu, Select, FormControl, MenuItem,
    Button, OutlinedInput, Checkbox, ListItemText
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import DateCalander from './DateCalander';
import { useEffect } from 'react';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { setSearchParameters } from '../redux/slices/authSlice';
import { dateConvert, getMonthRange } from '../HelperFile';

const filter = createFilterOptions();


function TabularFilter(
    { branchSelect,
        setBranchSelect,
        branchList,
        frmDate,
        toDate,
        setFrmdate,  
        setTodate,
        handleSearch,
        isLoadingTrue,
        setbranchSelectSearch,
        setBranchName,
        fyrList,
        fyrSelect,
        setFyrSelect,
        selectedFyr,
        setSelectedFyr,
        optionTitle,
        optionSelect,
        setOptionSelect,
        optionList,
        optionKey,
        changeOption
     }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };
 
    const handleChangeFyrselect = (e) => {
      console.log(e.target.value);
        setFyrSelect(e.target.value)
        let fYear = fyrList?.find(item => item.Fyrname === e.target.value)
        let range = getMonthRange(fYear?.Fyrfrom, fYear?.Fyrto, dayjs().month())
        setSelectedFyr(fYear)
        setFyrSelect(fYear?.Fyrname)
        setFrmdate(range[0])
        setTodate(range[1])
    }

    const search = () => {
      console.log(frmDate, toDate);
        handleCloseMenu()
        // const fyear = fyrList.find(item => item.Fyrname === fyrSelect);
        // setAsOnDate(fyear.AsonDate)
        // setFyrName(fyear.Fyrname)

        const brName = branchList?.filter(item => branchSelect === item.branchid)
            .map(item => item.branchname);
        setBranchName(brName)
        setbranchSelectSearch(branchSelect)
        dispatch(setSearchParameters({ selectedParameters: {selectedBranch:branchSelect, selectedFyr: fyrSelect, selectedFromDate: dateConvert(frmDate), selectedToDate: dateConvert(toDate)} }))
        handleSearch(branchSelect, frmDate, toDate, optionSelect)
        // handleSearch(branchSelect.join(','), frmDate, toDate   )

    }


    return (
        <>
         <Tooltip title='Search'>
            <IconButton size='small' onClick={handleMenu} className='filter-style'><FilterAltIcon className='filter-style' /></IconButton>
          </Tooltip>
          <Menu
            id="search"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <Box className='search box' >
              <Grid container spacing={1} >
                <Grid item xs={12} ><Typography className='text-color'> {t('home.branch')} </Typography></Grid>

                <Grid item xs={12} >
                  <FormControl required sx={{ width: '100%' }}>
                    <Select
                      size="small"
                      fullWidth
                      id="demo-simple-select"
                      name="branchid"
                      value={branchSelect}
                      onChange={(e) => { setBranchSelect(e.target.value) }}
                    >
                      {branchList?.map((item) => (
                        <MenuItem
                          key={item.branchid}
                          name={item.branchname}
                          value={item.branchid}
                        >
                          {item.branchname}
                        </MenuItem>
                      ))}

                    </Select>

                  </FormControl>
                </Grid>


                <Grid item xs={12} ><Typography className='text-color'>{t('home.fyr')}</Typography></Grid>
                        <Grid item xs={12}>
                            <FormControl required sx={{ width: '100%' }}>
                                <Select
                                    size='small'
                                    id="demo-simple-select-filled"
                                    value={fyrSelect}
                                    onChange={handleChangeFyrselect}
                                >
                                    {fyrList ? fyrList?.map(item => (<MenuItem key={item.Fyrname} name={item.Fyrname} value={item.Fyrname}  >{item.Fyrname}</MenuItem>))
                                        : (<p style={{ marginLeft: '5px' }}>No financial year to display</p>)
                                    }
                                </Select>

                            </FormControl>
                        </Grid>


                        {/* <Grid item xs={12} ><Typography className='text-color'>{t('home.fyr')}</Typography></Grid> */}
                            <Grid item xs={12} ><Typography className='text-color'>From</Typography></Grid>
                            <Grid item xs={12}>
                                <DateCalander value={dayjs(frmDate)} setValue={setFrmdate} frmDate={dayjs(selectedFyr?.Fyrfrom)} toDate={dayjs(selectedFyr?.Fyrto)}/>
                            </Grid>
                            <Grid item xs={12} ><Typography className='text-color'>To</Typography></Grid>
                            <Grid item xs={12}>
                                <DateCalander value={dayjs(toDate)} setValue={setTodate} frmDate={dayjs(selectedFyr?.Fyrfrom)} toDate={dayjs(selectedFyr?.Fyrto)}/>
                            </Grid>


                <Grid item xs={12} ><Typography className='text-color'>{optionTitle}</Typography></Grid>

                <Grid item xs={12}>

                  <FormControl required sx={{ width: '100%' }}>
                    <Select
                      size='small'
                      id="demo-simple-select-filled"
                      value={optionSelect}
                      onChange={(e) => { setOptionSelect(e.target.value) }}
                    >
                      {optionList ? optionList.map((item, index) => (<MenuItem key={index} name={item[`${optionKey}`]} value={item[`${optionKey}`]} >{item[`${optionKey}`]}</MenuItem>))
                        : (<p style={{ marginLeft: '5px' }}>No data to display</p>)
                      }
                    </Select>

                  </FormControl>
                </Grid>
                <Grid item xs={12}  >
                  {!isLoadingTrue() && <Button fullWidth className="btn btn-search" onClick={() => search()}>
                    {t('form.search')}</Button>}
                  {isLoadingTrue() && <Button fullWidth className="btn btn-filter-load">
                    <span className="btn-text">{t('form.loading')}</span><div className="spinner"></div>
                  </Button>}
                </Grid>
              </Grid>
            </Box>
          </Menu>
        </>
    )
}

export default TabularFilter