import React from 'react'
import { Box } from '@mui/material';
import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';


function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuSortItem: null,
      }}
    />
  );
}


function PermissionEditTable({subMenu, handleEditChange, editActions}) {
  let actionArray = []
  let actionName = []
  let menu = [...new Map(subMenu?.map(item =>
    [item['subMenu_id'], item])).values()].sort((a, b) => a.subMenu_order - b.subMenu_order)

  subMenu?.forEach(element => {
      (!actionName.includes(element['actions_name'])) && actionName.push(element['actions_name'])
  });

  menu?.forEach(item =>{
    let v_item =subMenu.find(element=>(element.subMenu_id === item.subMenu_id && element.actions_name === 'View'))
    let c_item =subMenu.find(element=>(element.subMenu_id === item.subMenu_id && element.actions_name === 'Create'))
    let e_item =subMenu.find(element=>(element.subMenu_id === item.subMenu_id && element.actions_name === 'Edit'))
    let d_item = subMenu.find(element=>(element.subMenu_id === item.subMenu_id && element.actions_name === 'Delete'))
    actionArray.push({
      "menu":item.subMenu_name,
      "view_id": v_item? v_item.actions_id: null,
      "create_id": c_item? c_item.actions_id: null,
      "edit_id": e_item? e_item.actions_id: null,
      "delete_id": d_item? d_item.actions_id: null
    })
  })

  const columns = [
    {
      field: 'menu',
      headerName: '',
      flex:2 ,
    },
    {
      field: 'View',
      flex:1,
      renderHeader: () => (
       actionName.includes('View')? <span>View</span>: ''
      ),
      renderCell: (params) => (
        // params.row.view_id ? (editActions.includes(params.row.view_id) ?
        // <Checkbox defaultChecked={true} onChange={(e) => { handleEditChange( params.row.view_id) }}/> 
        // :
        // <Checkbox onChange={(e) => { handleEditChange(params.row.view_id) }}/>)
        // : '' 
        params.row.view_id ? 
        <Checkbox checked={editActions.includes(params.row.view_id)} onChange={(e) => { handleEditChange( params.row.view_id) }}/> 
        : '' 
      ),
    },
    {
      field: 'Create',
      flex:1,
      renderHeader: () => (
        actionName.includes('Create')? <span>Create</span>: ''
      ),
      renderCell: (params) => (
        // params.row.create_id ?((editActions.includes(params.row.create_id))?
        // <Checkbox defaultChecked={true} onChange={(e) => { handleEditChange(params.row.create_id) }}/> 
        // :
        // <Checkbox  onChange={(e) => { handleEditChange(params.row.create_id) }}/>)
        // : ''
        params.row.create_id ?
        <Checkbox checked={editActions.includes(params.row.create_id)}  onChange={(e) => { handleEditChange(params.row.create_id) }}/>
        : ''
      ),
    },
    {
      field: 'Edit',
      flex:1,
      renderHeader: () => (
        actionName.includes('Edit')? <span>Edit</span>: ''
      ),
      renderCell: (params) => (
        // params.row.edit_id ?((editActions.includes(params.row.edit_id))?
        // <Checkbox defaultChecked={true} onChange={(e) => { handleEditChange(params.row.edit_id) }}/> 
        // :
        // <Checkbox  onChange={(e) => { handleEditChange(params.row.edit_id) }}/>)
        // : ''
        params.row.edit_id ?
        <Checkbox checked={editActions.includes(params.row.edit_id)}  onChange={(e) => { handleEditChange(params.row.edit_id) }}/>
        : ''
      ),
    },
    {
      field: 'Delete',
      flex:1,
      renderHeader: () => (
        actionName.includes('Delete')? <span>Delete</span>: ''
      ),
      renderCell: (params) => (
        // params.row.delete_id ?(editActions.includes(params.row.delete_id))?
        // <Checkbox defaultChecked={true} onChange={(e) => { handleEditChange(params.row.delete_id) }}/> 
        // :
        // <Checkbox  onChange={(e) => { handleEditChange(params.row.delete_id) }}/> 
        // : ''
        params.row.delete_id ?
        <Checkbox checked={editActions.includes(params.row.delete_id)}  onChange={(e) => { handleEditChange(params.row.delete_id) }}/>
        : ''
      ),
    }
  ];

  return (
    <Box sx={{
      "& .MuiDataGrid-root": {
        // borderColor: " rgb(90, 88, 88)",
        // fontSize: '12px',
        borderRadius: '10px',
        backgroundColor: 'white',

      },
      "& .MuiDataGrid-columnHeaders": {
        // backgroundColor: '#489C8F',
        // color: 'white',
        // fontSize: '16px'
      },
    }}>
      <DataGrid
        columnHeaderHeight={35}
        rowHeight={35}
        rows={actionArray}
        columns={columns}
        hideFooter={true}
        getRowId={(row) => row.view_id}
        // getRowId={(row) => generateRandom()}
        disableRowSelectionOnClick
        // disableColumnMenu 
        slots={{ columnMenu: CustomColumnMenu }}

      />
    </Box>
  )
}

export default PermissionEditTable