import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Card, Divider, Typography, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../components/styles/theme';
import dayjs from 'dayjs'
import { useSelector } from 'react-redux';
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from '../../api/HandleApiErrors';
import { useTranslation } from 'react-i18next';
import { getPermittedBranch } from '../../api/commonApi';
import { changeCurrencyFormat, changeFormat, dateConvert, getMonthRange } from '../../HelperFile';
import TabularHeader from '../../components/TabularHeader';
import muiStyles from '../../components/styles/muiStyles';
import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';
import * as transactionreportApi from '../../api/transactionreportApi';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import AntSwitch from '../../components/styles/AntSwitch';
import { getFyrByCompany } from '../../api/fyrApi';

function DebitNote() {
  const { t } = useTranslation()
  const isFyr = useRef(false)
  const isBranchList = useRef(false)
  const initialLoading = useRef(false)
  const abortConstants = useSignalAbort()
  const token = useSelector(state => state.token)
  const user = useSelector(state => state.user)
  const selectedDetails = useSelector(state => state.selectedParameters)
  const company = user?.company
  const companyId = user?.companyid
  const [branchList, setBranchList] = useState()
  const [branchSelect, setbranchSelect] = useState()
  const [branchSelectSearch, setbranchSelectSearch] = useState()
  const [fyrList, setFyrList] = useState()
  const [fyrSelect, setFyrSelect] = useState('');
  const [selectedFyr, setSelectedFyr] = useState()
  const [toDate, setTodate] = useState()
  const [frmDate, setFrmdate] = useState()
  const [frDate, setFrDate] = useState()
  const [tDate, setTDate] = useState()
  const [branchName, setBranchName] = useState()
  const [isLoading, setIsLoading] = useState({})
  const [voucherSelect, setVoucherSelect] = useState('');
  const [Vouchertype, setVouchertype] = useState()
  const [transaction, setTransaction] = useState()
  const [totalAmount, setTotalAmount] = useState()
  const [isDetailedView, setIsDetailedView] = useState(false);


  //display message if default branch is not set to the user
  useEffect(() => {
    if (user && !user?.branchid) {
      toast.error("A default branch has not been assigned to you. Please contact your administrator", {
        autoClose: 3000, // Close the toast after 5 seconds
      });
    }
  }, [user])

  useEffect(() => {
    const fetchData = async () => {
      if (!token || !companyId || !user) return;

      if (!isFyr.current) {
        console.log("Fetching financial year data...");
        await getFyrByCriteria(companyId, abortConstants.controllerConfig);
        isFyr.current = true;
      }

      if (!isBranchList.current) {
        console.log("Fetching branch data...");
        await getBranch(user.userid, abortConstants.controllerConfig);
        isBranchList.current = true;
      }

      if (branchSelect && selectedFyr) {
        console.log("Fetching voucher type...");
        const frm = dateConvert(dayjs(selectedFyr.Fyrfrom));
        const to = dateConvert(dayjs(selectedFyr.Fyrto));
        await getVoucherType(branchSelect, frm, to, 'Debit Note', abortConstants.controllerConfig);
      }

      if (branchSelect && frmDate && toDate && !initialLoading.current) {
        console.log("Fetching all data...");
        await getAllData(branchSelect, frmDate, toDate, '', 'DebitNote', abortConstants.controllerConfig);
        initialLoading.current = true;
      }
    };

    fetchData();

    return () => {
      console.log("Cleanup");
      abortConstants.controller.abort();
      toast.dismiss();
    };
  }, [token, companyId, user, branchSelect, selectedFyr, frmDate, toDate]);

  // useEffect(() => {
  //   console.log("useeffect");
  //   if (token && companyId && isFyr.current === false) {
  //     console.log("fyr");
  //     getFyrByCriteria(companyId, abortConstants.controllerConfig)
  //   }

  //   return () => {
  //     console.log("inside cleanuo");
  //     // Cancel the request before the component unmounts
  //     abortConstants.controller.abort()
  //     toast.dismiss()
  //   };
  // }, [token, companyId])

  // //get branch and financial year list
  // useEffect(() => {
  //   if (token && user && isBranchList.current === false ) {
  //     console.log("useeffect");
  //     getBranch(user?.userid, abortConstants.controllerConfig)
  //   }

  //   return () => {
  //     console.log("inside cleanup");
  //     // Cancel the request before the component unmounts
  //     abortConstants.controller.abort()
  //     toast.dismiss()
  //   };
  // }, [token, user])

  //   //get branch and financial year list
  //   useEffect(() => {
  //     if (branchSelect && selectedFyr) {
  //       console.log("useeffect");
  //       let frm = dateConvert(dayjs(selectedFyr.Fyrfrom))
  //       let to = dateConvert(dayjs(selectedFyr.Fyrto))
  //       getVoucherType(branchSelect, frm, to, 'Payment', abortConstants.controllerConfig)
  //     }
  
  //     return () => {
  //       console.log("inside cleanup");
  //       // Cancel the request before the component unmounts
  //       abortConstants.controller.abort()
  //       toast.dismiss()
  //     };
  //   }, [branchSelect, selectedFyr])

  // //call all api to get data
  // useEffect(() => {
  //   if (branchSelect && frmDate && toDate  && initialLoading.current === false) {
  //     console.log(frmDate, toDate);
  //     getAllData(branchSelect, frmDate, toDate, "", "DebitNote", abortConstants.controllerConfig)
  //     initialLoading.current = true
  //   }
  //   return () => {
  //     console.log("inside cleanuo");
  //     // Cancel the request before the component unmounts
  //     abortConstants.controller.abort()
  //     toast.dismiss()
  //   };
  // }, [branchSelect, frmDate, toDate])

  const getFyrByCriteria = async (id, abortToken) => {
    try {
      const result = await getFyrByCompany(id, abortToken);
      setFyrList(result?.data?.FinancialYear);
      isFyr.current = true;

      const fYear = selectedDetails?.selectedFyr
        ? result?.data?.FinancialYear?.find(item => item.Fyrname === selectedDetails?.selectedFyr)
        : result?.data?.FinancialYear?.find(item => item.Iscurrent === 1);

      const fromDate = selectedDetails?.selectedFromDate || getMonthRange(fYear?.Fyrfrom, fYear?.Fyrto, dayjs().month())[0];
      const toDate = selectedDetails?.selectedToDate || getMonthRange(fYear?.Fyrfrom, fYear?.Fyrto, dayjs().month())[1];

      setSelectedFyr(fYear);
      setFyrSelect(fYear?.Fyrname);
      setFrmdate(dayjs(fromDate));
      setTodate(dayjs(toDate));
    } catch (error) {
      handleApiErrors(error, 'Financial Year', 'fyr_response', 'fyr_timeout', 'fyr_request', setIsLoading);
    }
  };

  const getBranch = async (userId, abortToken) => {
    try {
      const result = await getPermittedBranch(userId, abortToken);
      isBranchList.current = true;
      if (result?.data?.branch.length !== 0) {
        setBranchList(result?.data?.branch);
        const selectedBranchId = selectedDetails?.selectedBranch || (user?.branchid || result?.data?.branch[0]?.branchid);
        const selectedBranchName = result.data?.branch
          .filter(item => item.branchid === selectedBranchId)
          .map(item => item.branchname);

        setbranchSelect(selectedBranchId);
        setBranchName(selectedBranchName);
      } else {
        toast.error(result?.data?.message, {
          autoClose: 3000,
        });
      }
    } catch (error) {
      handleApiErrors(error, 'Branch', 'branch_response', 'branch_timeout', 'branch_request', setIsLoading);
    }
  };

  const getAllData = (branchId, frm, to, voucher, transaction, abortToken) => {
    setFrDate(changeFormat(frm, user?.company?.DateFormatname))//change the format of from date
    setTDate(changeFormat(to, user?.company?.DateFormatname))//change the format of to date
    let fDate = dateConvert(frmDate)
    let tDate = dateConvert(toDate)
    getTransaction(branchId, fDate, tDate, voucher, transaction, abortToken)
  }

  //voucher List
  const getVoucherType = async (branchId, frm, to, type, abortToken) => {
    console.log(branchId, frm, to, type);
    try {
      const result = await transactionreportApi.getVoucherType(branchId, frm, to, type, abortToken)
      console.log("voucher", result);
      setVouchertype(result?.data?.Vouchers)
      console.log("Vocuchers", result?.data?.Vouchers);
      // setVoucherSelect(result?.data?.Vouchers[0]?.VoucherTypeName)
    }
    catch (error) {
      handleApiErrors(error, 'VoucherType', 'voucher_response', 'voucher_timeout', 'voucher_request', setIsLoading)
    }
  }
  //Transation Sales
  const getTransaction = async (branchId, frm, to, voucher, transaction, abortToken) => {
    console.log(branchId, frm, to, voucher);
    try {
      const result = await transactionreportApi.getTransaction(branchId, frm, to, voucher, transaction, abortToken);
      console.log("Transaction", result);

      /// Check if transaction is defined before calculating totalAmount
      const total = result?.data?.Transaction
        ? result?.data?.Transaction.reduce((total, row) => total + row.NetAmount, 0)
        : 0;
      setTotalAmount(changeCurrencyFormat(total, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL"))
      const totalRow = {
        date: "Total Amount:", // You can use a specific label for the total row
        netamount: total,
      };

      // Calculate TaxAmount and update the transaction array
      const updatedTransaction = result?.data?.Transaction.map(item => ({
        ...item,
        // TaxAmount: item.CGST + item.SGST,
        TaxAmount: changeCurrencyFormat(item.CGST + item.SGST, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL"),
        TaxableValue: changeCurrencyFormat(item.TaxableValue, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL"),
        NetAmount: changeCurrencyFormat(item.NetAmount, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL"),
        VoucherDate: item.VoucherDate? changeFormat(dayjs(item.VoucherDate), user?.company?.DateFormatname): '',

      }));

      // const updatedRows = transaction ? [...updatedTransaction, totalRow] : [];


      // Set the updated array in state
      setTransaction(updatedTransaction);
    } catch (error) {
      handleApiErrors(error, 'Transaction', 'transaction_response', 'transaction_timeout', 'transaction_request', setIsLoading);
    }
  };

  //for search
  const handleSearchClick = (branchId, frm, to, voucherData) => {
    console.log(branchId, frm, to, voucherData);
    toast.dismiss()
    setTransaction(null)

    setIsLoading({})
    getAllData(branchId, frm, to, voucherData, "DebitNote", abortConstants.controllerConfig)
  }

  //for loading circular progress
  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`] ||
      isLoading.branch_response || isLoading.branch_request || isLoading.branch_timeout ||
      isLoading.fyr_response || isLoading.fyr_request || isLoading.fyr_timeout) {
      return true
    }
    else {
      return false
    }
  }

  // to disable/enable search button
  const isLoadingTrue = () => {
    const state = (Object.keys(isLoading).length === 0) && ('')
    return state
  }

  const handleExport = () => {
    if (transaction && transaction.length > 0) {
      // Extracting only required columns
      const dataToExport = transaction.map(({ VoucherDate, CustomerName, VoucherType, VoucherNo, NetAmount }) => ({
        VoucherDate,
        CustomerName,
        VoucherType,
        VoucherNo,
        NetAmount
      }));

      // Calculate total amount
      const totalAmountRow = {
        VoucherDate: 'Total Amount:',
        CustomerName: '',
        VoucherType: '',
        VoucherNo: '',
        NetAmount: totalAmount // Include the total amount here
      };

      // Create a worksheet
      const ws = XLSX.utils.json_to_sheet([...dataToExport, totalAmountRow]);

      // Create a workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'DebitNote');

      // Save the file
      XLSX.writeFile(wb, 'DebitNote.xlsx');
    } else {
      toast.warning('No data available for export.');
    }
  };



  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container spacing={2}>
          {/* header */}
          {Vouchertype && (
            <TabularHeader
              branchName={branchName}
              frDate={frDate}
              tDate={tDate}
              title="Debit Note"
              companyName={company.companyname}
              isLoadingTrue={isLoadingTrue}
              branchSelect={branchSelect}
              setbranchSelect={setbranchSelect}
              branchList={branchList}
              handleSearchClick={handleSearchClick}
              setbranchSelectSearch={setbranchSelectSearch}
              setBranchName={setBranchName}
              frmDate={frmDate}
              toDate={toDate}
              setFrmdate={setFrmdate}
              setTodate={setTodate}
              fyrList={fyrList}
              fyrSelect={fyrSelect}
              setFyrSelect={setFyrSelect}
              selectedFyr={selectedFyr}
              setSelectedFyr={setSelectedFyr}
              optionTitle="Voucher Type"
              optionList={Vouchertype}
              optionSelect={voucherSelect}
              setOptionSelect={setVoucherSelect}
              optionKey="VoucherTypeName"
              exportExcel={handleExport}
            />
          )}
          {/* Grid display Principle head graph */}
          <Grid item xs={12}>
            <Box>

              <Grid container alignItems="center" justify="space-between">

                {/* <Grid item xs={6}>  
    <TabExtraFilter
      isLoadingTrue={isLoadingTrue}
      branchSelect={branchSelect}
      frmDate={frmDate}
      toDate={toDate}
      handleSearch={handleSearchClick}
      optionTitle="Voucher Type"
      optionList={Vouchertype}
      optionSelect={voucherSelect}
      setOptionSelect={setVoucherSelect}
      optionKey="VoucherTypeName"
    />
  </Grid> */}

                <Grid item xs={12} container justifyContent="flex-end">
                  {/* Consolidated/Detailed Switch */}
                  <Grid item display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body2" style={{ marginRight: '4px', color: '#1F77B4' }}>
                      Consolidated
                    </Typography> &nbsp; &nbsp;
                    <AntSwitch
                      checked={isDetailedView}
                      onChange={() => setIsDetailedView(!isDetailedView)}

                    /> &nbsp; &nbsp;
                    <Typography variant="body2" style={{ marginLeft: '4px' }}>
                      Detailed
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {transaction ? (
                <Box  >
                  <div >
                    <>
                      {transaction.length !== 0 ? (
                        <TableContainer component={Paper} sx={{ paddingLeft: '20px', paddingRight: '20px', maxHeight: 500, overflow: 'auto', marginTop: '15px' }}>
                          <Table style={{ marginTop: '30px', width: '100%' }} aria-label="spanning table">

                            <TableHead >
                              <TableRow >
                                <TableCell style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Date</TableCell>
                                <TableCell align='left' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Account Name</TableCell>
                                <TableCell align='left' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>VoucherType</TableCell>

                                <TableCell align='center' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderRight: '1px solid #ddd', borderBottom: 'none' }}>VoucherNo</TableCell>

                                <TableCell align='right' style={{ position: 'sticky', top: 0, color: '#489C8F', fontWeight: 'bold', background: '#fff', borderBottom: 'none' }}> Amount</TableCell>

                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {transaction.map((row) => (
                                <TableRow key={row.VoucherNo}  >
                                  <TableCell style={{  borderRight: '1px solid #ddd' }}>{row.VoucherDate}</TableCell>
                                  <TableCell align="left" style={{  borderRight: '1px solid #ddd' }}>{row.CustomerName}
                                    {isDetailedView && <div style={{ fontSize: '12px', color: 'gray' }}>
                                      {row.Narration}</div>}
                                  </TableCell>
                                  <TableCell align="left" style={{  borderRight: '1px solid #ddd' }}>{row.VoucherType}</TableCell>

                                  <TableCell align="center" style={{  borderRight: '1px solid #ddd' }}>{row.VoucherNo}</TableCell>


                                  <TableCell align="right" >{row.NetAmount}</TableCell>

                                </TableRow>
                              ))}




                              <TableRow >
                                <TableCell style={{ borderBottom: 'none' }} />
                                <TableCell style={{ borderBottom: 'none' }} />
                                <TableCell style={{ borderBottom: 'none' }} />

                                <TableCell colSpan={1} align='center' style={{ color: '#489C8F', fontWeight: 'bold' }}> Total Amount :</TableCell>

                                <TableCell align="right" style={{ fontWeight: 'bold' }}> {totalAmount}</TableCell>
                              </TableRow>



                            </TableBody>
                          </Table>
                        </TableContainer>

                      ) : (
                        <h4 style={{ textAlign: "center" }}>No data</h4>
                      )}
                    </>
                  </div>

                </Box>
              ) : (

                <>
                  {renderLoading("transaction") ? (
                    <h5 style={{ textAlign: "center" }}>No data</h5>
                  ) : (
                    <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </>

              )


              }



            </Box>
          </Grid>



        </Grid>
      </Box>
    </ThemeProvider>
  );
}

export default DebitNote


