import axiosInstance from "./axiosInstance";

let api = axiosInstance()

export const getBranchById = (id, token) => {
    return api.get(`/branch/${id}`);
  }


export const getBranch = (token, abort) => {
    return api.get("/branch", abort);
  }


export const createBranch =(data, token)=>{
    return api.post(`/branch`, data)
}

export const updateBranch =(id, data, token)=>{
    return api.put(`/branch/${id}`, data)
}

export const deleteBranch =(id, token)=>{
    return api.delete(`/branch/${id}`)
}

export const getTallyBranches =()=>{
  return api.get(`/TallyBranchs`)
}