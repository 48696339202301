import React from 'react'
import { Box, Grid, Typography, Button, Tooltip, IconButton } from '@mui/material'
import Header from './Header'
import RefreshIcon from '@mui/icons-material/Refresh';

function NotSync({getOnboardingStep}) {
    return (
        <Box>
            <Header />
            {/* <Box display='flex' justifyContent='flex-end'>
                <Tooltip title='If data synced, please refresh'>
                    <IconButton onClick={()=>getOnboardingStep()}><RefreshIcon className='common-icon'/></IconButton>
                </Tooltip>
            </Box> */}
            <Box height="100vh" display="flex" alignItems="center" justifyContent="center">
                <Grid container spacing={1}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} display='flex' justifyContent='center' >
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent='center'>
                            <img src={process.env.PUBLIC_URL + '/assets/images/rafiki.png'} alt='not found' width={150} height={100} />
                            <Typography variant='h6' mt={3}>Please connect your Tally data with RCOR Connector</Typography>
                            <Typography mt={2}>To make sure you get the most out of our platform, please connect your Tally
                                data to the RCOR connector.  </Typography>
                            <Typography mb={3}>This simple step keeps your data current and unlocks all our features for you.</Typography>
                            <Typography >If your Tally data already connected, please refresh
                            <IconButton onClick={()=>getOnboardingStep()}><RefreshIcon className='common-icon'/></IconButton>
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default NotSync