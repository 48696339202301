import React from 'react'
import { Typography, Card, CardContent, Box, Grid, } from '@mui/material';
import { useSelector } from 'react-redux';
import {convertCurrency } from '../HelperFile';

function ProfitLossCard({ title, value, unit }) {
    const user = useSelector(state => state.user)
    const company = user?.company

    return (
            <Card sx={{
                width: '100%', borderRadius: '10px', height: '90px', transition: 'transform 0.3s',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.4)'}}>
                <CardContent >
                    <Grid container>
                        <Grid item xs={12}>
                                <Typography sx={{ fontSize: '16px', color: '#1F77B4', fontWeight: 500, lineHeight: "19.36px" }} gutterBottom>
                                    {title}
                                </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <span style={{fontSize: '24px',  fontWeight: 500, lineHeight: "29.05px"}}> {value}</span>&nbsp;&nbsp;
                            <span style={{fontSize: '18px'}}>{unit}</span>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
    )
}

export default ProfitLossCard