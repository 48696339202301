import axiosInstance from "./axiosInstance";

let api = axiosInstance()

 
export const getCashBook = async(branchId, frm, to, ledger, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/MISReports/CashBook?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&LedgerName=${ledger}`, config)
}

 
export const getBankBook = async(branchId, frm, to, ledger, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/MISReports/BankBook?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&LedgerName=${ledger}`, config)
}

export const getLedgerBook = async(branchId, frm, to, ledger, abortToken)=>{
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/MISReports/LedgerBook?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&LedgerName=${ledger}`, config)
}

// Ledger type 
export const getLedgerType = async(branchId, frm, to, parent, abortToken)=>{
  console.log(branchId, frm, to);
  const config = {
      ...abortToken,
      timeout: 30000, // Set the timeout for this specific request
    };
  return  await api.get(`/Ledger?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Parent=${parent}`, config)
}