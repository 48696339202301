import React from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../components/styles/theme';
import { Outlet, useNavigate } from 'react-router-dom';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function ProfitLoss() {
    const navigate = useNavigate()
    const [value, setValue] = React.useState();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        // Navigate to the corresponding route when a tab is clicked
        switch (newValue) {
            case 0:
                navigate('/P&L');
                break;
            case 1:
                navigate('/trial_balance');
                break;
            case 2:
                navigate('/balance_sheet');
                break;
            default:
                navigate('/P&L');
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="P & L" {...a11yProps(0)} />
                        <Tab label="Trial Balance" {...a11yProps(1)} />
                        <Tab label="Balance Sheet" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <Box>
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default ProfitLoss