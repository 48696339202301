import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem("token");
    const menu = useSelector(state => state.menu)
    let location = useLocation();

    // Redirect to login if the user is not authenticated 
    if (!token) {
        return <Navigate to="/loginpage" state={{ from: location }} replace />
    }
    // Check if the requested route is in the user's menu array
    const requestedRoute = location.pathname.replace('/', ''); // Remove leading '/'
    const isRouteAllowed = menu.some(item => item.subMenu_route === requestedRoute);

    if (!isRouteAllowed) {
        // Redirect to Page Not Found if the requested route is not in the user's menu
        return <Navigate to="/*" />;
    }

    return children
};

export default PrivateRoute;