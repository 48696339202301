import axiosInstance from "./axiosInstance";

let api = axiosInstance()

export const getProfitLoss = async (branchId, frm, to, head, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 60000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/GetProfitandLoass?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Head=${head}`, config)
}

export const getTrialBalance = async (branchId, frm, to, head, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 90000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/GetTrailBalance?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Head=${head}`, config)
}

export const getBalanceSheet = async (branchId, frm, to, head, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 90000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/GetBalancesheet?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Head=${head}`, config)
}