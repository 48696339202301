import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  Modal,
  IconButton,
  Tooltip,
  Stack, Grid,
  Typography,
  CircularProgress
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridColumnMenu } from '@mui/x-data-grid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useSelector } from 'react-redux';
import * as UserApi from '../../api/userApi'
import muiStyles from '../../components/styles/muiStyles';
import UserForm from '../../components/forms/UserForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuColumnsItem`
        columnMenuColumnsItem: null,
        columnMenuSortItem: null,
      }}
    />
  );
}

let userDeleteId

function UserManagement() {

  const [userOpen, setUserOpen] = useState(false);
  const [openModal, setOpenmodal] = useState(false);
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [userList, setUserList] = useState()
  const [userId, setUserId] = useState()

  const token = useSelector(state => state.token)
  const menu = useSelector(state => state.menu)
  const operations = menu?.filter(item =>item.subMenu_name === 'Users').map(item=>item.Action_CurdNo)

  //load all user list
  const getAllUsers = async () => {
    try {
      const result = await UserApi.getUsers(token)
      result.data.User?.forEach(item => {
        item.isactive = item.isactive === 1 ? 'Yes' : 'No'
      })
      setUserList(result.data.User)
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllUsers()
  }, [userOpen, openModal, openDeleteModal])

  //to pass branch id to delete modal
  const deleteModal = (id) => {
    userDeleteId = id
    handleDeleteOpen();
  }

  //to pass row data to edit modal from datatgrid
  const editmodal = async (user_id) => {
    setUserId(user_id)
    handleOpenmodal()
  }

  const handleUserOpen = () => setUserOpen(true);
  const handleUserClose = () => setUserOpen(false);

  //for edit branch modal
  const handleOpenmodal = () => setOpenmodal(true);
  const handleClosemodal = () => {
    setOpenmodal(false)
  };

  //for delete branch modal
  const handleDeleteOpen = () => setopenDeleteModal(true);
  const handleDeleteClose = () => setopenDeleteModal(false);

  //api call to delete a branch
  const deleteBranch = async () => {
    try {
      const result = await UserApi.deleteUser(userDeleteId, token)
      alert(result.data.message)
    }
    catch (error) {
      console.log(error);
    }
    handleDeleteClose()

  }

  const userCreate = (message) => {
    handleUserClose()
    toast.success(message, {
      autoClose: 3000, // Close the toast after 5 seconds
    });
  };

  const userUpdate = (message) => {
    handleClosemodal()
    toast.success(message, {
      autoClose: 3000, // Close the toast after 5 seconds
    });
  };

  const cancelUserOperation = (edit) => {
    if (edit) {
      handleClosemodal()
    }
    else {
      handleUserClose()
    }
  };

  //---------define columns for data grid-----------------
  const columns = [

    {
      field: 'code',
      headerName: 'Code',
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'firstname',
      headerName: 'First Name',
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      flex: 0.9,
      editable: false,
      sortable: false,
    },
    {
      field: 'email',
      headerName: 'Email ID',
      flex: 1.4,
      editable: false,
      sortable: false,
    },
    {
      field: 'mobilenumber',
      headerName: 'Phone',
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'branchname',
      headerName: 'Branch',
      flex: 0.8,
      editable: false,
      sortable: false,
    },
    {
      field: 'departmentname',
      headerName: 'Department',
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'designationname',
      headerName: 'Designation',
      flex: 1.2,
      editable: false,
      sortable: false,
    },
    {
      field: 'usertypename',
      headerName: 'Role',
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'isactive',
      headerName: 'Is Active',
      flex: 0.8,
      editable: false,
      align: 'center',
      sortable: false,
    },

    {
      field: 'action', headerName: 'Actions', sortable: false, flex: 1, minWidth: 110, renderCell: (params) => {
        return (
          <Stack direction='row' spacing={1}>
            {operations?.includes(3)&&<Tooltip title="Edit">
              <IconButton className='icon edit' size='large' onClick={() => { editmodal(params.row.userid) }}>
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>}
           {operations?.includes(4)&& <Tooltip title="Delete">
              <IconButton className='icon delete' size='large' onClick={() => { deleteModal(params.row.userid) }}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>}
          </Stack>
        );
      }
    }
  ];
  //-----------------------END ----------------------------------------------

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <span style={{ color: '#489C8F', fontSize: '18px', fontWeight: '500' }}>User Details</span>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {operations?.includes(2)&&<Button sx={{ height: '30px', mr: 1, borderRadius: '5px' }} className='btn btn-text-style'  onClick={handleUserOpen} variant="contained">
                <AddIcon />New User</Button>}
              {/* <Tooltip title='Help'><IconButton onClick={handleHelpOpen}><HelpCenterIcon color='warning' /></IconButton></Tooltip> */}
            </Box>
          </Box>
          
          {/* modal to add branch */}
          <Modal
            open={userOpen}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={muiStyles.modalStyle.root}>
              <UserForm onUserCreated={userCreate} onCancel={cancelUserOperation} />
            </Box>
          </Modal>

          {/* modal to edit user */}
          <Modal
            open={openModal}
            // onClose={handleClosemodal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={muiStyles.modalStyle.root}>
              <UserForm isEdit userId={userId} onUserUpdated={userUpdate} onCancel={cancelUserOperation} />
            </Box>
          </Modal>

          {/* Modal for delete user */}
          <Modal
            open={openDeleteModal}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={muiStyles.modalStyle.root}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Confirm delete?
              </Typography>
              <div style={{ display: 'flex', justifyContent: 'right', marginTop: '10px' }}>
                <Button sx={{ marginRight: '20px' }} type="submit" variant="contained" color="success" onClick={() => { handleDeleteClose() }}>
                  CANCEL
                </Button>
                <Button variant="contained" color="error" onClick={() => { deleteBranch() }}>
                  DELETE
                </Button>
              </div>
            </Box>
          </Modal>

          {/* </Box> */}
        </Grid>
        <Grid item xs={12}>


          {/* display all branches in data grid */}
          {userList ?
            <>
              {
                userList?.length !== 0 ?
                  <div style={{ width: '100%' }}>
                      <DataGrid
                       sx={{
                        backgroundColor: 'white', 
                        fontSize: '13px', 
                        '& .MuiDataGrid-columnHeaders': {
                          color: '#489C8F',
                          fontWeight: 500,
                          fontSize: '14px'
                        },
                      }}
                        columnHeaderHeight={40}
                        rowHeight={38}
                        rows={userList}
                        columns={columns}
                        getRowId={(row) => row.userid}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        disableRowSelectionOnClick
                        slots={{ columnMenu: CustomColumnMenu }}
                      />
                  </div> :
                  <h5 style={{ textAlign: 'center' }}>No data</h5>
              }
            </> :
            (<Box sx={{ textAlign: 'center', marginTop: '20px' }}>
              <CircularProgress />
            </Box>)
          }

          {/* -------End of data grid--------- */}

        </Grid>

      </Grid>
    </Box>


  );
}

export default UserManagement;