import React, { useEffect, useState } from 'react'
import { Box, Grid, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Paper, Breadcrumbs, Link, Typography, TableFooter, CircularProgress, Tooltip, IconButton } from '@mui/material'
import { changeCurrencyFormat } from '../../HelperFile';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';

function DetailTable({ tableData, path, setPath, getDiggedDetail, renderLoading, detailData, close }) {
    const user = useSelector(state => state.user)
    const company = user?.company
    const [data, setData] = useState()
    const [totalDebit, setTotalDebit] = useState()
    const [totalCredit, setTotalCredit] = useState()
    const [net, setNet] = useState()

    useEffect(() => {
        const newData = tableData?.map((row) => ({
            ...row,
            Debit: row.value > 0 ? row.value : '',
            Credit: row.value < 0 ? row.value : ''
        }));
        setData(newData)

        // const netAmount = newData?.reduce((total, item) => {
        //     return total + item.Debit - item.Credit;
        // }, 0);

        const totDebit = newData?.reduce((total, item) => {
            return total + (Number(item.Debit) || 0);
        }, 0)
        setTotalDebit(totDebit)
        const totCredit = newData?.reduce((total, item) => {
            return total + (Number(item.Credit) || 0);
        }, 0)
        setTotalCredit(totCredit)

        const netAmt = Number(totDebit) === 0 ? Number(totCredit) : Number(totDebit) - Number(totCredit)
        setNet(netAmt)
    }, [tableData])

    const handleBreadcrumbClick = (event, index, transactionType) => {
        event.preventDefault();
        const newPath = path.slice(0, index + 1);
        setPath(newPath);
        getDiggedDetail(transactionType)
        // setTableData(Object.keys(getDataAtPath(newPath)));
    };

    const handleDiggingData = (transactionType) => {
        const newPath = [...path, transactionType];
        setPath(newPath);
        getDiggedDetail(transactionType)
        // setTableData(Object.keys(getDataAtPath(newPath)));
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Breadcrumbs aria-label="breadcrumb">
                                {path.map((key, index) => {
                                    return (detailData || renderLoading('profitloss') === true) ? (
                                        <Link
                                            key={index}
                                            underline="hover"
                                            color="inherit"
                                            href="#"
                                            onClick={(event) => handleBreadcrumbClick(event, index, key)}
                                        >
                                            {key}
                                        </Link>
                                    ) : (
                                        <span>
                                            <Typography key={key} color="textPrimary">
                                                {key}
                                            </Typography>
                                        </span>
                                    );
                                })}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item xs={1} display='flex' justifyContent='end'>
                            <Tooltip title={(detailData || renderLoading('profitloss') === true) ? 'Close' : 'Fetching data, please wait.....'}>
                                <IconButton className='icon delete' onClick={() => close()}><CloseIcon fontSize='small' /></IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    {data ? <>
                        {data.length !== 0 ?
                            <TableContainer component={Paper} sx={{ maxHeight: 500, position: 'relative' }}>
                                <Table size="small" stickyHeader aria-label="spanning table">
                                    <TableHead>
                                        <TableRow >
                                            <TableCell align="left" className="table tableCell-heading tableCellPadding">Particulars</TableCell>
                                            <TableCell align="right" className="table tableCell-heading tableCellPadding">Debit</TableCell>
                                            <TableCell align="right" className="table tableCell-heading tableCellPadding">Credit</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.map((item, index) => (
                                            <TableRow key={index} className='common-icon' onClick={() => handleDiggingData(item.transactionType)}>
                                                <TableCell>{item.transactionType}</TableCell>
                                                <TableCell align='right'>
                                                    {item.Debit && changeCurrencyFormat(item.Debit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}
                                                </TableCell>
                                                <TableCell align='right'>
                                                    {item.Credit && changeCurrencyFormat(item.Credit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                    <TableRow sx={{ position: 'sticky', bottom: 30, backgroundColor: '#fff', zIndex: 1 }}>
                                        <TableCell className="table tableCell-heading">Total</TableCell>
                                        <TableCell className='table tableCellBold' align="right">{changeCurrencyFormat(totalDebit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}</TableCell>
                                        <TableCell className='table tableCellBold' align="right">{changeCurrencyFormat(totalCredit, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}</TableCell>
                                    </TableRow>
                                    {/* <TableFooter sx={{ position: 'sticky', bottom: 0, backgroundColor: '#fff', zIndex: 1 }}> */}
                                    <TableRow sx={{ position: 'sticky', bottom: 0, backgroundColor: '#fff', zIndex: 1 }}>
                                        <TableCell className="table tableCell-heading">Net Amount</TableCell>
                                        <TableCell className='table tableCellBold' align="right">{net > 0 ? changeCurrencyFormat(net, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL") : ''}</TableCell>
                                        <TableCell className='table tableCellBold' align="right">{net < 0 ? changeCurrencyFormat(net, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL") : ''}</TableCell>
                                    </TableRow>
                                    {/* </TableFooter> */}
                                </Table>
                            </TableContainer>
                            :
                            <h5 style={{ textAlign: "center" }}>No data</h5>
                        }
                    </> :
                        <>
                            {renderLoading("profitloss") ? (
                                <h5 style={{ textAlign: "center" }}>No data</h5>
                            ) : (
                                <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                                    <CircularProgress />
                                </Box>
                            )}
                        </>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}

export default DetailTable