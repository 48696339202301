import React, { useEffect, useRef, useState } from 'react'
import { Box, Grid, Typography, CircularProgress } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../components/styles/theme';
import dayjs from 'dayjs'
import { useSelector } from 'react-redux';
import { useSignalAbort } from '../../api/useSignalAbort';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from '../../api/HandleApiErrors';
import { useTranslation } from 'react-i18next';
import { getPermittedBranch } from '../../api/commonApi';
import { changeFormat, dateConvert, changeCurrencyFormat } from '../../HelperFile';
import InventoryHeader from '../../components/InventoryHeader';
import * as XLSX from 'xlsx';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import * as inventoryStockReportApi from '../../api/inventoryStockReportApi';
import { getProductGroup } from '../../api/saleshomeApi';
//  import * as saleshomeApi from '../../api/saleshomeApi';
import InventoryExtraFilter from '../../components/InventoryExtraFilter'
import { getFyrByCompany } from '../../api/fyrApi';

function InventoryReg() {

  const { t } = useTranslation()
  const isBranchList = useRef(false)

  const initialLoading = useRef(false)
  const abortConstants = useSignalAbort()
  const token = useSelector(state => state.token)
  const user = useSelector(state => state.user)
  const companyId = user?.companyid
  const company = user?.company
  const isFyr = useRef(false);
  const [fyrList, setFyrList] = useState()
  const [fyrSelect, setFyrSelect] = useState('');
  const [fyrName, setFyrName] = useState()
  const [branchList, setBranchList] = useState()

  const [branchSelect, setbranchSelect] = useState(user?.branchid)
  const [branchSelectSearch, setbranchSelectSearch] = useState(user?.branchid)
  const [toDate, setTodate] = useState()
  const [selectedFyr, setSelectedFyr] = useState()
  const [frmDate, setFrmdate] = useState()
  const [frDate, setFrDate] = useState()
  const [tDate, setTDate] = useState()
  const [branchName, setBranchName] = useState()
  const [isLoading, setIsLoading] = useState({})
  const [groupSelect, setGroupSelect] = useState();
  const [Grouptype, setGrouptype] = useState()
  const [inventoryStock, setInventoryStock] = useState()

  //display message if default branch is not set to the user
  useEffect(() => {
    if (user && !user?.branchid) {
      toast.error("A default branch has not been assigned to you. Please contact your administrator", {
        autoClose: 3000, // Close the toast after 5 seconds
      });
    }
  }, [user])

  useEffect(() => {
    const fetchData = async () => {
      if (!token || !companyId || !user) return;

      if (!isFyr.current) {
        console.log("Fetching financial year data...");
        await getFyrByCriteria(companyId, abortConstants.controllerConfig);
        isFyr.current = true;
      }

      if (!isBranchList.current) {
        console.log("Fetching branch data...");
        await getBranch(user.userid, abortConstants.controllerConfig);
        isBranchList.current = true;
      }

      if (branchSelect && selectedFyr) {
        console.log("Fetching voucher type...");
        await getGroupType(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, abortConstants.controllerConfig);
      }

      if (branchSelect && selectedFyr  && !initialLoading.current) {
        console.log("Fetching all data...");
        await getAllData(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, '', abortConstants.controllerConfig)
        initialLoading.current = true;
      }
    };

    fetchData();

    return () => {
      console.log("Cleanup");
      abortConstants.controller.abort();
      toast.dismiss();
    };
  }, [token, companyId, user, branchSelect, selectedFyr]);

  //get branch and financial year list
  // useEffect(() => {
  //   if (token && user && isBranchList.current === false) {
  //     console.log("useeffect");

  //     console.log("User:", user);
  //     // let frm = dateConvert(frmDate)
  //     // let to = dateConvert(toDate)
  //     // console.log(  frm,  to);
  //     getBranch(user?.userid, abortConstants.controllerConfig)



  //   }

  //   if (token && companyId && isFyr.current === false) {
  //     getFyrByCriteria(companyId, abortConstants.controllerConfig);
  //   }

  //   return () => {
  //     console.log("inside cleanuo");
  //     // Cancel the request before the component unmounts
  //     abortConstants.controller.abort()
  //     toast.dismiss()
  //   };
  // }, [token])

  // //call all api to get data
  // useEffect(() => {
  //   if (branchSelect && frmDate && toDate && initialLoading.current === false) {
  //     console.log(frmDate, toDate);
  //     getGroupType(branchSelect, frmDate, toDate, abortConstants.controllerConfig)
  //     getAllData(branchSelect, frmDate, toDate, groupSelect.join(','), abortConstants.controllerConfig)
  //     initialLoading.current = true
  //   }
  //   return () => {
  //     console.log("inside cleanuo");
  //     // Cancel the request before the component unmounts
  //     abortConstants.controller.abort()
  //     toast.dismiss()
  //   };
  // }, [branchSelect, frmDate, toDate])



  const getBranch = async (userId, abortToken) => {
    console.log("inside branch latest");
    try {
      const result = await getPermittedBranch(userId, abortToken);//api call to get all branch
      console.log("dashbranch", result);
      isBranchList.current = true;
      if (result?.data?.branch.length !== 0) {
        setBranchList(result?.data?.branch);
        if (!user?.branchid) {
          // Set branchSelect to the first branch ID if user does not have a branch ID
          setbranchSelect(result?.data?.branch[0].branchid);
          // getGroupType(result?.data?.branch[0].branchid, frm, to, abortToken)

          const brName = result.data?.branch[0].branchname;
          setBranchName(brName);
        } else {
          // getGroupType(branchSelect.join(','), frm, to,   abortToken)
          const brName = result.data?.branch
            .filter(item => branchSelect === item.branchid)
            .map(item => item.branchname);
          setBranchName(brName);
        }
      }
      else {
        toast.error(result?.data?.message, {
          autoClose: 3000, // Close the toast after 5 seconds
        });
      }
    } catch (error) {
      handleApiErrors(error, 'Branch', 'branch_response', 'branch_timeout', 'branch_request', setIsLoading)
    }
  }

  const getFyrByCriteria = async (id, abortToken) => {
    console.log("inside fyr", id);
    try {
      const result = await getFyrByCompany(id, abortToken)
      console.log("fyr", result);
      setFyrList(result.data.FinancialYear)
      isFyr.current = true
      let fYear = result.data.FinancialYear.find(item => item.Iscurrent === 1)
      setSelectedFyr(fYear)
      setFyrSelect(fYear?.Fyrname)
      setFyrName(fYear?.Fyrname)
      setFrmdate(fYear?.Fyrfrom)
      setTodate(fYear?.Fyrto)
    }
    catch (error) {
      handleApiErrors(error, 'Financial Year', 'fyr_response', 'fyr_timeout', 'fyr_request', setIsLoading)
    }
  }


  const getAllData = (branchId, frm, to, productgroup, abortToken) => {
    setFrDate(changeFormat(dayjs(frm), user?.company?.DateFormatname))//change the format of from date
    setTDate(changeFormat(dayjs(to), user?.company?.DateFormatname))//change the format of to date
    // let fDate = dateConvert(frmDate)
    // let tDate = dateConvert(toDate)
    getInventoryStock(branchId, frm, to, productgroup, abortToken)

  }


  //voucher List
  const getGroupType = async (branchId, frm, to, abortToken) => {
    console.log(branchId, frm, to);
    try {
      const result = await getProductGroup(branchId, frm, to, abortToken)
      console.log("group", result);
      setGrouptype(result?.data?.ProductGroup)
      console.log("groups", result?.data?.ProductGroup);

    }
    catch (error) {
      handleApiErrors(error, 'GroupType', 'group_response', 'group_timeout', 'group_request', setIsLoading)
    }
  }


  //cashbook  
  const getInventoryStock = async (branchId, frm, to, productgroup, abortToken) => {
    console.log(branchId, frm, to,);
    try {
      //should be revised if error comes
      const result = await inventoryStockReportApi.getInventoryStock(branchId, frm, to, productgroup, abortToken)
      console.log("InventoryStock", result);


      setInventoryStock(result?.data?.StockRegister);
    }
    catch (error) {
      handleApiErrors(error, 'InventoryStock', 'inventoryStock_response', 'inventoryStock_timeout', 'inventoryStock_request', setIsLoading)
    }
  }



  //for search
  const handleSearchgroup = (group) => {
    // console.log(branchId, frm, to, groupData);
    toast.dismiss()
    setInventoryStock(null)
    setIsLoading({})
    getAllData(branchSelect, selectedFyr.Fyrfrom, selectedFyr.Fyrto, group?group:'', abortConstants.controllerConfig)
  }

  //for search
  const handleSearchClick = (branchId, frm, to) => {
    console.log(branchId, frm, to);
    toast.dismiss()
    setInventoryStock(null)
    setIsLoading({})
    getAllData(branchId, frm, to, groupSelect?groupSelect:'', abortConstants.controllerConfig)
  }

  //for loading circular progress
  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`] ||
      isLoading.branch_response || isLoading.branch_request || isLoading.branch_timeout) {
      return true
    }
    else {
      return false
    }
  }

  // to disable/enable search button
  const isLoadingTrue = () => {
    const state = (Object.keys(isLoading).length === 0) && (!inventoryStock)
    return state
  }

  const exportToExcel = () => {
    // Extract only the required fields from inventoryStock
    const dataToExport = inventoryStock.map(item => ({
      'Product Code': item.ProductCode,
      'Product Name': item.ProductName,
      'Description': item.Description,
      'Unit Name': item.UnitName,
      'MRP': changeCurrencyFormat(item.MRP, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL'),
      'Stock Qty': item.Units,
      'Cost Price': changeCurrencyFormat(item.CostPrice, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL'),
      'Stock Value': changeCurrencyFormat(item.Units * item.CostPrice, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')
    }));

    // Calculate Total Stock Value
    const totalStockValue = inventoryStock.reduce((total, row) => total + row.Units * row.CostPrice, 0);

    // Append Total Stock Value as a row to dataToExport
    dataToExport.push({
      'Product Code': 'Total Stock Value',
      'Stock Value': changeCurrencyFormat(totalStockValue, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')
    });

    const ws = XLSX.utils.json_to_sheet(dataToExport);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'Inventory Report.xlsx');
  };






  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Grid container spacing={2}>
          {/* header */}
          {branchList && fyrList && (
            <Grid item xs={12}  >
            <InventoryHeader
              branchName={branchName}
              frDate={frDate}
              tDate={tDate}
              isLoadingTrue={isLoadingTrue}
              branchSelect={branchSelect}
              setbranchSelect={setbranchSelect}
              branchList={branchList}
              handleSearch={handleSearchClick}
              setbranchSelectSearch={setbranchSelectSearch}
              setBranchName={setBranchName}
              frmDate={frmDate}
              toDate={toDate}
              setFrmdate={setFrmdate}
              setTodate={setTodate}
              fyrSelect={fyrSelect}
              setFyrSelect={setFyrSelect}
              fyrList={fyrList}
              setFyrName={setFyrName}
              selectedFyr={selectedFyr}
              setSelectedFyr={setSelectedFyr}
              optionTitle="Group selection"
              optionList={Grouptype}
              optionSelect={groupSelect}
              setOptionSelect={setGroupSelect}
              optionKey="ProductGroupName"
              exportExcel={exportToExcel}
              companyName={company.companyname}
              title="Inventory Register"
            />
            </Grid>
          )}


          <Grid item xs={12}  >
            <Box >

              <Grid container alignItems="center" justify="space-between">
                <Grid item xs={6}>
                  <InventoryExtraFilter
                    isLoadingTrue={isLoadingTrue}
                    branchSelect={branchSelect}
                    frmDate={frmDate}
                    toDate={toDate}
                    optionTitle="Group selection"
                    optionList={Grouptype}
                    optionSelect={groupSelect}
                    setOptionSelect={setGroupSelect}
                    optionKey="ProductGroupName"
                    handleSearch={handleSearchgroup}

                  />
                </Grid>
              </Grid>

              {/* from here renderLoading */}
              {inventoryStock ? (
                <Box p={2} >
                  <>
                    {inventoryStock.length !== 0 ? (
                      <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                        <Table size="small" stickyHeader aria-label="spanning table">
                          <TableHead >
                            <TableRow >
                              <TableCell align='left' style={{ color: '#489C8F', fontWeight: 'bold', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Sl.No</TableCell>
                              <TableCell align='left' style={{ color: '#489C8F', fontWeight: 'bold', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Product Code</TableCell>

                              <TableCell align='left' style={{ color: '#489C8F', fontWeight: 'bold', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Product Name</TableCell>
                              <TableCell align='left' style={{ color: '#489C8F', fontWeight: 'bold', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Description</TableCell>
                              <TableCell align='center' style={{ color: '#489C8F', fontWeight: 'bold', borderRight: '1px solid #ddd', borderBottom: 'none' }}>UnitName</TableCell>
                              <TableCell align='right' style={{ color: '#489C8F', fontWeight: 'bold', borderRight: '1px solid #ddd', borderBottom: 'none' }}>MRP</TableCell>

                              <TableCell align='center' style={{ color: '#489C8F', fontWeight: 'bold', borderRight: '1px solid #ddd', borderBottom: 'none' }}>Stock Qty</TableCell>
                              <TableCell align='right' style={{ color: '#489C8F', fontWeight: 'bold', borderRight: '1px solid #ddd', borderBottom: 'none' }}>CostPrice</TableCell>

                              <TableCell align='right' style={{ color: '#489C8F', fontWeight: 'bold', borderBottom: 'none' }}>Stock Value</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {inventoryStock.map((row, index) => (
                              <TableRow key={row.ProductCode}>
                                <TableCell style={{ borderRight: '1px solid #ddd' }}>{index + 1}</TableCell> {/* Serial number */}
                                <TableCell style={{ borderRight: '1px solid #ddd' }}>{row.ProductCode}</TableCell>
                                <TableCell align="left" style={{ borderRight: '1px solid #ddd' }}>{row.ProductName}</TableCell>
                                <TableCell align="left" style={{ borderRight: '1px solid #ddd' }}>{row.Descritpion}</TableCell>
                                <TableCell align="center" style={{ borderRight: '1px solid #ddd' }}>{row.UnitName} </TableCell>
                                <TableCell align="right" style={{ borderRight: '1px solid #ddd' }}>
                                  {changeCurrencyFormat(row.MRP, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')}
                                </TableCell>

                                <TableCell align="center" style={{ borderRight: '1px solid #ddd' }}>{row.Units}</TableCell>

                                <TableCell align="right" style={{ borderRight: '1px solid #ddd' }}>
                                  {changeCurrencyFormat(row.CostPrice, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')}
                                </TableCell>

                                <TableCell align="right" >
                                  {changeCurrencyFormat(row.Units * row.CostPrice, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')}
                                </TableCell>
                              </TableRow>
                            ))}


                            <TableRow >
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell colSpan={1} style={{ color: '#489C8F', fontWeight: 'bold' }}>Total Stock Value :</TableCell>
                              <TableCell />
                              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                                {/* Calculate and display the sum of NetAmount */}
                                {changeCurrencyFormat(inventoryStock.reduce((total, row) => total + row.Units * row.CostPrice, 0), company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), "No_SYMBOL")}
                              </TableCell>
                            </TableRow>


                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <h4 style={{ textAlign: "center" }}>No data</h4>
                    )}
                  </>

                </Box>
              ) : (
                <>
                  {renderLoading("journal") ? (
                    <h5 style={{ textAlign: "center" }}>No data</h5>
                  ) : (
                    <Box sx={{ textAlign: "center", marginTop: "20px" }}>
                      <CircularProgress />
                    </Box>
                  )}
                </>
              )}

              {/* upto here */}






            </Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>)
}

export default InventoryReg