import axiosInstance from "./axiosInstance";

let api = axiosInstance()

export const getLedgerUser = async (branchId,abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/LedgerGroup`, config)
  // return await api.get(`/LedgerGroup?BranchId=${branchId}`, config)
}

export const getLedger = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/LedgerGroup?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getLedgerHome = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/LedgerGroup?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&&Isreserved=Yes`, config)
}

export const getGroupSummary = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/NatureofGroupSummary?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getIncomeAndExpense = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/IncomeExpense?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getAssetLiability = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/AssetLaibility?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getCashAndBank = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/CashBank?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getSalesPurchase = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/SalesPurchase?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getTop10ProductSale = async (branchId, frm, to, product, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/Top10ProductSale?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&ProductGroup=${product}`, config)
}

export const getTop10CustomerSale = async (branchId, frm, to, product, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/Top10CustomerSale?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&ProductGroup=${product}`, config)
}

export const getStockInHand = async (branchId, frm, to, group, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/StockinHand?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&IsGroup=${group}`, config)
}

export const getGroupHeadMonth = async (branchId, frm, to, head, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/PrincipalHeads?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Head=${head}`, config)
}

export const getGroupHeadBranch = async (branchId, frm, to, head, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/PrincipalHeadsBranchWise?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Head=${head}`, config)
}

export const getGroupHeadBranchMonth = async (branchId, frm, to, head, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/PrincipalHeadsMonthBranchWise?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Head=${head}`, config)
}

export const getCashFlow = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/CashInOutSummary?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getCashFlowMonth = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/CashInOutMonthWise?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getYearwise = async (branchId, frm, to, head, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/ChartofAccountBranchYearWise?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&Head=${head}`, config)
}

export const getHighlihts = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/HighLights?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getPayablesRceivables = async (branchId, frm, to, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/PayableandReceivableSummary?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}`, config)
}

export const getPayablesRceivablesDetails = async (branchId, frm, to, type, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/PayableandReceivableDetails?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&&TransactionType=${type}`, config)
}

export const getIncomeExpenseComparison = async (branchId, frm, to, head, abortToken) => {
  const config = {
    ...abortToken,
    timeout: 30000, // Set the timeout for this specific request
  };
  return await api.get(`/FinancialAnalysis/NatureOfGroupBranchMonthWise?BranchId=${branchId}&FromDate=${frm}&ToDate=${to}&&Head=${head}`, config)
}