import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Checkbox, Grid, Paper, Popper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AntSwitch from '../../components/styles/AntSwitch';
import { CheckBox, Search } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomPopper = (props) => {
    return <Popper {...props} placement="bottom-start" />;
};

const CustomPaper = (props) => {
    return (
        <Paper {...props}>
            {props.children}
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px' }}>
                <Button
                    variant="contained"
                    // className="btn btn-search"
                    size="small"
                // onClick={() => setLedgerOpen(false)}
                >
                    Done
                </Button>
            </div>
        </Paper>
    );
};

function ProductFilter({
    branchSelect,
    handleSearch,
    isLoadingTrue,
    optionSelect,
    setOptionSelect,
    optionList,
    optionKey,
    optionId,
    isProduct,
    setIsProduct,
}) {
    // console.log(optionList);
    const { t } = useTranslation()
    const [inputValue, setInputValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const inputRef = useRef(null);

    useEffect(() => {
        if (optionSelect === null) {
            setInputValue('');
        }
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [optionSelect]);

    useEffect(() => {
        const input = inputValue?.toLowerCase();
        if (input) {
            setFilteredOptions(optionList?.filter(option =>
                option[optionKey].toLowerCase().startsWith(input) ||
                option['Descritpion'].toLowerCase().startsWith(input)
            ));
        } else {
            setFilteredOptions([]);
        }
    }, [inputValue, optionList, optionKey]);

    const search = (id) => {
        // handleSearch(isProduct?0:1, optionSelect.join(','))
        if (id) {
            handleSearch(isProduct ? 0 : 1, id)
        }

    }
    // console.log(inputValue);
    return (
        // <>{optionList && optionList.length !== 0 &&
            <Box className='box align'>
                <Box className='box flex flex-center'>
                    <Box className='' sx={{ maxHeight: '100px', overflow: 'auto', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.3)' }}>
                        <Autocomplete
                            id="option-select"
                            // PopperComponent={CustomPopper}
                            // PaperComponent={CustomPaper}
                            // disableCloseOnSelect
                            // multiple
                            options={filteredOptions||[]}
                            // value={optionSelect}
                            onChange={(event, newValue) => {
                                // setOptionSelect(newValue?.map(item => item[optionId]));
                                setOptionSelect(newValue ? newValue[optionId] : '')
                                search(newValue ? newValue[optionId] : '')
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newValue) => {
                                setInputValue(newValue)
                            }}
                            filterOptions={(options, params) => {
                                const input = params.inputValue ? params.inputValue.toLowerCase() : '';
                                return options?.filter(option =>
                                    option[optionKey].toLowerCase().startsWith(input) ||
                                    option['Descritpion'].toLowerCase().startsWith(input)
                                );
                            }}
                            getOptionLabel={(option) => `${option[optionKey]} (${option['Descritpion']})`}
                            renderOption={(props, option, { selected }) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <li key={option[optionId]} {...optionProps}>
                                        {/* <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        /> */}
                                        {option[optionKey]} ( {option['Descritpion']} )
                                    </li>
                                );
                            }}

                            freeSolo
                            renderInput={(params) => (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    borderRadius: '5px',
                                    width: '600px', backgroundColor: 'white', boxShadow: '0px 2px 4px rgba(0, 1, 0, 0.3)'
                                    ,
                                    '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': { border: 'none' }
                                }}
                                >
                                    <TextField {...params} variant="outlined" placeholder='Search' size="small" />
                                    {/* <IconButton onClick={search}>
                                        <Search />
                                    </IconButton> */}
                                </Box>
                            )}
                        />
                    </Box>
                </Box>
                {/* <Box>
                    <Box className='box flex' >
                        <Typography className='typography-switch size'>Product Group</Typography>&nbsp;&nbsp;
                        <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={isProduct} onChange={()=>setIsProduct(!isProduct)}/>&nbsp;&nbsp;
                        <Typography className='typography-switch size'>Product</Typography>
                    </Box>
                </Box> */}
            </Box>
        // }</>
    )
}

export default ProductFilter