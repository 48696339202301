import React from 'react'
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material'
import { styled } from '@mui/material/styles';
import { changeCurrencyFormat, changeFormat } from '../../HelperFile';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F1F1F1',
  },
  // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));

function InventoryTable({ data }) {
  const user = useSelector(state => state.user);
  const company = user?.company;

  return (
    <TableContainer component={Paper} >
      <Table size="small" stickyHeader aria-label="spanning table">
        <TableHead>
          <TableRow>
            <TableCell className="table tableCell tableCell-heading1">Transaction Name</TableCell>
            <TableCell className="table tableCell tableCell-heading1">Customer/Vendor</TableCell>
            <TableCell align='center' className="table tableCell tableCell-heading1">Date</TableCell>
            <TableCell align="right" className="table tableCell tableCell-heading1">Rate</TableCell>
            <TableCell align="center" className="table tableCell tableCell-heading1">Quantity</TableCell>
            <TableCell align="right" className="table tableCell tableCell-heading1">Total Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((item, index) => (
            <StyledTableRow key={index}>
              <TableCell className="table tableCell">{item.VoucherType}</TableCell>
              <TableCell className="table tableCell">{item.CustomerName}</TableCell>
              <TableCell align='center' className="table tableCell">{item.VoucherDate ? changeFormat(dayjs(item.VoucherDate), user?.company?.DateFormatname) : ''}</TableCell>
              <TableCell align="right" className="table tableCell">
                {changeCurrencyFormat(item.CostPrice, company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')}
              </TableCell>
              <TableCell align="center" className="table tableCell">{item.Quantity}</TableCell>
              <TableCell align="right" className="table tableCell">
                {changeCurrencyFormat((item.CostPrice * item.Quantity), company?.localeID, company?.currencyCode, Number(company?.decimalPlaces), 'No_SYMBOL')}
              </TableCell>
            </StyledTableRow>
          ))}
          {/* <TableRow  className="table tableRowBackground">
          <TableCell className="table tableCell">Highest value purchased from vendor</TableCell>
          <TableCell className="table tableCell">M/s. Force Motors Ltd</TableCell>
          <TableCell align="right" className="table tableCell">01/06/2024</TableCell>
          <TableCell align="right" className="table tableCell">23</TableCell>
          <TableCell align="right" className="table tableCell">13231.00</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="table tableCell">Highest sales to customer</TableCell>
          <TableCell className="table tableCell">Mr. A One Auto</TableCell>
          <TableCell align="right" className="table tableCell">01/06/2024</TableCell>
          <TableCell align="right" className="table tableCell">16</TableCell>
          <TableCell align="right" className="table tableCell">13231.00</TableCell>
        </TableRow>
        <TableRow className="table tableRowBackground">
          <TableCell className="table tableCell">Last purchase date</TableCell>
          <TableCell className="table tableCell">M/s. Force Motors Ltd</TableCell>
          <TableCell align="right" className="table tableCell">01/06/2024</TableCell>
          <TableCell align="right" className="table tableCell">30</TableCell>
          <TableCell align="right" className="table tableCell">13231.00</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className="table tableCell">Last sales date</TableCell>
          <TableCell className="table tableCell">Mr. A one Auto</TableCell>
          <TableCell align="right" className="table tableCell">01/06/2024</TableCell>
          <TableCell align="right" className="table tableCell">45</TableCell>
          <TableCell align="right" className="table tableCell">13231.00</TableCell>
        </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InventoryTable