// import React, { useEffect, useState } from 'react'
// import { Box, Grid, Typography, Button } from '@mui/material'
// import { useLocation, useNavigate } from 'react-router-dom'
// import Header from './Header'
// import { useSignalAbort } from '../api/useSignalAbort'
// import { handleApiErrors } from '../api/HandleApiErrors'
// import { onBoarding } from '../api/userApi'
// import { useSelector } from 'react-redux'

// // Import your components for each step
// import NotSync from './NotSync'
// import CompanyProfile from './CompanyProfile'
// import BranchProfile from './BranchProfile'
// import UserProfile from './UserProfile'

// function OnboardingHome() {
//     const navigate = useNavigate()
//     const localToken = localStorage.getItem("token")
//     const user = useSelector(state => state.user)
//     const abortConstants = useSignalAbort()
//     const [isLoading, setIsLoading] = useState({})
//     const [currentStep, setCurrentStep] = useState(null)

//     useEffect(() => {
//         if (localToken && user) {
//             getOnboarding()
//         } else {
//             navigate('/loginpage')
//         }

//         return () => {
//             // Cancel the request before the component unmounts
//             abortConstants.controller.abort()
//         }
//     }, [user, localToken])

//     // API call to get onboarding details
//     const getOnboarding = async () => {
//         try {
//             const result = await onBoarding(abortConstants.controllerConfig)
//             console.log(result);
//             // handleNavigation(result?.data)
//             if (result && result?.data?.OnboardingProcess) {
//                 // const onboardingStep = data.OnboardingProcess[0]?.Id
//                 setCurrentStep(result.data.OnboardingProcess[0]?.Id)
//             }
//         }
//         catch (error) {
//             handleApiErrors(error, 'OnBoarding', 'boarding_response', 'boarding_timeout', 'boarding_request', setIsLoading)
//         }
//     }

//     // Handle navigation based on onboarding process
//     // const handleNavigation = (data) => {
//     //     if (data && data.OnboardingProcess) {
//     //         const onboardingStep = data.OnboardingProcess[0]?.Id
//     //         setCurrentStep(onboardingStep)
//     //     }
//     // }

//     const getData =()=>{
//         console.log("onboarding");
//         getOnboarding()
//     }

//     // Render the component based on the current step
//     const renderStepComponent = () => {
//         switch (currentStep) {
//             case 0:
//                 return <NotSync getOnboardingStep={getData}/>
//             case 1:
//                 return <CompanyProfile getOnboardingStep={getData}/>
//             case 2:
//                 return <BranchProfile hideBackButton={true} getOnboardingStep={getData}/>
//             case 3:
//                 return <UserProfile hideBackButton={true} getOnboardingStep={getData}/>
//             case 4:
//                 navigate('/')
//                 return null
//             default:
//                 navigate('/loginpage')
//                 return null
//         }
//     }

//     return (
//         <Box>
//             <Header />
//             {/* {isLoading ? <Typography>Loading...</Typography> : renderStepComponent()} */}
//             {renderStepComponent()}
//         </Box>
//     )
// }

// export default OnboardingHome

import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Header from './Header'
import { useSignalAbort } from '../api/useSignalAbort'
import { handleApiErrors } from '../api/HandleApiErrors'
import { onBoarding } from '../api/userApi'
import { useSelector } from 'react-redux'

// Import your components for each step
import NotSync from './NotSync'
import CompanyProfile from './CompanyProfile'
import BranchProfile from './BranchProfile'
import UserProfile from './UserProfile'
import { getLedgerUser } from '../api/ledgerApi'

function OnboardingHome() {
    const navigate = useNavigate()
    const localToken = localStorage.getItem("token")
    const menu = useSelector(state => state.menu)
    console.log(menu);
    const user = useSelector(state => state.user)
    const abortConstants = useSignalAbort()
    const [isLoading, setIsLoading] = useState({})
    const [currentStep, setCurrentStep] = useState(null)

    // const getLedgerGroup = async (branchid) => {
    //     console.log(branchid);
    //     try {
    //       const result = await getLedgerUser(branchid)
    //       // const ledgerName = [...new Set(   (result?.data?.LedgerGroup)     .filter((item) => item.IsReserved === 'Yes')     .map((element) => element.ReservedName) )];
    //       console.log("ledger", result);
      
    //     }
    //     catch (error) {
    //       console.log(error);
    //     }
    //   }

    useEffect(() => {
        if (localToken && user) {
            getOnboarding()
            // getLedgerGroup()
        } else {
            navigate('/loginpage')
        }

        return () => {
            // Cancel the request before the component unmounts
            abortConstants.controller.abort()
        }
    }, [user, localToken])

    // API call to get onboarding details
    const getOnboarding = async () => {
        try {
            const result = await onBoarding(abortConstants.controllerConfig)
            console.log(result)
            if (result && result?.data?.OnboardingProcess) {
                setCurrentStep(result.data.OnboardingProcess[0]?.Id)
            }
        }
        catch (error) {
            handleApiErrors(error, 'OnBoarding', 'boarding_response', 'boarding_timeout', 'boarding_request', setIsLoading)
        }
    }

    const getData = () => {
        console.log("onboarding")
        getOnboarding()
    }

    const goToPreviousStep = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1)
        }
    }

    const goToNextStep = () => {
        if (currentStep >= 0) {
            setCurrentStep(currentStep + 1)
        }
    }

    // Render the component based on the current step
    const renderStepComponent = () => {
        switch (currentStep) {
            case 0:
                return <NotSync getOnboardingStep={getData} />
            case 1:
                return <CompanyProfile getOnboardingStep={getData} goToNextStep={goToNextStep} />
            case 2:
                return <BranchProfile getOnboardingStep={getData} goToPreviousStep={goToPreviousStep} />
            case 3:
                return <UserProfile getOnboardingStep={getData} goToPreviousStep={goToPreviousStep} />
            case 4:
                // navigate('/')
                 window.location.href = '/'
                return null
            default:
                navigate('/loginpage')
                return null
        }
    }

    return (
        <Box>
            <Header />
            {renderStepComponent()}
        </Box>
    )
}

export default OnboardingHome
