import React from 'react';
import { Box, Grid, Button, Typography, IconButton, Tooltip, Menu, Select, FormControl, MenuItem, } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import TabularFilter from './TabularFilter';
import InventoryFilter from './InventoryFilter';
import { useSelector } from 'react-redux';
import ProductFilter from '../TabularReports/InventoryReg/ProductFilter';


function StockHeader({
    branchName,
    fyrList,
    fyrSelect,
    isLoadingTrue,
    branchSelect,
    setbranchSelect,
    setFyrSelect,
    setFyrName,
    branchList,
    handleSearchGlobal,
    setbranchSelectSearch,
    setBranchName,
    setFrmdate,
    setTodate,
    frmDate,
    toDate,
    selectedFyr,
    setSelectedFyr,
    exportExcel,
    title,
    optionId,
    optionKey,
    optionList,
    optionSelect,
    setOptionSelect,
    isProduct,
    setIsProduct,
    handleSearch,
    isTable,
    tableData
}) {

    const user = useSelector(state => state.user)

    return (

        <Grid container spacing={1}>

            <Grid item xs={12} ml={2} mt={2} className='box space'>
                <span style={{ color: '#489C8F', fontSize: '15px', fontWeight: '500' }}>{title}</span>
                <span style={{ color: '#1F77B4', fontWeight: '500' }}>{user?.company?.companyname}</span>
            </Grid>

            <Grid item xs={12} ml={2} style={{ textAlign: 'start', display: 'flex', justifyContent: 'space-between' }}>

                <Grid container>
                    <Grid item xs={12} md={3}>
                        <span className="span text-h1">
                            <b className="b text-h1" style={{ fontSize: '12px', fontWeight: 500, color: 'black' }}> Branch :  </b>
                            {/* <b className="b text-h1" style={{ fontSize: '12px', fontWeight: 500 }}> {companyName}
                            &nbsp;&nbsp; */}
                            <b className="b text-h1" style={{ color: '#1F77B4', fontSize: '12px', fontWeight: 500 }}>{branchName.join(" , ")}</b>

                            {/* </b> */}
                        </span>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <ProductFilter optionList={optionList}
                            optionKey={optionKey}
                            optionSelect={optionSelect}
                            setOptionSelect={setOptionSelect}
                            optionId={optionId}
                            isProduct={isProduct}
                            setIsProduct={setIsProduct}
                            handleSearch={handleSearch} />
                    </Grid>

                    <Grid item xs={12} md={2} style={{ display: 'flex', justifyContent: 'end' }} >
                        <InventoryFilter

                            branchSelect={branchSelect}
                            setBranchSelect={setbranchSelect}
                            branchList={branchList}
                            handleSearch={handleSearchGlobal}
                            isLoadingTrue={isLoadingTrue}
                            setbranchSelectSearch={setbranchSelectSearch}
                            setBranchName={setBranchName}
                            setFrmdate={setFrmdate}
                            setTodate={setTodate}
                            frmDate={frmDate}
                            toDate={toDate}
                            //  optionTitle={optionTitle}
                            //  optionSelect={optionSelect}
                            //  setOptionSelect={setOptionSelect}
                            //  optionList ={optionList}
                            //  optionKey ={optionKey}
                            fyrSelect={fyrSelect}
                            setFyrSelect={setFyrSelect}
                            fyrList={fyrList}
                            setFyrName={setFyrName}
                            selectedFyr={selectedFyr}
                            setSelectedFyr={setSelectedFyr}
                        />

                        &nbsp; &nbsp; &nbsp; &nbsp;
                        {isTable && tableData && <DownloadIcon onClick={exportExcel} className='common-icon' style={{ marginTop: '8px' }} />}
                    </Grid>
                </Grid>

            </Grid>


        </Grid>

    );
}

export default StockHeader;
