import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Select,
  FormControl,
  Button,
  CircularProgress
} from "@mui/material";
import UploadImage from "./UploadImage";
import * as CompanyApi from '../../../api/companyApi'
import { getAllFyr } from "../../../api/fyrApi";
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from "../../../redux/slices/authSlice";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleApiErrors } from "../../../api/HandleApiErrors";
import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from "react-router-dom";

function CompanySettings({ onBoarding, goToNextStep }) {
  const token = useSelector(state => state.token)
  const userData = useSelector(state => state.user)
  console.log(userData);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState({})
  const [dateFormat, setDateFormat] = useState()
  const [timeZone, setTimeZone] = useState()
  const [organizationType, setOrganizationType] = useState()
  const [language, setLanguage] = useState()
  const [country, setCountry] = useState()
  const [currency, setCurrency] = useState()
  const [selectedImage, setSelectedImage] = useState();
  const [selectedImageBase64, setSelectedImageBase64] = useState("");
  const [companyData, setCompanyData] = useState()
  const [fyrList, setFyrList] = useState()
  const [isSaving, setIsSaving] = useState(false)
  const [formErrors, setFormerrors] = useState({})

  //load DateFormat
  const getDateFormat = async (separtor) => {
    try {
      const result = await CompanyApi.getDateFormat(separtor)
      setDateFormat(result?.data?.dateformat)
      console.log(result);
      // setDateFormatSelect(result.data.dateformat[0].DateFormatid)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getTimeZone = async () => {
    try {
      const result = await CompanyApi.getTimeZone()
      setTimeZone(result?.data?.TimeZone)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getOrganizationType = async () => {
    try {
      const result = await CompanyApi.getOrganizationType()
      setOrganizationType(result?.data?.CompanyType)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getLanguage = async () => {
    try {
      const result = await CompanyApi.getLanguage()
      setLanguage(result?.data?.Language)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getCountry = async () => {
    try {
      const result = await CompanyApi.getCountry()
      setCountry(result?.data?.Country)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getCurrency = async (id) => {
    try {
      const result = await CompanyApi.getCurrency(id)
      console.log(result);
      setCurrency(result?.data?.Currency)
      setCurrencySelect(result?.data?.Currency[0]?.currencyId)
    }
    catch (error) {
      console.log(error);
    }
  }
  const getFyr = async () => {
    try {
      const result = await getAllFyr()
      setFyrList(result?.data?.FinancialYear)
    }
    catch (error) {
      console.log(error);
    }
  }

  const getcompanyData = async () => {
    const id = userData?.companyid
    try {
      const result = await CompanyApi.getCompanyData(id)
      console.log(result);
      const data = result?.data?.Company
      console.log(`${process.env.REACT_APP_BASE_URL}${data.logo}`);
      getDateFormat(data.FormatSeparator ? data.FormatSeparator : '/')
      getCurrency(data.countryid)
      setCompanyData(data)
      setDatesepartorSelect(data.FormatSeparator ? data.FormatSeparator : '/')
      setOrganizationTypeSelect(data.companytypeid)
      setCountrySelect(data.countryid)
      // setCurrencySelect(data.currencyId)
      setTimeZoneSelect(data.timezoneid)
      setLanguageSelect(data.languageId)
      setDateFormatSelect(data.dateformatid)
      setFyrSelect(data.fiscyearid)
      // setSelectedImage(data.logo)
      setSelectedImage(data.logo ? `${process.env.REACT_APP_BASE_URL}${data.logo}` : "")
      setCommencementYear(data.CommencementYear ? dayjs(data.CommencementYear) : null)
    }
    catch (error) {
      console.log(error);
      handleApiErrors(error, 'Get company data', 'company_response', 'company_timeout', 'company_request', setIsLoading)
    }
  }

  useEffect(() => {
    getTimeZone()
    getOrganizationType()
    getLanguage()
    getCountry()
    // getCurrency()
    getFyr()
    if (userData?.companyid) {
      getcompanyData()
    }
  }, [token, userData])

  //load Select

  const [dateFormatSelect, setDateFormatSelect] = useState('');
  const [datesepartorSelect, setDatesepartorSelect] = useState('');
  const [timeZoneSelect, setTimeZoneSelect] = useState('');
  const [organizationTypeSelect, setOrganizationTypeSelect] = useState('');
  const [languageSelect, setLanguageSelect] = useState('');
  const [countrySelect, setCountrySelect] = useState('');
  const [currencySelect, setCurrencySelect] = useState('');
  const [fyrSelect, setFyrSelect] = useState('');
  const [commencementYear, setCommencementYear] = useState('')

  const handleChangeSelect = (e) => {
    const { name, value } = e.target;

    // Use a switch statement or if-else conditions to determine which state to update
    switch (name) {
      case 'dateFormat':
        setDateFormatSelect(value);
        break;
      case 'timeZone':
        setTimeZoneSelect(value);
        break;
      case 'organizationType':
        setOrganizationTypeSelect(value);
        break;
      case 'language':
        setLanguageSelect(value);
        break;
      case 'country':
        setCountrySelect(value);
        getCurrency(value)
        break;
      case 'currency':
        setCurrencySelect(value);
        break;
      case 'fyr':
        setFyrSelect(value);
        break;
      // Add more cases for additional select dropdowns if needed
      case 'datesepartor':
        console.log(value);
        getDateFormat(value)
        setDatesepartorSelect(value);
        // setDateFormatSelect(data[0].DateFormatid)
        break;
      default:
        // Handle default case or do nothing
        break;
    }
  };

  const changeDateFormat = () => {
    const data = dateFormat[0].DateFormatid
    console.log(dateFormat);
    setDateFormatSelect(data)
  }

  const handleChange = (e) => {
    setCompanyData({ ...companyData, [e.target.name]: e.target.value })
  }

  const validate = (data) => {
    const errors = {}

    // Validate each form field
    if (!data.CommencementYear) {
      errors.CommencementYear = 'Select a year.';
    }

    // if (!data.CompanyName.trim()) {
    //   errors.CompanyName = 'Company Name is required';
    // }

    // if (!data.CompanySize.trim()) {
    //   errors.CompanySize = 'Company Size is required';
    // }
    console.log(errors);
    setFormerrors(errors)
    if (Object.keys(errors).length === 0) {
      return true;
    }
    else {
      // setIsSaving(false)
      return false;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true)
    let newData = {
      companyname: companyData.companyname,
      companycode: companyData.companycode,
      logo: selectedImageBase64,
      emailid: companyData.emailid,
      mobileno: companyData.mobileno,
      address: companyData.address,
      website: companyData.website,
      dateformatid: dateFormatSelect,
      timezoneId: timeZoneSelect,
      companytypeid: organizationTypeSelect,
      languageId: languageSelect,
      countryid: countrySelect,
      CurrencyId: currencySelect,
      fiscyearid: fyrSelect,
      GstNo: companyData.GstNo,
      PostalCode: companyData.PostalCode,
      CommencementYear: commencementYear ? dayjs(commencementYear).year().toString() : null
    }
    console.log(newData);
    
    if (validate(newData)) {
      createcompany(newData)
    } else {
      setIsSaving(false);
    }
  }

  const createcompany = async (data) => {
    console.log(data);
    try {
      const result = await CompanyApi.updateCompanyData(companyData.companyid, data)
      console.log(result);
      if (result?.data?.Company) {
        dispatch(setUser({
          user: {
            ...userData, company: {
              ...userData.company,
              ...result?.data?.Company
            }
          }
        }))
        setIsSaving(false)
        // getcompanyData(result?.data?.Company?.companyid)
        toast.success(result.data.message, {
          autoClose: 3000, // Close the toast after 5 seconds
        });
      }
      onBoarding ? navigate('/OnBoarding') : navigate('/company')
    }
    catch (error) {
      setIsSaving(false)
      console.log(error);
      handleApiErrors(error, 'Update company settings', 'company_update_response', 'company_update_timeout', 'company_update_request', setIsLoading)
    }
  }

  const renderLoading = (item) => {
    if (isLoading[`${item}_response`] || isLoading[`${item}_request`] || isLoading[`${item}_timeout`]) {
      return true
    }
    else {
      return false
    }
  }
  // {onBoarding ?<Typography className="form-onboarding title">Set up your organization profile</Typography> : 'Edit Company Profile'}

  return (
    <>
      {companyData ?
        <>
          {companyData.companyid !== 0 ?
            <Box mt={2} sx={{ borderRadius: "5px", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)", bgcolor: "white", }}>
              <Box >
                <Typography padding={2} color="#1F77B4" fontSize={16}>
                  {onBoarding ? <Typography className="form-onboarding title">Set up your organization profile</Typography> : 'Edit Company Profile'}
                </Typography>
              </Box>
              <Divider
                color="#DADADA"
                sx={{
                  width: "100%",
                  height: "1px",
                  mt: '10px'
                  // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                }}
              />
              <Box>
                <form onSubmit={handleSubmit}>
                  <Box mt={1} p={3}>
                    <Grid container spacing={1}>
                      {!onBoarding && <Grid item xs={12}>
                        <Grid container spacing={1} columnSpacing={3}>
                          <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={4} className='grid center'>
                                <Typography >
                                  {" "}
                                  Organization Name{" "}<span className="span asterisk">*</span>
                                </Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <TextField
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  name="companyname"
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1F77B4",
                                    },
                                  }}
                                  value={companyData?.companyname}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={4} className='grid center'>
                                <Typography >
                                  {" "}
                                  Organization code{" "}
                                </Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <TextField
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  name="companycode"
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1F77B4",
                                    },
                                  }}
                                  value={companyData?.companycode}
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item xs={4} className='grid center'>
                                <Typography >
                                  {" "}
                                  Organization Type{" "}
                                </Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1F77B4",
                                    },
                                  }}
                                >
                                  {/* <InputLabel id="demo-select-small-label">Organization Type</InputLabel> */}
                                  <Select
                                    size="small"
                                    id="demo-simple-select-filled"
                                    name="organizationType"
                                    value={organizationTypeSelect}
                                    onChange={handleChangeSelect}
                                  >
                                    {organizationType?.map((item) => (
                                      <MenuItem key={item.CompanyTypeid} value={item.CompanyTypeid}>
                                        {item.CompanyTypename}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4} className='grid center'>
                                <Typography >
                                  {" "}
                                  Organization Address{" "}
                                </Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <TextField
                                  fullWidth
                                  size="small"
                                  variant="outlined"
                                  name="address"
                                  multiline
                                  inputProps={{ style: { maxHeight: '40px', overflowY: 'scroll' } }}
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1F77B4",
                                    },
                                  }}
                                  value={companyData?.address}
                                  onChange={handleChange}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={4} className='grid center'>
                                <Typography >
                                  {" "}
                                  Organization Logo{" "}
                                </Typography>
                              </Grid>
                              <Grid item xs={8} >
                                <Box
                                  sx={{
                                    borderRadius: "5px",
                                    border: '1px solid #BFBFBF',
                                    // boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.3)",
                                    // width: "220px",
                                    height: "180px", // adjust the height to match the width
                                    // ml: "25px",
                                    // position: "relative",
                                  }}
                                >
                                  <UploadImage selectedImage={selectedImage} setSelectedImage={setSelectedImage} setSelectedImageBase64={setSelectedImageBase64} />
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>}
                      <Grid item xs={12}>
                        <Grid container spacing={1} columnSpacing={3}>
                          <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>
                              {!onBoarding &&
                                <>
                                  <Grid item xs={4} className='grid center'>
                                    <Typography >
                                      Postal Code
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={8} className='grid center'>
                                    <TextField
                                      fullWidth
                                      size="small"
                                      variant="outlined"
                                      name="PostalCode"
                                      sx={{
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#1F77B4",
                                        },
                                      }}
                                      value={companyData?.PostalCode}
                                      onChange={handleChange}
                                    />
                                  </Grid></>
                              }
                              {onBoarding && <>
                                <Grid item xs={4} className='grid center'>
                                  <Typography >
                                    {" "}
                                    Organization Name{" "}<span className="span asterisk">*</span>
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} className='grid center'>
                                  <TextField
                                    fullWidth
                                    required
                                    size="small"
                                    variant="outlined"
                                    name="companyname"
                                    sx={{
                                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#1F77B4",
                                      },
                                    }}
                                    value={companyData?.companyname}
                                    onChange={handleChange}
                                  />
                                </Grid>
                              </>}
                              <Grid item xs={4} className='grid center'>
                                <Typography > Country <span className="span asterisk">*</span> </Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  required
                                  variant="outlined"
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1F77B4",
                                    },
                                  }}
                                >
                                  {/* <InputLabel id="demo-select-small-label">Organization Type</InputLabel> */}
                                  <Select
                                    size="small"
                                    id="demo-simple-select-filled"
                                    name='country'
                                    value={countrySelect}
                                    onChange={handleChangeSelect}
                                  >
                                    {country?.map((item) => (
                                      <MenuItem key={item.countryId} value={item.countryId}>
                                        {item.countryName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4} className='grid center'>
                                <Typography  > Time Zone <span className="span asterisk">*</span></Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  required
                                  variant="outlined"
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1F77B4",
                                    },
                                  }}
                                >
                                  {/* <InputLabel id="demo-select-small-label">Organization Type</InputLabel> */}
                                  <Select
                                    size="small"
                                    id="demo-simple-select-filled"
                                    name='timeZone'
                                    value={timeZoneSelect}
                                    onChange={handleChangeSelect}
                                  >
                                    {timeZone?.map((item) => (
                                      <MenuItem key={item.TimeZoneid} value={item.TimeZoneid}>
                                        {item.TimeZonename}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4} className='grid center'>
                                <Typography  > Date Format <span className="span asterisk">*</span></Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <Grid container>
                                  <Grid item xs={10}>
                                    <FormControl
                                      fullWidth
                                      size="small"
                                      required
                                      variant="outlined"
                                      sx={{
                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#1F77B4",
                                        },
                                      }}
                                    >
                                      <Select
                                        size="small"
                                        id="demo-simple-select-filled"
                                        name="dateFormat"
                                        value={dateFormatSelect}
                                        onChange={handleChangeSelect}
                                        required
                                      >
                                        {dateFormat?.map((item) => (
                                          <MenuItem key={item.DateFormatid} value={item.DateFormatid}>
                                            {item.DateFormatname}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <FormControl
                                      fullWidth
                                      size="small"
                                      variant="outlined"
                                    // sx={{
                                    //   "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    //   {
                                    //     borderColor: "#1F77B4",
                                    //   },
                                    // }}
                                    >
                                      <Select
                                        name='datesepartor'
                                        value={datesepartorSelect}
                                        onChange={handleChangeSelect}
                                        required
                                      >

                                        <MenuItem value='.'> . </MenuItem>
                                        <MenuItem value='-'> - </MenuItem>
                                        <MenuItem value='/'> / </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {!onBoarding && <>
                                <Grid item xs={4} className='grid center'>
                                  <Typography > Email </Typography>
                                </Grid>
                                <Grid item xs={8} className='grid center'>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="emailid"
                                    sx={{
                                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#1F77B4",
                                      },
                                    }}
                                    value={companyData?.emailid}
                                    onChange={handleChange}
                                  />
                                </Grid>
                                <Grid item xs={4} className='grid center'>
                                  <Typography   >
                                    {" "}
                                    GST No{" "}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} className='grid center'>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    required
                                    name="GstNo"
                                    sx={{
                                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#1F77B4",
                                      },
                                    }}
                                    value={companyData?.GstNo}
                                    onChange={handleChange}
                                  />
                                </Grid>
                              </>}
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Grid container spacing={1}>
                              <Grid item xs={4} className='grid center'>
                                <Typography > Fiscal Year <span className="span asterisk">*</span></Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  required
                                  variant="outlined"
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1F77B4",
                                    },
                                  }}
                                >
                                  {/* <InputLabel id="demo-select-small-label">Organization Type</InputLabel> */}
                                  <Select
                                    size="small"
                                    id="demo-simple-select-filled"
                                    name='fyr'
                                    value={fyrSelect}
                                    onChange={handleChangeSelect}
                                  >
                                    {fyrList?.map((item) => (
                                      <MenuItem key={item.Fyrid} value={item.Fyrid}>
                                        {item.Fyrname}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={4} className='grid center'>
                                <Typography  >
                                  {" "}
                                  Currency{" "}<span className="span asterisk">*</span>
                                </Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  required
                                  variant="outlined"
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1F77B4",
                                    },
                                  }}
                                >
                                  {/* <InputLabel id="demo-select-small-label">Organization Type</InputLabel> */}
                                  <Select
                                    size="small"
                                    id="demo-simple-select-filled"
                                    name='currency'
                                    value={currencySelect}
                                    onChange={handleChangeSelect}
                                  >
                                    {currency?.map((item) => (
                                      <MenuItem key={item.currencyId} value={item.currencyId}>
                                        {item.currencyName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>

                              <Grid item xs={4} className='grid center'>
                                <Typography >
                                  {" "}
                                  Language{" "}<span className="span asterisk">*</span>
                                </Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <FormControl
                                  fullWidth
                                  size="small"
                                  required
                                  variant="outlined"
                                  sx={{
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#1F77B4",
                                    },
                                  }}
                                >
                                  <Select
                                    size="small"
                                    id="demo-simple-select-filled"
                                    name='language'
                                    value={languageSelect}
                                    onChange={handleChangeSelect}
                                  >
                                    {language?.map((item) => (
                                      <MenuItem key={item.languageId} value={item.languageId}>
                                        {item.languageName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              {!onBoarding && <>
                                <Grid item xs={4} className='grid center'>
                                  <Typography  >
                                    {" "}
                                    Mobile No{" "}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} className='grid center'>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="mobileno"
                                    sx={{
                                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#1F77B4",
                                      },
                                    }}
                                    value={companyData?.mobileno}
                                    onChange={handleChange}
                                  />
                                </Grid>

                                <Grid item xs={4} className='grid center'>
                                  <Typography >
                                    {" "}
                                    Website{" "}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8} className='grid center'>
                                  <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    name="website"
                                    sx={{
                                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#1F77B4",
                                      },
                                    }}
                                    value={companyData?.website}
                                    onChange={handleChange}
                                  />
                                </Grid>
                              </>}
                              <Grid item xs={4} className='grid center'>
                                <Typography   >
                                  {" "}
                                  Commencement Year{" "}<span className="span asterisk">*</span>
                                </Typography>
                              </Grid>
                              <Grid item xs={8} className='grid center'>
                                <Box className='' display='flex' flexDirection='column' width='100%'>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      value={commencementYear}
                                      onChange={(newValue) => setCommencementYear(newValue)}
                                      views={['year']} // Show only the year view
                                      openTo="year"    // Open to the year view initially
                                      slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                    />
                                  </LocalizationProvider>
                                  {formErrors?.CommencementYear && <Typography color='error'>{formErrors.CommencementYear}</Typography>}
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {userData?.usertypeid === 1 && <Grid container >
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="flex-end"
                        mt={2}
                      >
                        {!isSaving && <Button
                          className="btn btn-update"
                          type="submit"
                        >
                          SAVE
                        </Button>}
                        {isSaving && <Button className='btn btn-update' ><span className="btn-text">Saving</span><div className="spinner"></div>
                        </Button>}
                        {/* <Button
                                 className="btn btn-delete"
                               >
                                 CANCEL
                               </Button> */}
                      </Grid>
                    </Grid>}
                  </Box>
                </form>
              </Box>
            </Box> :
            <h4 style={{ textAlign: 'center' }}>No data</h4>
          }
        </>
        :
        <>
          {renderLoading('company') ?
            <h5 style={{ textAlign: 'center' }}>No data</h5> :
            (<Box sx={{ textAlign: 'center', marginTop: '50px' }}>
              <CircularProgress />
            </Box>)
          }
        </>
      }
    </>

  );
}
export default CompanySettings;
