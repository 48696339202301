import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: "'Source Sans 3', sans-serif",
    // fontFamily: "'Inter', sans-serif",
    fontSize: 15,
  },

});

export default theme;