import React, { useState } from 'react'
import { Box, Button, TextField, InputAdornment, IconButton, Grid, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import * as UserApi from '../../api/userApi'
import { handleApiErrors } from '../../api/HandleApiErrors';

function ChangePassword({ handleClose }) {
  const user = useSelector(state => state.user)
  const token = useSelector(state => state.token)
  const [oldPswd, setOldPswd] = useState("")
  const [newPswd, setNewPswd] = useState("")
  const [rePswd, setRePswd] = useState("")
  const [viewOldPassword, setViewOldPassword] = useState(false)
  const [viewNewPassword, setViewNewPassword] = useState(false)
  const [errMsg, setErrMsg] = useState()
  const [isLoading, setIsLoading] = useState({})

  const resetPassword = async (data) => {
    console.log(data);
    try {
      const result = await UserApi.changePassword(data, token)
      alert(result.data?.message)
      setNewPswd(null)
      setOldPswd(null)
      setRePswd(null)
      handleClose()
    }
    catch (error) {
      // console.log(error);
      handleApiErrors(error, 'Change Password', 'changepswd_response', 'changepswd_timeout', 'changepswd_request', setIsLoading)
      handleClose()
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (newPswd === rePswd) {
      let data = {
        "OldPassword": oldPswd,
        "UserId": user.userid,
        "NewPassword": newPswd,
        "ReTypePassword": rePswd
      }
      resetPassword(data)
    }
    else {
      setErrMsg("Passwords do not match");
      return;
    }
  }
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <Grid container spacing={1} >
            <Grid item xs={12} ><Typography className='text-color'> Current Password </Typography></Grid>
            <Grid item xs={12} >
              <TextField
                size='small'
                required
                fullWidth
                id="opassword"
                name="old_password"
                type={viewOldPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setViewOldPassword(!viewOldPassword)}
                      // onMouseDown={handleMouseDownPassword}
                      >
                        {viewOldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                autoFocus
                value={oldPswd}
                onChange={(e) => { setOldPswd(e.target.value); setErrMsg(null) }}
              />
            </Grid>
            <Grid item xs={12} ><Typography className='text-color'>New Password</Typography></Grid>
            <Grid item xs={12}>
              <TextField
                size='small'
                required
                fullWidth
                name="new_password"
                type={viewNewPassword ? 'text' : 'password'}
                id="npassword"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setViewNewPassword(!viewNewPassword)}
                      // onMouseDown={handleMouseDownPassword}
                      >
                        {viewNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={newPswd}
                onChange={(e) => { setNewPswd(e.target.value); setErrMsg(null) }}
              />
            </Grid>
            <Grid item xs={12} ><Typography className='text-color'> Re-enter Password </Typography></Grid>
            <Grid item xs={12} >
              <TextField
                size='small'
                required
                fullWidth
                name="re_password"
                type='password'
                id="rpassword"
                value={rePswd}
                onChange={(e) => { setRePswd(e.target.value); setErrMsg(null) }}
              />
            </Grid>
            {errMsg && <span style={{ color: 'red', fontSize: '12px', marginLeft: '10px' }}>{errMsg}</span>}
            <Grid item xs={12} >
              <Button type='submit' fullWidth  className="btn btn-search">
                RESET
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  )
}

export default ChangePassword